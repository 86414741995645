import React from "react";
import { Label } from "reactstrap";
import { dataExists, returnDataIfExists, returnaddress } from "../../../services/utils";
import "./Step1Review.css";
import { dataNotFoundMessage } from "../../../data/data";
import { JsonSchema } from "../../../data/stepData";
import { StaticReviewField }  from "../../atoms/StaticInformationField/StaticInformationField";

const Step1Review = props => {
  const { mortgageDetails, accountNumber, guarantors,isBroker,brokerDetails } = props.data;
  var args = Array.prototype.slice.call(guarantors);
  const step1 = JsonSchema.step1.sections[0]
  const step1AccountInformation = JsonSchema.step1.sections[0].accountInformation.staticInformationField
  const step1PersonalDetails = JsonSchema.step1.sections[0].personalDetails.staticInformationField
  const step1BrokerDetails = JsonSchema.step1.sections[0].brokerDetails.staticInformationField
  return (
    <>
      <Label className="step1Label">{step1.headerDescription}</Label>
      <StaticReviewField
            label={step1AccountInformation[0].label}
            value={dataExists(accountNumber)}
      />
      <StaticReviewField
            label={step1AccountInformation[1].label}
            value={dataExists(mortgageDetails.accountName)}
      />
      {guarantors === 1 ? (
        <StaticReviewField
          label={step1AccountInformation[2].label}
          value={dataExists(guarantors)}
        />
      ) : (
        <StaticReviewField
          label={step1AccountInformation[2].label}
          value={dataExists(args.join(", "))}
        />
      )}

      <StaticReviewField
          label={step1AccountInformation[3].label}
          value={returnDataIfExists(mortgageDetails.securityAddress) ? 
            returnaddress(mortgageDetails.securityAddress) :
            dataNotFoundMessage}
        />

      <StaticReviewField
          label={step1PersonalDetails[0].label}
          value={dataExists(mortgageDetails.telephone)}
        />
      <StaticReviewField
          label={step1PersonalDetails[1].label}
          value={dataExists(mortgageDetails.email)}
        />
      
      <StaticReviewField
          label={step1PersonalDetails[2].label}
          value={returnDataIfExists(mortgageDetails.correspondenceAddress) ? 
            returnaddress(mortgageDetails.correspondenceAddress) :
            dataNotFoundMessage}
        />

      {isBroker?(
        <>
        <StaticReviewField
          label={step1BrokerDetails[0].label}
          value={dataExists(brokerDetails.companyName)}
        />
        <StaticReviewField
          label={step1BrokerDetails[1].label}
          value={dataExists(brokerDetails.contactName)}
          className="email-break"
        />
        <StaticReviewField
          label={step1BrokerDetails[2].label}
          value={dataExists(brokerDetails.contactTelephone)}
          className="email-break"
        />
        <StaticReviewField
          label={step1BrokerDetails[3].label}
          value={dataExists(brokerDetails.email)}
          className="email-break"
        />
      </>):(null)}
    </>
  );
};

export default Step1Review;
