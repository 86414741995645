import React from 'react';
import { Row, Col } from 'reactstrap'
import './Loader.css'

function Loader() {
    return (
        <Row className="align-items-middle">
            <Col className="loader">
                <div className="circ circ1"></div>
                <div className="circ circ2"></div>
                <div className="circ circ3"></div>
                <div className="circ circ4"></div>
                <div className="circ circ5"></div>
                <div className="circ circ6"></div>
                <div className="circ circ7"></div>
                <div className="circ circ8"></div>
            </Col> 
        </Row>
    )
}

export default Loader;