/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Label } from "reactstrap";
import { dataExists, typeOfChanges, fullFormatCurrency } from "../../../services/utils";
import "./Step3Review.css";
import { JsonSchema } from "../../../data/stepData"

const Step3Review = props => {
  const { step2,step3,isBroker,mortgageDetails } = props.data;
  const step3Header = JsonSchema.step3.sections[0].headerDescription;
  const step3Question1 = JsonSchema.step3.sections[0].questions[0].description;
  const step3Question2 = JsonSchema.step3.sections[0].questions[0].options[0].subDescription;
  const step3Question3 = JsonSchema.step3.sections[0].questions[0].options[0].fieldInput.label;
  const step3Question4 = JsonSchema.step3.sections[0].questions[1].description;
  const step3Question5 = JsonSchema.step3.sections[0].questions[3].description;
  const step3Question6 = JsonSchema.step3.sections[0].questions[2].description;
  const step3Question7 = JsonSchema.step3.sections[0].questions[4].description;
  const step3Question1_1 = JsonSchema.step3.sections[0].questions[5].description;
  const {isProductTransfer} = mortgageDetails;
  return (
    <>
    {!isBroker?(<div className="page-break"></div>):(null)}
    <Label className="step3Label labelspacing">{step3Header}</Label>
      { isProductTransfer && (
        <Row className="step3label-row">
          <Col className="question">
          {step3Question1_1}
          </Col>
          <Col className="answer"> {dataExists(fullFormatCurrency(step3.annualIncome))}
          </Col>
        </Row>
      )}
      <Row className="step3label-row">
        <Col className="question">{step3Question1}</Col>
        {step3.propertyAlterations?(
          <Col className="answer"> {"Yes"}
          </Col>
           
        ):<Col className="answer"> {"No"}
        </Col>}
      </Row>

      {step3.propertyAlterations ? (
        <Row className="step3label-row">
          <Col className="question">{step3Question2}</Col>
          <Col className="answer">
          {dataExists(
            step3.propertyAlterations
              ? step3.typeOfChange.map(value => typeOfChanges(value)).join(", ")
              : "No"
          )}
          </Col>
        </Row>
      ) : null}


      {step3.propertyAlterations ? (
        <Row className="step3label-row">
          <Col className="question">{step3Question3}</Col>
          <Col className="answer">
            {dataExists(step3.propertyChangesDetails)}
          </Col>
        </Row>
      ) : null}

      <Row className="step3label-row">
        <Col className="question">{step3Question4}</Col>
        {step3.furtherDetails?(
          <Col className="answer">
          {"Yes"}
        </Col>
        ):<Col className="answer">
        {"No"}
      </Col>} 
      </Row>

      {step3.furtherDetails ? (
        <Row className="step3label-row">
          <Col className="question">{step3Question5}</Col>
          <Col className="answer">
            {dataExists(step3.furtherDetails)}
          </Col>
        </Row>
      ) : null}

      <Row className="step3label-row">
        <Col className="question">{step3Question6}</Col>
        {step3.claimsInformation?(
          <Col className="answer">
          {"Yes"}
        </Col>
        ):<Col className="answer">
        {"No"}
      </Col>} 
      </Row>

      {step3.claimsInformation ? (
        <Row className="step3label-row">
          <Col className="question">{step3Question5}</Col>
          <Col className="answer">
            {dataExists(step3.claimsInformation)}
          </Col>
        </Row>
      ) : null}

     
      <Row className="step3label-row">
        <Col className="question">
        {step3Question7}
        </Col>
        {step3.capitalRaise?(
          <Col className="answer"> {"Yes"}
          </Col>         
        ):<Col className="answer"> {"No"}
        </Col>}      
      </Row>
    </>
  );
};

export default Step3Review;
