import React from 'react';
import { Row, Col } from 'reactstrap'
import './DashBoardPage.css'

const DashBoardPage = props => (
  <Row className="selectMortgage-Data">
    <Col xs='12' className='px-0'>
      <div className="small-header">{props.small_header}</div>
      <div className="main-header">{props.main_header}</div>
    </Col>
  </Row>
);

export default DashBoardPage;
