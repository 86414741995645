import React from "react";
import { Container, Row, Col } from "reactstrap";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import ProductCompare from "../../components/molecules/ProductCompare/ProductCompare";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import { shouldRefer } from "../../services/utils";
import { JsonSchema } from "../../data/stepData";

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReferralJournery: shouldRefer({
        step2: props.state.step2,
        step3: props.state.step3,
        step4: props.state.step4,
        selectedProduct: props.state.selectedProduct
      }),
    };
  }

  render() {
    const label = "Continue";
    const step5 = JsonSchema.step5.sections[0];
    return (
      <Container fluid className="step5">
        <ProductSwitchConsumer>
          {(ctx) => (
            <>
              <ScrollToPoint></ScrollToPoint>
              {ctx.state.isBroker ? (
                <>
                  <PageIntroduction
                    small_header={step5.currentStepNumberForBroker}
                    main_header={step5.headerDescription}
                  />
                  <ProgressBar now={55} />
                </>
              ) : (
                <>
                  <PageIntroduction
                    small_header={step5.currentStepNumberForCustomer}
                    main_header={step5.headerDescription}
                  />
                  <ProgressBar now={62.5} />
                </>
              )}

              <Row className="mt-4">
                <Col xs={12} className="page-navbar-bottom">
                  <ProductCompare
                    isDataReady={ctx.state.isDataReady}
                    mortgage={ctx.state.mortgageDetails}
                    desired_loan={ctx.state.step2.desired_loan}
                    selectedProduct={ctx.state.selectedProduct}
                    step2={ctx.state.step2}
                    step3={ctx.state.step3}
                  />
                </Col>
              </Row>

              {renderControllerBar(
                this.props.backTo,
                "/step-6",
                ctx.state.isDataReady,
                label,
                true,
                () => ctx.saveBackProgress("step5"),
                () => ctx.saveProgress("step5", this.state)
              )}
            </>
          )}
        </ProductSwitchConsumer>
      </Container>
    );
  }
}

export default Step5;
