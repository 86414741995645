import React from 'react'
import './Logo.css'

class Logo extends React.Component {

    render() {
        return (
            <div className="logo">
                 <img alt="Shawbrook Bank Logo" src="/logo.png"></img>
            </div>
        )
    }
}

export default Logo;
