import React from "react";
import { Row, Col } from "reactstrap";
import ButtonSelect from "../../components/atoms/ButtonSelect/ButtonSelect";
import FieldInput from "../../components/atoms/FieldInput/FieldInput";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import CheckboxSelect from "../../components/atoms/CheckboxSelect/CheckboxSelect";
import CurrentMortgage from "../../components/molecules/CurrentMortgage/CurrentMortgage";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import MessageInformationField from "../../components/atoms/MessageInformationField/MessageInformationField";
import { ltvThresholdValue } from "../../data/data";

import {
  booleanOrNull,
  fullFormatCurrency,
  formatCurrency,
  shouldShowStep2AddBarComponent,
} from "../../services/utils";
import { post } from "../../services/api";
import { ltvUrl } from "../../config";
import { JsonSchema } from "../../data/stepData";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    let step2_values;
    const { isProductTransfer } = this.props.state.mortgageDetails;
    if (props.state && props.state.step2) {
      step2_values = props.state.step2;
    } else {
      step2_values = {};
    }
    this.state = {
      remainingBalance: props.state.mortgageDetails.remaining_balance,
      grossLoanAmount: props.state.mortgageDetails.grossLoan_amount,
      automatedValue: props.state.mortgageDetails.automated_valuation,
      avm_agreed: isProductTransfer ? true : booleanOrNull(step2_values.avm_agreed),
      accept_value: isProductTransfer ? true : booleanOrNull(step2_values.accept_value),
      expected_value: isProductTransfer ? props.state.mortgageDetails.automated_valuation.toString() : step2_values.expected_value || "",
      desired_loan: isProductTransfer ? props.state.mortgageDetails.remaining_balance : step2_values.desired_loan || "",
      loan_reason: step2_values.loan_reason || [],
      loan_reason_detail: step2_values.loan_reason_detail || "",
      future_ltv: step2_values.future_ltv || 0,
      is_expected_field_active:
        step2_values.avm_agreed === null || step2_values.avm_agreed
          ? false
          : true,
      is_desired_loan_field_active:
        step2_values.accept_value === null || step2_values.accept_value
          ? false
          : true,
      is_ltv_threshold_breached: false,
    };
  }

  componentDidMount() {
    const { avm_agreed, accept_value } = this.state;
    if (
      (avm_agreed !== null && !avm_agreed) ||
      (accept_value !== null && !accept_value)
    ) {
      this.set_future_ltv();
    }
  }

  // First set of ButtonSelect questions logic
  avm_agreed = (value) => {
    let exp_value = this.formatNumber(this.props.state.step2.expected_value);
    const { automatedValue } = this.state;
    let auto_value = this.formatNumber(automatedValue.toString());
    if (auto_value !== exp_value) {
      this.setState({
        isDataChanged: true,
      });
    } else {
      this.setState({
        isDataChanged: false,
      });
    }
    this.setState(
      {
        avm_agreed: value,
        is_expected_field_active: !value,
        expected_value: !value
          ? ""
          : this.props.state.mortgageDetails.automated_valuation.toString(),
      },
      () => {
        if (!(this.state.avm_agreed && this.state.accept_value)) {
          this.set_future_ltv();
        } else {
          this.setState({ future_ltv: 0 });
        }
      }
    );
  };

  // Second set of ButtonSelect questions logic
  accept_value = (value) => {
    let des_loan = this.formatNumber(this.props.state.step2.desired_loan);
    const { remainingBalance } = this.state;
    let remBal_value = this.formatNumber(remainingBalance.toString());
    if (remBal_value !== des_loan) {
      this.setState({
        isDataChanged: true,
      });
    } else {
      this.setState({
        isDataChanged: false,
      });
    }
    if (value) {
      this.setState(
        {
          accept_value: value,
          loan_reason: [],
          loan_reason_detail: "",
          desired_loan: this.props.state.mortgageDetails.remaining_balance,
          is_desired_loan_field_active: false,
        },
        () => {
          if (!(this.state.avm_agreed && this.state.accept_value)) {
            this.set_future_ltv();
          } else {
            this.setState({ future_ltv: 0 });
          }
        }
      );
    } else {
      this.setState(
        {
          accept_value: value,
          desired_loan: null,
          loan_reason: [],
          loan_reason_detail: "",
          is_desired_loan_field_active: true,
        },
        () => {
          if (!(this.state.avm_agreed && this.state.accept_value)) {
            this.set_future_ltv();
          } else {
            this.setState({ future_ltv: 0 });
          }
        }
      );
    }
    this.isValueChangedInExpectedProperty();
  };

  set_future_ltv = () => {
    const { expected_value, desired_loan } = this.state;
    if (
      expected_value !== "" &&
      expected_value !== null &&
      desired_loan !== "" &&
      desired_loan !== null
    ) {
      post(
        `${ltvUrl}?loanSize=${desired_loan}&propertyValue=${expected_value}`,
        null,
        true,
        null
      ).then((future_ltv) => {
        if (future_ltv >= ltvThresholdValue) {
          this.setState({
            future_ltv,
            is_ltv_threshold_breached: true,
          });
        } else {
          this.setState({ future_ltv, is_ltv_threshold_breached: false });
        }
      });
    } else {
      this.setState({ is_ltv_threshold_breached: false });
    }
  };

  // This is a function that changes a number into a correctly formatted currency string with commas in the right places
  formatNumber(x) {
    //regex to match single non zero digit after the decimal point
    var singleNonZeroDecimalPoint = /^[0-9]+(\.[1-9])$/;
    var valid = singleNonZeroDecimalPoint.test(x);
    if (valid && typeof x.length !== "undefined") {
      x = x.padEnd(x.length + 1, "0");
    }
    //regex to match single zero after the decimal point
    var singleZeroAfterDecimal = /^[0-9]+(\.[0])$/;
    var singleZeroAfterDecimalIsTrue = singleZeroAfterDecimal.test(x);
    let formattedValue = x.toString();
    if (formattedValue !== "") {
      if (!formattedValue.includes(".")) {
        formattedValue = formattedValue + ".00";
      }
      if (singleZeroAfterDecimalIsTrue) {
        formattedValue = formattedValue.replace(".0", ".00");
      }
    }
    return formattedValue;
  }

  expected_value = (value) => {
    this.setState(
      {
        is_ltv_threshold_breached: false,
        expected_value: value,
      },
      () => this.set_future_ltv()
    );
    this.isExpectedValueChanged(value);
  };

  desired_loan = (value) => {
    this.setState(
      {
        is_ltv_threshold_breached: false,
        desired_loan: value,
      },
      () => this.set_future_ltv()
    );
    this.isDesiredLoanChanged(value);
  };

  isDesiredLoanChanged(value) {
    value = this.formatNumber(value);
    let desiredLoan = this.formatNumber(this.props.state.step2.desired_loan);
    if (value === "") {
      this.setState({
        future_ltv: 0,
      });
    } else if (value !== desiredLoan) {
      this.setState({
        isDataChanged: true,
      });
    } else {
      this.setState({
        isDataChanged: false,
      });
    }
    this.isValueChangedInExpectedProperty();
  }

  isExpectedValueChanged(value) {
    value = this.formatNumber(value);
    let expectedValue = this.formatNumber(
      this.props.state.step2.expected_value
    );
    if (value !== expectedValue) {
      this.setState({
        isDataChanged: true,
      });
    } else {
      this.setState({
        isDataChanged: false,
      });
    }
  }

  isValueChangedInExpectedProperty() {
    const { expected_value } = this.state;
    let exValue = expected_value;
    let value_sample = this.formatNumber(exValue);
    let expectedValue = this.formatNumber(
      this.props.state.step2.expected_value
    );
    if (value_sample !== expectedValue) {
      this.setState({
        isDataChanged: true,
      });
    }
  }

  loan_reason = (value) => {
    this.setState({ loan_reason: value });
  };

  loan_reason_detail = (value) => {
    this.setState({ loan_reason_detail: value });
  };

  renderValueAgree(ctx) {
    const {
      avm_agreed,
      desired_loan,
      loan_reason,
      future_ltv,
      expected_value,
      accept_value,
      is_desired_loan_field_active,
      is_ltv_threshold_breached,
      loan_reason_detail,
    } = this.state;

    const question2 = JsonSchema.step2.sections[0].questions[1];
    const question2Answers = JsonSchema.step2.sections[0].questions[1].answers;
    const question3Answer =
      JsonSchema.step2.sections[0].questions[2].answers[1];
    const question4 = JsonSchema.step2.sections[0].questions[3];
    const question5 = JsonSchema.step2.sections[0].questions[4];
    const question5Option1 =
      JsonSchema.step2.sections[0].questions[4].options[0];
    const question5Option2 =
      JsonSchema.step2.sections[0].questions[4].options[1];
    const question5Option3 =
      JsonSchema.step2.sections[0].questions[4].options[2];
    const question5Option4 =
      JsonSchema.step2.sections[0].questions[4].options[3];

    if (
      avm_agreed ||
      (!avm_agreed && expected_value.length > 0) ||
      accept_value ||
      (!accept_value && accept_value !== null)
    ) {
      return (
        <React.Fragment>
          <ButtonSelect
            label={question2.description}
            className="step2__agree-avm-amount"
            options={[
              {
                value: question2Answers[0].value,
                label: fullFormatCurrency(
                  ctx.state.mortgageDetails.remaining_balance
                ),
              },
              {
                value: question2Answers[1].value,
                label: question2Answers[1].label,
              },
            ]}
            selected={accept_value}
            handleSelect={this.accept_value}
          />

          {is_desired_loan_field_active && (
            <MessageInformationField
              messageClass="step2-content-text"
              color="black"
              value={question3Answer.loanAmountValuationText}
            />
          )}

          {!accept_value && accept_value !== null && (
            <>
              <FieldInput
                className="step2__desired-loan"
                label={question4.description}
                type={question4.type}
                prefix={String.fromCharCode(163)}
                placeholder={formatCurrency(
                  ctx.state.mortgageDetails.remaining_balance
                )}
                required={true}
                validation_error={question4.validation_error}
                value={desired_loan}
                hasError={is_ltv_threshold_breached}
                handleInput={this.desired_loan}
              />

              {!accept_value &&
                is_desired_loan_field_active &&
                is_ltv_threshold_breached &&
                future_ltv >= ltvThresholdValue && (
                  <MessageInformationField
                    messageClass="field-input__validation-error"
                    color="#c00"
                    value={question4.thresholdBreachedText}
                  />
                )}

              {!is_ltv_threshold_breached &&
                parseInt(desired_loan) >
                  parseInt(ctx.state.mortgageDetails.remaining_balance) && (
                  <>
                    <CheckboxSelect
                      className="step2__desired-loan-questions"
                      label={question5.description}
                      options={[
                        {
                          label: question5Option1.label,
                          value: question5Option1.value,
                        },
                        {
                          label: question5Option2.label,
                          value: question5Option2.value,
                        },
                        {
                          label: question5Option3.label,
                          value: question5Option3.value,
                        },
                        {
                          label: question5Option4.label,
                          value: question5Option4.value,
                        },
                      ]}
                      selected={loan_reason}
                      handleSelect={this.loan_reason}
                    />
                  </>
                )}

              {this.state.loan_reason.length > 0 &&
              parseInt(desired_loan) >
                parseInt(ctx.state.mortgageDetails.remaining_balance) &&
              !is_ltv_threshold_breached ? (
                <FieldInput
                  className="step2__loan-reason-details"
                  label={question5.loanReasonDetails.description}
                  type={question5.loanReasonDetails.type}
                  required={question5.loanReasonDetails.required}
                  validation_error={
                    question5.loanReasonDetails.validation_error
                  }
                  value={loan_reason_detail}
                  handleInput={this.loan_reason_detail}
                />
              ) : (
                <>
                  {this.state.loan_reason.length > 0 ||
                  this.state.loan_reason_detail !== ""
                    ? this.setState({
                        loan_reason: [],
                        loan_reason_detail: "",
                      })
                    : null}
                </>
              )}
            </>
          )}
        </React.Fragment>
      );
    }
  }

  renderRightPanel(ctx) {
    const {
      avm_agreed,
      is_expected_field_active,
      future_ltv,
      expected_value,
      is_ltv_threshold_breached,
    } = this.state;

    const question1 = JsonSchema.step2.sections[0].questions[0];
    const question3 = JsonSchema.step2.sections[0].questions[2];

    return (
      this.props.state.isDataReady && (
        <Col
          xs="12"
          md="6"
          className="pr-0 pl-0 pl-md-3 page-navbar-bottom box-height"
        >
          <ButtonSelect
            className="step2__agree-avm-options mt-md-0"
            label={question1.description}
            options={[
              {
                value: question1.answers[0].value,
                label: question1.answers[0].label,
              },
              {
                value: question1.answers[1].value,
                label: question1.answers[1].label,
              },
            ]}
            selected={avm_agreed}
            handleSelect={this.avm_agreed}
          />

          {!avm_agreed && avm_agreed !== null && (
            <FieldInput
              label={question3.description}
              className="step2__agree-avm-expected-value"
              handleInput={this.expected_value}
              type={question3.type}
              prefix={String.fromCharCode(163)}
              placeholder={
                ctx.state.mortgageDetails.automated_valuation
                  ? formatCurrency(
                      ctx.state.mortgageDetails.automated_valuation
                    )
                  : ""
              }
              value={expected_value}
              required={question3.required}
              hasError={is_ltv_threshold_breached}
              validation_error={question3.validation_error}
            />
          )}
          {!avm_agreed &&
            is_expected_field_active &&
            is_ltv_threshold_breached &&
            future_ltv >= ltvThresholdValue && (
              <MessageInformationField
                messageClass="field-input__validation-error"
                color="#c00"
                value={question3.thresholdBreachedText}
              />
            )}
          {this.renderValueAgree(ctx)}
        </Col>
      )
    );
  }

  render() {
    let enableAddControllerBar = shouldShowStep2AddBarComponent(
      this.state,
      this.props
    );
    const step2 = JsonSchema.step2.sections[0];
    return (
      <div className="container-fluid step2">
        <ProductSwitchConsumer>
          {(ctx) => (
            <>
              <ScrollToPoint />
              {ctx.state.isBroker ? (
                <>
                  <PageIntroduction
                    small_header={step2.currentStepNumberForBroker}
                    main_header={
                      ctx.state.mortgageDetails.isProductTransfer
                        ? step2.headerDescriptionProductTransfer
                        : step2.headerDescriptionProductSwitch
                    }
                  />
                  <ProgressBar now={22} />
                </>
              ) : (
                <>
                  <PageIntroduction
                    small_header={step2.currentStepNumberForCustomer}
                    main_header={
                      ctx.state.mortgageDetails.isProductTransfer
                        ? step2.headerDescriptionProductTransfer
                        : step2.headerDescriptionProductSwitch
                    }
                  />
                  <ProgressBar now={25} />
                </>
              )}

              <Row className="mt-4">
                <Col xs="12" md="6" className="pr-md-4 pr-lg-5">
                  <CurrentMortgage
                    mortgageDetails={ctx.state.mortgageDetails}
                  />
                </Col>
                {!ctx.state.mortgageDetails.isProductTransfer && this.renderRightPanel(ctx)}
              </Row>
              {renderControllerBar(
                this.props.backTo,
                this.props.continueTo,
                true,
                "Continue",
                enableAddControllerBar,
                () => ctx.saveBackProgress("step2", this.state),
                () => ctx.update("step2", this.state)
              )}
            </>
          )}
        </ProductSwitchConsumer>
      </div>
    );
  }
}

export default Step2;
