import React from "react";
import { Container, Row, Col } from "reactstrap";
import { openingHours, pdfError, referApplicationName } from "../../data/data";
import { WithInfoContactUsCard } from "../../components/molecules/ContactUsCard/ContactUsCard";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import { referralData } from "./Referral_data";
import Button from "../../components/atoms/Button/Button";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import MessageInformationField from "../../components/atoms/MessageInformationField/MessageInformationField";
import { getGeneratedPdf } from "../../services/api";
import { downloadPdf } from "../../services/utils";
import "./Referral.css";

class Referral extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitForResponse: false,
      loader: false,
      isError: this.isError,
      caseId: sessionStorage.getItem("caseId"),
    };
  }

  handlePdfClick() {
    this.setState({
      loader: true,
      isError: false,
    });

    getGeneratedPdf(this.state.caseId).then((res) => {
      if (res.data.size === 0) {
        this.setState({
          loader: false,
          isError: true,
        });
      } else {
        this.setState({
          pdf: res.data,
          loader: false,
          isError: false,
        });
        downloadPdf(res, referApplicationName);
      }
    });
  }

  buttonEnabled(ctx) {
    return (
      !this.state.waitForResponse &&
      !ctx.isOfferRequested() &&
      ctx.state.isDataReady
    );
  }

  render() {
    const { isError, loader } = this.state;

    return (
      <>
        <ProductSwitchConsumer>
          {(ctx) => (
            <Container className="referral">
              <PageIntroduction
                small_header="Thank you for your submission"
                main_header="Your application has been referred"
              />
              <ProgressBar now={100} />
              <Row className="mt-4">
                <Col xs="12" md="6" className="referral__review">
                  <div className="referral__review-inner">
                    <h5>{referralData.reviewText[0].value}</h5>
                    <p className="mt-4">{referralData.reviewText[1].value}</p>
                    {ctx.state.step3.capitalRaise?
                    (
                      <>
                    <p style={{paddingTop:'-10px'}}>{referralData.reviewText[3].value}</p>
                    <div className="capitalRaiseConditions">
                    <ul className="capitalRaiseList">
                      <li className="capitalRaise"><span className="capitalRaisespan">{referralData.reviewText[4].value}</span></li>
                      <li className="capitalRaise"><span className="capitalRaisespan">{referralData.reviewText[5].value}</span></li>
                    </ul>
                    </div>
                    </>
                    )
                    :
                    (null)}
                    <p>{referralData.reviewText[2].value}</p>

                    {ctx.state.pdfFeatureFlag && (
                      <Col className="referral__review_col">
                        {!isError ? (
                          <ProductSwitchConsumer>
                            {(ctx) => (
                              <>
                                {!loader ? (
                                  <Button
                                    handleClick={() =>
                                      this.handlePdfClick(ctx, "pdf")
                                    }
                                    className="referral__pdf-button"
                                    label="Download application (PDF)"
                                    type={loader ? "inactive" : "primary"}
                                    download
                                    next
                                  />
                                ) : (
                                  <>
                                    <Button
                                      handleClick={() =>
                                        this.handlePdfClick(ctx, "pdf")
                                      }
                                      className="referral__pdf-button"
                                      label="Generating PDF..."
                                      type={loader ? "inactive" : "primary"}
                                      next
                                    />
                                    <img
                                      className="small-loader"
                                      src="./Spinner.svg"
                                      alt="load"
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </ProductSwitchConsumer>
                        ) : (
                          <ProductSwitchConsumer>
                            {(ctx) => (
                              <>
                                <Button
                                  handleClick={() =>
                                    this.handlePdfClick(ctx, "pdf")
                                  }
                                  className="referral__pdf-button"
                                  label="Download application (PDF)"
                                  type={loader ? "inactive" : "primary"}
                                  download
                                  next
                                />
                                <MessageInformationField
                                  messageClass="pdf__validation-error"
                                  color="red"
                                  value={pdfError}
                                />
                              </>
                            )}
                          </ProductSwitchConsumer>
                        )}
                      </Col>
                    )}
                  </div>
                </Col>

                <Col xs="12" md="6" className="referral__contact-us">
                  <WithInfoContactUsCard opening_hours={openingHours} />
                </Col>
              </Row>
            </Container>
          )}
        </ProductSwitchConsumer>
      </>
    );
  }
}
export default Referral;
