import React from "react";
import { Container, Row } from "reactstrap";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import ReviewApplication from "../../components/Organisms/ReviewApplication/ReviewApplication";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import { shouldRefer } from "../../services/utils";
import "./Step6.css";
import { JsonSchema } from "../../data/stepData";

class Step6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: React.createRef(null),
      isReferralJournery: shouldRefer({
        step2: props.state.step2,
        step3: props.state.step3,
        step4: props.state.step4,
      }),
    };
    this.update = this.update.bind(this);
  }

  update = (ctx, payload) => {
    ctx.saveProgress("step6", payload);
    this.state.htmlContent.current.getElementsByClassName('progress')[0].remove()
    const value = this.state.htmlContent.current.outerHTML;
    ctx.savePdfHtmlContent(value);
  };

  render() {
    const label = "Continue";
    const step6 = JsonSchema.step6.sections[0];
    return (
      <>
        <ScrollToPoint></ScrollToPoint>
        <Container fluid className="step6">
          <Row>
            <div ref={this.state.htmlContent} className="page-navbar-bottom">
              <ProductSwitchConsumer>
                {(ctx) => (
                  <>
                    {" "}
                    {ctx.state.isBroker ? (
                      <>
                        {" "}
                        <PageIntroduction
                          small_header={step6.currentStepNumberForBroker}
                          main_header={step6.headerDescription}
                        />
                        <ProgressBar now={66} />
                      </>
                    ) : (
                      <>
                        <PageIntroduction
                          small_header={step6.currentStepNumberForCustomer}
                          main_header={step6.headerDescription}
                        />
                        <ProgressBar now={75} />
                      </>
                    )}
                    <ReviewApplication
                      context={ctx.state}
                      htmlContent={this.state.htmlContent}
                    />
                  </>
                )}
              </ProductSwitchConsumer>
            </div>
          </Row>
        </Container>
        <ProductSwitchConsumer>
          {(ctx) =>
            this.state.isReferralJournery
              ? renderControllerBar(
                  this.props.backTo,
                  "/declaration",
                  ctx.state.isDataReady,
                  label,
                  true,
                  () =>
                    ctx.saveBackProgress("step6", {
                      backClicked: true,
                    }),
                  () => this.update(ctx, { toRefer: true })
                )
              : renderControllerBar(
                  this.props.backTo,
                  "/step-7",
                  ctx.state.isDataReady,
                  label,
                  true,
                  () => ctx.saveBackProgress("step6"),
                  () => this.update(ctx, this.state)
                )
          }
        </ProductSwitchConsumer>
      </>
    );
  }
}

export default Step6;
