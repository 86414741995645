import React, { Component } from "react";
import { Container } from "reactstrap";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import ProductList from "../../components/molecules/ProductList/ProductList";
import DashBoardData from "./DashBoardData";
import {
  smallHeader,
  mainHeader,
  myShawbrookStatus,
  headerTitleNew,
  headerTitleInProgress,
  headerClassNew,
  headerClassInProgress,
  buttonNameSelect,
  buttonNameContinue,
  headerClassExpired,
  headerTitleExpired,
  buttonNameExpired
} from "../../data/data";
import { getMortgageList } from "../../services/api";
import { getCaseDetails } from "../../services/utils";
import EmptyDashBoardPage from "../../pages/EmptyDashboardPage/EmptyDashboard";
import Loader from "../../components/atoms/Loader/Loader";

var jwtdecode = require("jwt-decode");
var anyCasesAvailable = 0;
class DashBoardPage extends Component {
  constructor(props) {
    super(props);
    var caseDetails = jwtdecode(getCaseDetails());
    this.caseId = caseDetails.extension_BlueChipCaseID;
    this.state = {
      loading: true,
      products: props.products || [],
      status: [
        myShawbrookStatus.NO_STATUS,
        myShawbrookStatus.NEW,
        myShawbrookStatus.IN_PROGRESS
      ],
      headerClass: props.headerClass || "",
      headerText: props.headerText || "",
      selectName: props.selectName || ""
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    getMortgageList(this.caseId).then(res => {
      this.setState({
        products: res,
        loading: false
      });
    });
  }

  anycaseAvailable() {
    this.state.products.forEach(product => {
      if (product["status"] !== 3) {
        anyCasesAvailable++;
      }
    });
  }

  render() {
    this.anycaseAvailable();
    return (
      <Container>
        <ProductSwitchConsumer>
          {ctx => (
            <>
              {this.state.loading ? (
                <div className="mt-4" style={{ height: "200px" }}>
                  <p
                    style={{
                      width: "300px",
                      position: "relative",
                      textAlign: "left"
                    }}
                  ></p>
                  <Loader className="col-12" />
                </div>
              ) : anyCasesAvailable !== 0 ? (
                <>
                  <ScrollToPoint />
                  <DashBoardData
                    small_header={smallHeader}
                    main_header={mainHeader}
                  />
                  <ProductList
                    caseId={ctx.caseId}
                    products={this.state.products}
                    status={[
                      myShawbrookStatus.NO_STATUS,
                      myShawbrookStatus.NEW
                    ]}
                    headerClass={headerClassNew}
                    headerText={headerTitleNew}
                    selectName={buttonNameSelect}
                  />
                  <ProductList
                    caseId={ctx.caseId}
                    products={this.state.products}
                    status={[myShawbrookStatus.IN_PROGRESS]}
                    headerClass={headerClassInProgress}
                    headerText={headerTitleInProgress}
                    selectName={buttonNameContinue}
                  />
                  <ProductList
                  caseId={ctx.caseId}
                  products={this.state.products}
                  status={[myShawbrookStatus.EXPIRED,myShawbrookStatus.REQUESTEDACTIVATION]}
                  headerClass={headerClassExpired}
                  headerText={headerTitleExpired}
                  selectName={buttonNameExpired}
                />
                </>
              ) : (
                <>
                  <EmptyDashBoardPage />
                </>
              )}
            </>
          )}
        </ProductSwitchConsumer>
      </Container>
    );
  }
}

export default DashBoardPage;
