import React from "react";
import "./ReviewApplication.css";
import Step1Review from "../../molecules/Step1Review/Step1Review";
import Step2Review from "../../molecules/Step2Review/Step2Review";
import Step3Review from "../../molecules/Step3Review/Step3Review";
import Step4Review from "../../molecules/Step4Review/Step4Review";

class ReviewApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: props.context,
    };
  }
  render() {
    return (
      <div
        className="review-application-container"
        data-test="reviewApplication-component"
      >
        <Step1Review data={this.state.context} />
        <Step2Review data={this.state.context} />
        <Step3Review data={this.state.context} />
        <Step4Review data={this.state.context} />
      </div>
    );
  }
}

export default ReviewApplication;
