import React from 'react'

import './ContactUsCard.css'
import Button from '../../atoms/Button/Button';
import { withContactInfo } from '../../../services/ContactInfo';

class ContactUsCard extends React.Component {

    //Props -> phone_number = String
    // opening_hours = {[ { label=String, value=String } ]}

    renderOpeningHours() {
        if (this.props.opening_hours) {
            const opening_hours = this.props.opening_hours.map((line, index) =>
                <div key={index} className="contact-us__opening-hours-line mt-2">
                    <p className="contact-us__opening-hours-label">{line.label}</p>
                    <p className="contact-us__opening-hours-value">{line.value}</p>
                </div>
            );
            return opening_hours;
        } else {
            return <p>Closed</p>
        }
    }

    render() {
        return (
            <div className="contact-us">
                <div className="contact-us__inner">
                    <h5>Questions?</h5>
                    <p className="contact-us__phone-number">{this.props.phoneNumber}</p>
                    <div className="contact-us__opening-hours">
                        <div className="contact-us__opening-hours-line">
                            <p className="contact-us__opening-hours-label">Opening Hours</p>
                            <p className="contact-us__opening-hours-value">(Exc. Bank Holidays)</p>
                        </div>
                        <div className="contact-us__opening-hours-divider"></div>
                        {this.renderOpeningHours()}
                        <a href={`mailto:${this.props.email}`}>             
                            <Button className="contact-us__email-button mt-3" label="Send us an email" next />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export const WithInfoContactUsCard = withContactInfo(ContactUsCard)
export default ContactUsCard;