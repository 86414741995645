import { apiAxios } from "../services/api";
import {
  dataNotFoundMessage,
  localeString,
  dateFormatString,
  currencyString,
  currencyDecimalPlaces,
  deposit,
  internalDecoration,
  changeOfUse,
  extension,
  landscaping,
} from "../data/data";
import moment from "moment";
import {
  ltvThresholdValue,
  debtConsolidation,
  propertyImprovements,
  anotherPurpose,
} from "../data/data";
import authentication from "@kdpw/msal-b2c-react";

// Remove commas from a currency string
export const removeCommas = (str) => {
  if (str.toString().includes("/,/")) {
    str.replace(/,/g, "");
  }
  return str;
};

// Helper to requst and open pdf's from a url
export const requestPdf = (url, fileName) => {
  apiAxios
    .get(`/downloads/${url}`, { responseType: "blob" })
    .then((response) => {
      downloadPdf(response, fileName);
    });
};

export const downloadPdf = (response, fileName) => {
  if ((response.errors && response.errors.length) || !response.data) {
  } else {
    var pdfBlob = new Blob([response.data], { type: "application/pdf" });

    // This is for IE, must use saveOrOpenBlob function
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(pdfBlob);
      return;
    }

    const data = window.URL.createObjectURL(pdfBlob);
    var link = document.createElement("a");
    link.href = data;
    link.open = fileName + ".pdf";

    // _blank does not work in safari
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") <= -1) {
      // safari
      link.download = fileName + ".pdf";
    } else {
      // not safari
      link.target = "_blank";
    }

    link.click();
  }
};

export const isDefined = (i) => typeof i !== "undefined";

export const isDefinedAndNotNull = (i) => isDefined(i) && i !== null;

export const getCurrentDateTime = (date) => {
  return Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(isDefinedAndNotNull(date) ? new Date(date) : new Date());
};

export const isObj = (obj) => {
  while (Object.prototype.toString.call(obj) === "[object Object]") {
    // eslint-disable-next-line no-cond-assign, no-param-reassign
    if ((obj = Object.getPrototypeOf(obj)) === null) {
      return true;
    }
  }
  return false;
};

// Check if a value is boolean or null. When value was false, sometimes this caused if statements to evaluate wrong
export const booleanOrNull = (value) => (value === null ? null : value);
// Conversion of date formats from API (dd/mm/yyyy) to javascript date object format
export const dateFormat = (date) => {
  return moment(date).format(dateFormatString);
};

// Simple addition of percentage symbol to rate figures
export const rateConversion = (rate) =>
  isNumeric(rate) ? rate.toFixed(2) + "%" : "";
// Convert a number of months to nearest year. Used for term in months from api response
export const monthsToYear = (months) => Math.floor(months / 12);
// Helper to throw an error from an async request that can be picked up by React Error Boundary
export const handleAsyncError = (error) => {
  throw new Error(error);
};
// Polyfill function for Object.values() in Internet explorer
export const objectValues = (obj) =>
  typeof obj === "object" && Object.keys(obj).map((e) => obj[e]);
// Check if the user agent is Internet explorer
export const isIE = () => (!!document.documentMode ? true : false);
// Check if the user agent is Edge
export const isEdge = () => (!isIE() && !!window.StyleMedia ? true : false);

// Convert to numeric and apply formatter if a number
const currencyWrapper = (formatter) => (currency) => {
  const num = Number(currency);
  if (
    currency === null ||
    typeof currency === "undefined" ||
    isNaN(currency) ||
    isNaN(num) ||
    currency === ""
  ) {
    return "";
  }
  if (num === 0) {
    return "0";
  }
  return formatter(num);
};

// Format a number into a currency standard xxx,xxx.xx without the £ symbol
export const formatCurrency = currencyWrapper((num) =>
  num.toLocaleString(localeString, {
    style: "decimal",
    minimumFractionDigits: currencyDecimalPlaces,
    maximumFractionDigits: currencyDecimalPlaces,
  })
);

// Formatting a number into a currency standard (£xxx,xxx.xx). If chrome, one more substring required to deal with space afetr GBR
export const fullFormatCurrency = currencyWrapper((num) =>
  num.toLocaleString(localeString, {
    style: "currency",
    currency: currencyString,
    currencyDisplay: "symbol",
    minimumFractionDigits: currencyDecimalPlaces,
    maximumFractionDigits: currencyDecimalPlaces,
  })
);

// Get Todays date and format it to a GB stye string dd/mm/yyyy
export const todaysDate = () => {
  var today = new Date();
  return today.toLocaleDateString(localeString);
};
// Offers are valid for 2 weeks from the date that it is given. (Todays date)
export const offerValidDate = () => {
  var date = new Date();
  date.setDate(date.getDate() + 14);
  return date.toLocaleDateString(localeString);
};
// Check if variable is Number
export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
// Check if variable is function
export const isFunction = (f) => !!(f && typeof f === "function");
// Check if variable is function and call it passing the arguments
export const ifIsFunctionCall = (f, ...args) => isFunction(f) && f(...args);
// Check if object is empty
export const isEmptyObject = (arg) => {
  if (arg == null) return true;
  else if (Object.keys(arg).length === 0 && arg.constructor === Object)
    return true;
  return false;
};
// Loan to Value
export const loanToValue = (loan, valuation) =>
  Math.ceil((loan / valuation) * 100);
// ProductCode Normalise wrapper (removes f)
export const normaliseProductCode = (productCode) => {
  if (productCode.substr(-1) === "f") {
    return productCode.substring(0, productCode.length - 1);
  } else {
    return productCode;
  }
};
// Normalise Term into a months value, this is because sometimes years is 0 but we have months. This normalises any combination into a months only value
export const normaliseTerm = (years, months) => {
  return years * 12 + months;
};

export const ucFirst = (s) => s && s[0].toUpperCase() + s.slice(1);

// A Normalisation function to check if data exists.
// If data doesn't exist display a standard message else display the data or transformed data
export const dataExists = (data, dataFunction = null) => {
  if (data) {
    if (dataFunction) {
      return dataFunction(data);
    } else {
      return data;
    }
  } else {
    return dataNotFoundMessage;
  }
};

// If data is undefined/null or length is 0 return null or return the value
export const returnDataIfExists = (data) => {
  if (
    data.address1 === "" &&
    data.address2 === "" &&
    data.address3 === "" &&
    data.address4 === "" &&
    data.postcode === ""
  ) {
    return false;
  } else {
    return true;
  }
};

export const returnaddress = (data) => {
  var addressData = "";
  for (var key in data) {
    if (data[key] !== "") {
      addressData += " " + data[key] + "-";
    }
  }
  var addressResult = addressData.trim().split("-").join(",");
  var result = addressResult.substring(0, addressResult.length - 1);
  return result;
};

//function to split the address
export const splitAddress = (data) => {
  if (typeof data === "undefined" || data === null) {
    return null;
  } else {
    return data.split(",")[0];
  }
};

// Reduce the product object to just the required fields to map back to bluechip
// This object is sent into the /Case/${caseId}/CompleteApplication API.
export const transformProductForBlueChip = (product) => {
  return {
    productHash: product.productHash,
    productId: product.productId,
    productVersionId: product.productVersionId,
    fixedProductVersionId: product.fixedProductVersionId,
    termId: product.termId,
    ltvRangeId: product.ltvRangeId,
    productPropertyBucketRecordId: product.productPropertyBucketRecordId,
    fixedTermInMonths: product.fixedTermInMonths,
    fixedReversionRate: product.fixedReversionRate,
    postFixedMonthlyRepayment: product.postFixedMonthlyRepayment,
  };
};

export const determineProgress = (key) => {
  switch (key) {
    case "step1":
      return "28%";
    case "step2":
      return "42%";
    case "step3":
      return "56%";
    case "step4":
      return "70%";
    case "step5":
      return "84%";
    case "step6":
      return "86%";
    case "step7":
      return "100%";
    case "declaration":
      return "84%";
    case "referral":
      return "100%";
    case "confirmation":
      return "100%";
    default:
      return "0%";
  }
};

// Remove previously entered values when blob is sent to letter history.
export const cleanLetterHistoryValuesForCompletion = (state) => {
  if (state.step3.propertyAlterations) {
    const allowedChangeValues = ["internal_decoration", "landscaping"];
    state.step3.typeOfChange = state.step3.typeOfChange.filter((value) =>
      allowedChangeValues.includes(value)
    );
  } else {
    state.step3.typeOfChange = [];
    state.step3.propertyChangesDetails = "";
  }

  state.step2.expected_value = "";
  state.step2.loan_reason = [];
  state.step2.loan_reason_detail = "";
  state.step3.furtherDetails = "";
  state.step3.claimsInformation = "";

  return state;
};

// Change the context state into just section that the user has input
export const transformStateForBlob = (state) => {
  var selectedProduct = {};

  // Send only part of the selected product that are relevant to Blob
  if (state.selectedProduct) {
    var product = state.selectedProduct;
    selectedProduct = transformProductForBlueChip(product);
    selectedProduct.margin = product.margin;
    selectedProduct.remaining_balance = product.remaining_balance;
    selectedProduct.fixed = product.fixed;
    selectedProduct.allInRate = product.allInRate;
    selectedProduct.fixedRate = product.fixedRate;
    selectedProduct.fixedReversionRate = product.fixedReversionRate;
    selectedProduct.termInMonths = product.termInMonths;
    selectedProduct.fixedTermInMonths = product.fixedTermInMonths;
    selectedProduct.monthlyRepayment = product.monthlyRepayment;
    selectedProduct.future_ltv = product.future_ltv;
    selectedProduct.interestOnly = product.interestOnly;
    selectedProduct.capitalAndInterest = product.capitalAndInterest;
    selectedProduct.postFixedMonthlyRepayment =
      product.postFixedMonthlyRepayment;
    selectedProduct.arrangementFeePerc = product.arrangementFeePerc;
    selectedProduct.capitalRaise = state.step3.capitalRaise;
  }

  // Reduce the state to include only user inputs
  return {
    step2: state.step2,
    step3: state.step3,
    step4: state.step4,
    step7: state.step7,
    selectedProduct: selectedProduct,
  };
};

// Each rule has value, operator, and expected Value
// By creating a rule checker and structure, we can extract the rules
export const referralRules = (values) => {
  return [
    {
      operator: "OR",
      rules: [
        {
          value: values.step2.avm_agreed,
          operator: "equals",
          expectedValue: false,
        },
        {
          value: values.step2.accept_value,
          operator: "equals",
          expectedValue: false,
        },
        {
          value: values.step3.additionalInformation,
          operator: "equals",
          expectedValue: true,
        },
        { value: values.step3.claims, operator: "equals", expectedValue: true },
        {
          operator: "AND",
          rules: [
            {
              value: values.step3.propertyAlterations,
              operator: "equals",
              expectedValue: true,
            },
            {
              operator: "OR",
              rules: [
                {
                  value: values.step3.typeOfChange,
                  operator: "includes",
                  expectedValue: "changeOfUse",
                },
                {
                  value: values.step3.typeOfChange,
                  operator: "includes",
                  expectedValue: "extension",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

//return fileUrl for download
export function getFileName(productCode) {
  return `productinformation?productcode=${productCode
    .substring(0, 3)
    .toLowerCase()}`;
}

// Recursive function to check all rules provided. Returns a Boolean.
export const ruleChecker = (rules) => {
  return rules.every(checkRule);
};

// Determine check for different types of rules.
export const checkRule = (rule) => {
  switch (rule.operator) {
    case "AND":
      return rule.rules.every(checkRule);
    case "OR":
      return rule.rules.some(checkRule);
    case "equals":
      return rule.value === rule.expectedValue;
    case "includes":
      if (rule.not) {
        return !rule.value.includes(rule.expectedValue);
      } else {
        return rule.value.includes(rule.expectedValue);
      }
    default:
      // This should never be triggered as all rules provided should have an operator property
      throw new Error("Rule provided in RuleChecker needs a valid operator.");
  }
};

export const shouldRefer = (state) => {
  if (typeof state.selectedProduct !== "undefined") {
    if (
      parseFloat(state.selectedProduct.future_ltv) > 75 ||
      (state.step4.mortgageType === "capital_repayment" &&
        state.selectedProduct.capitalAndInterest) ||
      state.selectedProduct.capitalAndInterest
    ) {
      return true;
    } else {
      return ruleChecker(referralRules(state));
    }
  } else {
    return ruleChecker(referralRules(state));
  }
};

export const isProductTransfer = (applicationCreationTypeID) => {
  if (applicationCreationTypeID === 13) {
    return true;
  }
  return false;
};

/**
 * This function check whether the save product information is same as the product passed
 *Need to improve this with something as above rules
 * @param {object } selectedProduct
 * @param {object} product
 * @returns {boolean} isSelected
 */
export const isSelectedProduct = (selectedProduct, product) => {
  let isSelected = false;
  if (selectedProduct === null) {
    isSelected = false;
  } else if (
    selectedProduct.margin === product.margin &&
    selectedProduct.fixed === product.fixed &&
    selectedProduct.allInRate === product.allInRate &&
    selectedProduct.fixedRate === product.fixedRate &&
    selectedProduct.fixedReversionRate === product.fixedReversionRate &&
    selectedProduct.termInMonths === product.termInMonths &&
    selectedProduct.fixedTermInMonths === product.fixedTermInMonths &&
    selectedProduct.monthlyRepayment === product.monthlyRepayment &&
    selectedProduct.interestOnly === product.interestOnly &&
    selectedProduct.capitalAndInterest === product.capitalAndInterest &&
    selectedProduct.arrangementFeePerc === product.arrangementFeePerc
  ) {
    isSelected = true;
  }
  return isSelected;
};

/**
 * Should have better logic for the conditions
 * Returns the the status whether add controller bar should be enabled or not?
 * @props {state} state
 * @props {ctx} context
 * @returns {enableAddControllerBar} boolean
 */
export const shouldShowStep2AddBarComponent = (state, ctx) => {
  let enableAddControllerBar = false;
  if (
    state.avm_agreed ||
    (!state.avm_agreed && state.expected_value.length > 0)
  ) {
    if (state.accept_value && state.future_ltv < ltvThresholdValue) {
      enableAddControllerBar = true;
    }

    if (!state.accept_value && state.accept_value !== null) {
      if (
        parseInt(state.desired_loan) >
        parseInt(ctx.state.mortgageDetails.remaining_balance)
      ) {
        if (
          state.desired_loan &&
          state.loan_reason.length > 0 &&
          state.loan_reason_detail.length > 0 &&
          state.future_ltv < ltvThresholdValue
        ) {
          enableAddControllerBar = true;
        }
      } else if (state.desired_loan && state.future_ltv < ltvThresholdValue) {
        enableAddControllerBar = true;
      }
    }
  }
  return enableAddControllerBar;
};

export const transformProductForMarketingPreferences = (preferences) => {
  const defaultData = {
    Email: false,
    GDPRAgreement: true,
    Phone: false,
    Post: false,
    SMS: false,
  };

  return preferences.reduce(
    (obj, pref) => ({ ...obj, [pref]: true }),
    defaultData
  );
};

//This function checks the selected loan reason on step2 and returns the value on the step6
export const loanReason = (data) => {
  var loanReasonsList = {
    debt_consolidation: debtConsolidation,
    property_improvements: propertyImprovements,
    deposit: deposit,
    another_purpose: anotherPurpose,
  };

  if (data !== null) {
    return loanReasonsList[data];
  } else {
    return dataNotFoundMessage;
  }
};

export const typeOfChanges = (data) => {
  var typeOfChangesList = {
    internal_decoration: internalDecoration,
    changeOfUse: changeOfUse,
    extension: extension,
    landscaping: landscaping,
  };
  if (data !== null) {
    return typeOfChangesList[data];
  } else {
    return dataNotFoundMessage;
  }
};

//It gets the case details from the generated access token.
//It gets the case details from the generated access token.
export const getCaseDetails = () => {
  var token =
    sessionStorage.getItem("isPrint") !== undefined &&
    sessionStorage.getItem("isPrint") !== null &&
    sessionStorage.getItem("isPrint")
      ? sessionStorage.getItem("printToken")
      : authentication.getAccessToken();
  return token;
};

//It gets the caseId and token to bypass the b2c
export const extractQueryParams = () => {
  sessionStorage.setItem("isPrint", true);
  var params = new URLSearchParams(window.location.search);
  var caseId = params.get("caseId");
  var accessToken = params.get("accessToken");
  sessionStorage.setItem("caseId", caseId);
  sessionStorage.setItem("printToken", accessToken);
};
