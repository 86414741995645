import React from "react";
import { Container, Row, Col } from "reactstrap";
import Button from "../../atoms/Button/Button";
import {
  rateConversion,
  monthsToYear,
  fullFormatCurrency,
} from "../../../services/utils";
import "./ProductCard.css";
import {
  requestPdf,
  getFileName,
  isSelectedProduct,
} from "../../../services/utils";
import { ProductSwitchConsumer } from "../../Organisms/ProductSwitch/ProductSwitchContext";

class ProductCard extends React.Component {
  render() {
    const product = this.props.product;
    const fixedTerm = monthsToYear(product.fixedTermInMonths);
    const term = monthsToYear(product.termInMonths);
    return (
      <>
        <ProductSwitchConsumer>
          {(ctx) => (
            <div
              className={`${
                isSelectedProduct(ctx.state.selectedProduct, product)
                  ? "selected-product-card"
                  : "product-card"
              }`}
              data-test={`${
                isSelectedProduct(ctx.state.selectedProduct, product)
                  ? "selected-product-card"
                  : ""
              }`}
            >
              <Container className="pb-4 pl-4">
                <Row className="pt-4">
                  <Col>
                    <p
                      style={{ fontWeight: "bold" }}
                      className="product-card__rate-type"
                    >
                      {product.fixed === true
                        ? `${fixedTerm} Year Fixed`
                        : "Variable"}
                    </p>
                    <h2 className="product-card__rate">
                      {product.fixed
                        ? rateConversion(product.fixedRate)
                        : rateConversion(product.allInRate)}
                    </h2>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <p>Maximum {product.maximumLTV}% LTV</p>
                    <p className="product-card__mortgage-type">
                      {product.interestOnly === true
                        ? "Interest only"
                        : "Capital Repayment"}
                    </p>
                    <p className="product-card__term">{term} year term</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {product.fixed !== true ? (
                      <p>
                        Tracks {rateConversion(product.margin)} above{" "}
                        {product.arrName}*
                      </p>
                    ) : (
                      <p>
                        Reverts to {rateConversion(product.fixedReversionRate)}{" "}
                        after fixed period
                      </p>
                    )}
                  </Col>
                </Row>
                <div className="product-card__divider mt-2 mb-2"></div>
                <Row>
                  <Col>
                    <p>Monthly payment</p>
                    <h2 className="product-card__monthly-repayment">
                      {fullFormatCurrency(product.monthlyRepayment)}
                    </h2>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <p data-test="arrangement-fee">Arrangement Fee</p>
                    <p
                      data-test="arr-fee-perc"
                      className="product-card__mortgage-type"
                    >
                      {product.arrangementFeePerc}%
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {product.fixed === true ? (
                      <p>
                        Reverts to{" "}
                        {fullFormatCurrency(product.postFixedMonthlyRepayment)}{" "}
                        after fixed period{" "}
                      </p>
                    ) : (
                      <p>&nbsp;</p>
                    )}
                  </Col>
                </Row>
                <Col md="9" className="pl-0">
                  <Button
                    className={
                      isSelectedProduct(ctx.state.selectedProduct, product)
                        ? "product-card__selected-product"
                        : "product-card__select-product"
                    }
                    type="primary"
                    next
                    handleClick={() => ctx.selectProduct(this.props.product)}
                    label={
                      isSelectedProduct(ctx.state.selectedProduct, product)
                        ? "Selected"
                        : "Select this product"
                    }
                  ></Button>
                </Col>
              </Container>
              <div className="product-card__information">
                <p style={{ fontWeight: "bold" }} className="ml-3 pt-2 pb-2">
                  {product.productCode} Residential Mortgage
                  <button
                    className={`${this.props.className} information-icon`}
                    onClick={() => {
                      const fileName = getFileName(product.productCode);
                      requestPdf(fileName, fileName);
                    }}
                  >
                    i
                  </button>
                </p>
              </div>
            </div>
          )}
        </ProductSwitchConsumer>
      </>
    );
  }
}

export default ProductCard;
