import React from "react";
import { Container } from "reactstrap";
import authentication from "../../services/auth";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import ProductSearch from "../../components/molecules/ProductSearch/ProductSearch";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import { renderControllerBar } from "../../services/navFooterForPages";
import { JsonSchema } from "../../data/stepData";
var jwtdecode = require("jwt-decode");

class Step4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caseDetails: jwtdecode(authentication.getAccessToken()),
    };
  }

  stepfn(ctx){
    ctx.state.selectedProduct = Object.assign({},ctx.previousState.selectedProduct);
    ctx.saveBackProgress("step4");
  }

  render() {
    const label = "Continue";
    const step4 = JsonSchema.step4.sections[0];
    return (
      <Container fluid className="step4">
        <ProductSwitchConsumer>
          {(ctx) => (
            <>
              <ScrollToPoint></ScrollToPoint>
              {ctx.state.isBroker ? (
                <>
                  <PageIntroduction
                    small_header={step4.currentStepNumberForBroker}
                    main_header={step4.headerDescription}
                  />
                  <ProgressBar now={44}></ProgressBar>
                </>
              ) : (
                <>
                  <PageIntroduction
                    small_header={step4.currentStepNumberForCustomer}
                    main_header={step4.headerDescription}
                  />
                  <ProgressBar now={50}></ProgressBar>
                </>
              )}

              <ProductSearch
                clientId={this.state.caseDetails.extension_IdentityId}
                caseId={this.state.caseDetails.extension_BlueChipCaseID}
                mortgageDetails={ctx.state.mortgageDetails}
                desired_loan={
                  ctx.state.step2.desired_loan
                    ? ctx.state.step2.desired_loan
                    : ctx.state.mortgageDetails.remaining_balance
                }
                maxTerm={ctx.state.maxTerm}
                minTerm={ctx.state.minTerm}
                step2={ctx.state.step2}
                step3={ctx.state.step3}
                step4={ctx.state.step4}
                selectedProduct={ctx.state.selectedProduct}
              />
              {renderControllerBar(
                this.props.backTo,
                this.props.continueTo,
                true,
                label,
                /** Need to improve this logic of intializing selectedproduct
                    because it sometimes returns null, sometimes empty object and
                    sometime returns Object that is not consitent */
                  typeof ctx.state.selectedProduct !== Object &&
                  ctx.state.selectedProduct !== null &&
                  Object.entries(ctx.state.selectedProduct).length > 0,
                  () =>  this.stepfn(ctx),
                  () =>  ctx.saveProgress("step4", this.state)
              )}
            </>
          )}
        </ProductSwitchConsumer>
      </Container>
    );
  }
}

export default Step4;
