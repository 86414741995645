import React from 'react'
import StaticInformationField from '../../atoms/StaticInformationField/StaticInformationField'
import InformationIcon from '../../atoms/InformationIcon/InformationIcon'
import { Row, Col } from 'reactstrap'
import { ProductSwitchConsumer } from '../../Organisms/ProductSwitch/ProductSwitchContext'
import './AccountInformation.css'
import { objectValues, dataExists } from '../../../services/utils'
import { yourAccountModal } from '../../../data/data'
import { JsonSchema } from '../../../data/stepData'
require('es6-promise').polyfill();

const accountInformationFields = JsonSchema.step1.sections[0].accountInformation.staticInformationField
const accountInformation = JsonSchema.step1.sections[0].accountInformation
const AccountInformation = props => (
    <ProductSwitchConsumer>
        {ctx => (
            <Row >
                <Col className="account-information__inner">
                    <h5>{accountInformation.header}</h5>
                    <InformationIcon className="account-information__main-information" {...yourAccountModal} />
                    <StaticInformationField label={accountInformationFields[0].label} value={dataExists(ctx.state.accountNumber)} />
                    <StaticInformationField label={accountInformationFields[1].label} value={dataExists(ctx.state.mortgageDetails.accountName)} />
                    <StaticInformationField label={accountInformationFields[2].label} value={ctx.state.guarantors ? ctx.state.guarantors : [] } />
                    <StaticInformationField label={accountInformationFields[3].label} value={dataExists(ctx.state.mortgageDetails.securityAddress, objectValues)} />
                </Col>
            </Row>
        )}
    </ProductSwitchConsumer>
)

export default AccountInformation;