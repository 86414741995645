import axios from "axios";
import authentication from "./auth";
import { apiUrl, saveState } from "../config";

require("es6-promise").polyfill();

// All requests got to the same API by default
axios.defaults.baseURL = apiUrl;

// Dynamically set the Token in case it is refreshed
axios.interceptors.request.use(
  (config) => {
    const token =
      sessionStorage.getItem("isPrint") !== undefined &&
      sessionStorage.getItem("isPrint") !== null &&
      sessionStorage.getItem("isPrint")
        ? sessionStorage.getItem("printToken")
        : authentication.getAccessToken();

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const apiAxios = axios;

const getPdf = (url) =>
  axios(url, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
  })
    .then((response) => {
      if (response.status === 204) {
        return Promise.resolve(null);
      }

      if ((response.errors && response.errors.length) || !response.data) {
        throw new Error("Error getting your mortgage details"); //
      } else {
        return response;
      }
    })
    .catch((err) => {
      throw new Error(`Error getting: ${url}`);
    });

const get = (url) =>
  axios
    .get(url)
    .then((response) => {
      if (response.status === 204) {
        return Promise.resolve(null);
      }

      if ((response.errors && response.errors.length) || !response.data) {
        throw new Error("Error getting your mortgage details"); //
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      throw new Error(`Error getting: ${url}`);
    });

export const post = (url, data, expectResponse = false) =>
  axios
    .post(url, data)
    .then((response) => {
      if (
        (response.errors && response.errors.length) ||
        (!response.data && expectResponse)
      ) {
        throw new Error(`Error posting to: ${url}`);
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      throw new Error(`Error posting to: ${url}`);
    });

const put = (url, data, expectResponse = false) =>
  axios
    .put(url, data)
    .then((response) => {
      if (
        (response.errors && response.errors.length) ||
        (!response.data && expectResponse)
      ) {
        throw new Error(`Error putting to: ${url}`);
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      throw new Error(`Error posting to: ${url}`);
    });

export const getMortgageList = (ids) => get(`/mortgage/list/${ids}`);

export const updateMyshawbrookStatus = (id) =>
  put(`/mortgage/myshawbrookstatus/${id}`);

export const requestActivation = (id) =>
  put(`/mortgage/requestactivation/${id}`);

export const getMortgage = (id) => get(`/mortgage/${id}`);

export const completeApplication = (caseId, request) =>
  put(`/Case/${caseId}/CompleteApplication`, request).catch((error) => {
    console.error(error);
    throw new Error("Error completing application.");
  });

export const referApplication = (caseId, request) =>
  put(`/Case/${caseId}/ReferApplication`, request).catch((error) => {
    console.error(error);
    throw new Error("Error referring application.");
  });

// Send the state to azure blob storage so that when the user returns we can populate the
// information they previously entered and send them to the correct page
// We should only send user input to be saved in blob and not everything.
export const sendState = (id, postData) =>
  saveState ? post(`/state/${id}`, postData) : Promise.resolve(false);

//API call to update the current step to the blob object when back button is pressed
export const sendCurrentStepState = (id, currentStep) =>
  put(`/stepstate/${id}?currentStep=${currentStep}`);

export const requestOffer = (id, type) =>
  post(`/mortgage/formalOffer/${id}`, { type });

export const getContactInfo = () => get(`/static/contactinfo`);

export const getGeneratedPdf = (id) =>
  getPdf(`/mortgage/getapplicationform/${id}`);

export const getFeatureFlag = (key) => get(`/featureflag/${key}`);

export const getBrokerByCaseId = (id) => get(`/mortgage/broker/${id}`);
