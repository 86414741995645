import React from 'react';

class ScrollToPoint extends React.Component {
    constructor(props) {
        super(props)

        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        if( this.scrollRef.current ) {
            window.scrollTo(0,0);
        }
    }

    render() {
        return(
            <span ref={this.scrollRef} />
        )
    }
}

export default ScrollToPoint;
