import React from "react";
import "./App.css";
import { Router, Route } from "react-router-dom";
import Header from "./components/molecules/Header/Header";
import ProductSwitch from "./components/Organisms/ProductSwitch/ProductSwitch";
import Footer from "./components/molecules/Footer/Footer";
import ErrorBoundary from "./pages/Error/Error";
import { Container } from "reactstrap";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { ContactInfoProvider } from "./services/ContactInfo";
import { gaKey } from "./config";
import { printUrl } from "./data/data";
ReactGA.initialize(gaKey);

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.pageview(location.pathname + location.search);
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false
    };

    // Important to handled B2C OIDC Forgot password
    if (window.location.hash.indexOf("AADB2C90118") >= 0) {
      // eslint-disable-next-line
      history.push("/forgot");
    }
  }


  handleLogin = () => {
    this.setState({ loggedIn: true });
  };

  // We want to use the same path for both component but render conditionally on loggedIn condition
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Route path="/" component={AppContent} />
          <Route path="/forgot" component={Todo} />
        </Router>
      </div>
    );
  }
}

const Todo = () => <h2>TODO</h2>;

const AppContent = () => {
  return (
    <>
      <ContactInfoProvider>
       {window.location.pathname === printUrl ?
       (
          <div className="main-area">
              <ProductSwitch></ProductSwitch>
        </div>
        )
         :
         (
         <>
         <div className="main-area">
          <Header></Header>
          <Container className="page-content">
            <ErrorBoundary>
              <ProductSwitch></ProductSwitch>
            </ErrorBoundary>
          </Container>
          </div>
        <Footer></Footer>
        </>
       )
        }
      </ContactInfoProvider>
    </>
  );
};

export default App;
