import React from "react";
import { NavLink } from "react-router-dom";
import { Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./BackButton.css";
import PropTypes from "prop-types";

function BackButton({ to,handleClick, backButtonClass = "back-button" }) {
  return (
    <Row>
      <NavLink to={to} className="no-decor" onClick={handleClick} >
        <div className={backButtonClass} >
          <FontAwesomeIcon
            style={{ fontSize: "13px" }}
            icon={faChevronLeft}
          ></FontAwesomeIcon>
          &nbsp;Back
        </div>
      </NavLink>
    </Row>
  );
}
BackButton.propTypes = {
  handleClick: PropTypes.func
};
export default BackButton;
