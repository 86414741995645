import React from "react";
import { Row, Col } from "reactstrap";

import Note from "../../atoms/Note/Note";
import RangeInput from "../../atoms/RangeInput/RangeInput";
import ButtonSelect from "../../atoms/ButtonSelect/ButtonSelect";
import ProductResults from "../ProductResults/ProductResults";
import { ProductSwitchConsumer } from "../../Organisms/ProductSwitch/ProductSwitchContext";
import Loader from "../../atoms/Loader/Loader";
import { apiAxios } from "../../../services/api";
import { shouldRefer } from "../../../services/utils";
import "./ProductSearch.css";
import { JsonSchema } from "../../../data/stepData";

class ProductSearch extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      // Boolean to describe if the products api is waiting for a response
      loading: true,
      // Determines the motrgage type that the user has selected to view.
      mortgage_type: "",
      // Determines the rate type that the user has selected to view
      rate_type: this.props.mortgageDetails.isProductTransfer ? "fixed" : "",
      // The mortgage term that the user has selected on the slider. Defaults to the term of the users current mortgage
      term: this.determineDefault(),
      // The maximum and minimum values possible of fixed term based on the products returned from the product api
      fixedTermRange: [2, 10],
      // The selected range of fixed term that the user wants to display
      fixedTerm: [2, 10],
      // The full list of products returned from the products api
      allProducts: [],
      // The filtered list of products to display based on the users selctions
      results: [],
      //Alternative Reference Rate name, longName, Value
      liborRate: 0,
      arrName: "",
      arrLongName: "",
      arrValue: 0,
      // Boolean to describe if the fixed term slider should be rendered
      showFixedTermSlider: true,
      termProductTransfer: this.determineTermProductTransfer(),
    };
  }

  componentDidMount() {
    const referralState = { step2: this.props.step2, step3: this.props.step3 };
    const isReferralJourney = shouldRefer(referralState);
    this.getAllProducts(isReferralJourney);
    this._isMounted = true;
  }

  // Prevents memory leak of attempted setState from async request when loadProgress changes page
  componentWillUnmount() {
    this._isMounted = false;
  }

  getAllProducts(isReferralJourney) {
    this.setState({ loading: true });

    var loanSize = 0;
    var termMonths = Number(this.state.term) * 12;
    var expectedPropertyValue;
    var isReferral = true;
    var isAVMAgreed = true;

    this.props.step2.expected_value !== null &&
    this.props.step2.expected_value !== ""
      ? (expectedPropertyValue = this.props.step2.expected_value)
      : (expectedPropertyValue =
          this.props.mortgageDetails.automated_valuation);

    if (this.props.mortgageDetails.isProductTransfer) {
      termMonths = this.state.termProductTransfer;
      loanSize = this.props.mortgageDetails.remaining_balance;
    } else {
      //If termmonths is 0, then the default term will be passed from the mortgagedetails
      if (termMonths === 0) {
        termMonths = this.props.mortgageDetails.termMonths;
      }
      //TODO: in production, a desired_loan should always be passed.
      if (this.props.desired_loan) {
        loanSize = Math.ceil(this.props.desired_loan);
      }
      isReferral = isReferralJourney;
      isAVMAgreed = this.props.step2.avm_agreed;
    }

    apiAxios
      .post(
        `/product?caseId=${sessionStorage.getItem(
          "caseId"
        )}&termMonths=${termMonths}&clientId=${
          this.props.clientId
        }&expectedPropertyValue=${expectedPropertyValue}&loanSize=${loanSize}&isReferral=${isReferral}
        &avmAgreed=${isAVMAgreed}`,
        {},
        true
      )
      .then((response) => {
        if ((response.errors && response.errors.length) || !response.data) {
          //TODO: Implement error handling approach
        } else {
          if (this._isMounted)
            this.setState(
              {
                allProducts: response.data,
                liborRate: response.data[0].liborRate,
                arrName: response.data[0].arrName,
                arrLongName: response.data[0].arrLongName,
                arrValue: response.data[0].arrValue,
              },
              () => {
                this.getProducts();
                this.determineFixedTermRange();
              }
            );
        }
      })
      .catch((err) => {
        this.setState({ allProducts: [], liborRate: "Not found" }, () =>
          this.getProducts()
        );
      });
  }

  // This will be moved to the backend and a call will be made each time.
  getProducts() {
    var filteredProducts = this.state.allProducts;
    if (this.state.rate_type) {
      filteredProducts = filteredProducts.filter(
        (product) =>
          this.state.rate_type === (product.fixed ? "fixed" : "variable")
      );
    }

    if (this.state.mortgage_type) {
      filteredProducts = filteredProducts.filter((product) => {
        // If we can assume that product.capitalAndInterest and product.interestOnly
        // are mutually exclusive then we can just check one of the 2 properties
        return (
          this.state.mortgage_type ===
          (product.interestOnly === true ? "interest" : "capital_repayment")
        );
      });
      this.props.step4.mortgageType = this.state.mortgage_type;
    }

    if (this.state.rate_type !== "variable" && this.state.fixedTerm) {
      filteredProducts = filteredProducts.filter((product) => {
        // When no rate_type selection is made, return any variable product
        // and any fixed product that has a term within the range of the fixed term slider
        return (
          !product.fixed ||
          (product.fixed &&
            this.state.fixedTerm[0] <= product.fixedTermInMonths / 12 &&
            product.fixedTermInMonths / 12 <= this.state.fixedTerm[1])
        );
      });
    }

    this.setState({
      results: filteredProducts,
      loading: false,
      showFixedTermSlider: this.shouldShowFixedTermSlider(filteredProducts),
    });
  }

  handleMortgageType = (value) => {
    this.setState({ mortgage_type: value }, () => {
      this.getProducts();
    });
  };

  handleRateType = (value) => {
    if (value === "fixed") {
      var fixedTerm = [
        this.state.fixedTermRange[0],
        this.state.fixedTermRange[this.state.fixedTermRange.length - 1],
      ];
    }
    this.setState({ rate_type: value, fixedTerm: fixedTerm }, () => {
      this.getProducts();
    });
  };

  handleMortgageTerm = (value) => {
    const stepState = { step2: this.props.step2, step3: this.props.step3 };
    this.setState({ term: Number(value) }, () =>
      this.getAllProducts(shouldRefer(stepState))
    );
  };
  handleFixedTerm = (value) =>
    this.setState({ fixedTerm: value }, () => {
      this.getProducts();
    });

  determineDefault() {
    if (this.props.selectedProduct !== null) {
      var obj = Object.keys(this.props.selectedProduct).length;
      if (obj !== 0) {
        var selectedProductTerm = Math.floor(
          this.props.selectedProduct.termInMonths / 12
        );
        if (selectedProductTerm > this.props.maxTerm) {
          selectedProductTerm = this.props.maxTerm;
        }
        return selectedProductTerm;
      } else {
        var defaultTermMonths = Math.floor(
          this.props.mortgageDetails.termMonths / 12
        );
        if (defaultTermMonths > this.props.maxTerm) {
          defaultTermMonths = this.props.maxTerm;
        }
        return defaultTermMonths;
      }
    } else {
      var defaultTerm = Math.floor(this.props.mortgageDetails.termMonths / 12);
      if (defaultTerm > this.props.maxTerm) {
        defaultTerm = this.props.maxTerm;
      }
      return defaultTerm;
    }
  }

  determineTermProductTransfer() {
    const to = new Date(this.props.mortgageDetails.end_date);
    const from = new Date();
    var months =
      to.getMonth() -
      from.getMonth() +
      12 * (to.getFullYear() - from.getFullYear());
    if (to.getDate() < from.getDate()) {
      months--;
    }
    return Math.floor(months / 12) * 12; 
   }

  determineFixedTermRange() {
    var unique = [
      ...new Set(
        this.state.allProducts.map((product) => product.fixedTermInMonths)
      ),
    ];
    unique = unique.map((term) => term / 12).sort((a, b) => a - b);

    if (unique.includes(0)) unique.splice(unique.indexOf(0), 1);

    // If no fixed products are returned, prevent undefined errors sent to the slider.
    if (!unique[0]) unique = [0, 0];

    if (this._isMounted) {
      this.setState({ fixedTermRange: unique });
    }
  }

  // Don't show the fixed term slider if vairable mortgage type is selected OR
  // if there are no fixed term products returned.
  shouldShowFixedTermSlider(results) {
    if (this.state.rate_type === "fixed") {
      return true;
    }

    if (
      results.some(function(product) {
        return product.fixed === true;
      })
    ) {
      return true;
    }

    return false;
  }

  render() {
    const step4 = JsonSchema.step4.sections[0];
    const mortgageRequirementSelections1 =
      JsonSchema.step4.sections[0].mortgageRequirementSelections[0];
    const mortgageRequirementSelections1Options =
      JsonSchema.step4.sections[0].mortgageRequirementSelections[0].options;
    const mortgageRequirementSelections2 =
      JsonSchema.step4.sections[0].mortgageRequirementSelections[1];
    const mortgageRequirementSelections2Options =
      JsonSchema.step4.sections[0].mortgageRequirementSelections[1].options;
    const mortgageRequirementSelections3 =
      JsonSchema.step4.sections[0].mortgageRequirementSelections[2];
    const mortgageRequirementSelections4 =
      JsonSchema.step4.sections[0].mortgageRequirementSelections[3];
    const step4ResultsMessages =
      JsonSchema.step4.sections[0].results.messages[0];
    return (
      <ProductSwitchConsumer>
        {(ctx) => (
          <Row className="mt-4">
            <Col xs="12" md="6" className="p-0 pr-md-4 box-height">
              <Row>
                <Col>
                  <h5>{step4.header}</h5>
                </Col>
              </Row>
              <ButtonSelect
                label={mortgageRequirementSelections1.description}
                options={[
                  {
                    label: mortgageRequirementSelections1Options[0].label,
                    value: mortgageRequirementSelections1Options[0].value,
                  },
                  {
                    label: mortgageRequirementSelections1Options[1].label,
                    value: mortgageRequirementSelections1Options[1].value,
                  },
                ]}
                handleSelect={this.handleMortgageType}
                className="product-search__mortgage-type-select"
              />
              {!ctx.state.mortgageDetails.isProductTransfer && (
                <>
                <ButtonSelect
                  label={mortgageRequirementSelections2.description}
                  options={[
                    {
                      label: mortgageRequirementSelections2Options[0].label,
                      value: mortgageRequirementSelections2Options[0].value,
                    },
                    {
                      label: mortgageRequirementSelections2Options[1].label,
                      value: mortgageRequirementSelections2Options[1].value,
                    },
                  ]}
                  handleSelect={this.handleRateType}
                  className="product-search__rate-type-select"
                />
              {this.props.mortgageDetails.termMonths && (
                <RangeInput
                  label={mortgageRequirementSelections3.description}
                  caption={mortgageRequirementSelections3.caption}
                  onChange={this.handleMortgageTerm}
                  default={this.state.term}
                  min={this.props.minTerm}
                  max={this.props.maxTerm}
                />
              )}
              </>
              )}
              {this.state.showFixedTermSlider && (
                <RangeInput
                  className="product-search__fixed-term-slider"
                  label={mortgageRequirementSelections4.description}
                  range={mortgageRequirementSelections4.range}
                  caption={mortgageRequirementSelections4.caption}
                  onChange={this.handleFixedTerm}
                  default={[
                    this.state.fixedTermRange[0],
                    this.state.fixedTermRange[
                      this.state.fixedTermRange.length - 1
                    ],
                  ]}
                  min={this.state.fixedTermRange[0]}
                  max={
                    this.state.fixedTermRange[
                      this.state.fixedTermRange.length - 1
                    ]
                  }
                  marks={this.state.fixedTermRange}
                />
              )}
              {this.state.allProducts.length ? (
                <Note
                  liborRate={this.state.liborRate}
                  arrName={this.state.arrName}
                  arrLongName={this.state.arrLongName}
                  arrValue={this.state.arrValue.toFixed(
                    Math.max(
                      ((this.state.arrValue + "").split(".")[1] || "").length,
                      2
                    )
                  )}
                  color="#294952"
                />
              ) : null}
            </Col>
            <Col
              xs="12"
              md="6"
              className="p-0 pl-md-4 product-search__results-container"
            >
              <Row className="mb-3">
                <Col>
                  <h5>
                    <span className="product-search__matching-products">
                      {this.state.results.length}&nbsp;
                    </span>
                    {step4ResultsMessages.resultsMessage}
                  </h5>
                </Col>
              </Row>
              {this.state.loading ? (
                <div className="mt-4" style={{ height: "200px" }}>
                  <p
                    style={{
                      width: "300px",
                      position: "relative",
                      textAlign: "left",
                    }}
                  >
                    {step4ResultsMessages.loadingMessage}
                  </p>
                  <Loader className="col-12" />
                </div>
              ) : (
                <ProductResults results={this.state.results} />
              )}
            </Col>
          </Row>
        )}
      </ProductSwitchConsumer>
    );
  }
}

export default ProductSearch;
