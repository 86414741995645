import React from 'react'
import './InformationIcon.css'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class InformationIcon extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            modal: false
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const isInverted = this.props.inverted === true;
        return (
            <>
                <div onClick={this.toggle} className={`${this.props.className} information-icon ${isInverted ? 'inverted' : ''}`}>
                    i
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>{this.props.header}</ModalHeader>
                    <ModalBody>
                        {this.props.body}
                    </ModalBody>
                    <ModalFooter>
                        {this.props.footer}
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default InformationIcon;