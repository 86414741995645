import React from "react";
import { Row, Col } from "reactstrap";
import "./CurrentMortgage.css";
import StaticInformationField from "../../atoms/StaticInformationField/StaticInformationField";
import InformationIcon from "../../atoms/InformationIcon/InformationIcon";
import {
  fullFormatCurrency,
  dataExists,
  rateConversion
} from "../../../services/utils";
import {
  JsonSchema,
  currentMortgageModal
} from "../../../data/stepData";

class CurrentMortgage extends React.Component {
  render() {
    const props = this.props.mortgageDetails || this.props;
    const {
      remaining_balance,
      grossLoan_amount,
      end_date,
      rate,
      current_repayment,
      automated_valuation,
      actualLTV,
      isProductTransfer
    } = props;
    const currentMortgage = JsonSchema.step5.sections[0].productCompare.currentMortgage
    const staticInformationFields = JsonSchema.step5.sections[0].productCompare.currentMortgage.staticInformationField

    return (
      <Row className="current-mortgage">
        <Col xs={12} className="current-mortgage__inner">
          <h5>{currentMortgage.header}</h5>
          <InformationIcon
            className="current-mortgage__information"
            {...currentMortgageModal(end_date, isProductTransfer)}
          />
          <StaticInformationField
            label={staticInformationFields[0].label}
            value={dataExists(remaining_balance, fullFormatCurrency)}
            shouldApplyDataNotFound={true}
          />
          <StaticInformationField
            label={staticInformationFields[1].label}
            value={dataExists(grossLoan_amount, fullFormatCurrency)}
            shouldApplyDataNotFound={true}
          />
          <StaticInformationField
            label={staticInformationFields[2].label}
            value={dataExists(end_date)}
            shouldApplyDataNotFound={true}
          />
          <StaticInformationField
            label={staticInformationFields[3].label}
            value={dataExists(rate, rateConversion)}
            shouldApplyDataNotFound={true}
          />
          <StaticInformationField
            label={staticInformationFields[4].label}
            value={dataExists(current_repayment, fullFormatCurrency)}
            shouldApplyDataNotFound={true}
          />
          {!isProductTransfer &&
            <StaticInformationField
              label={staticInformationFields[5].label}
              value={dataExists(actualLTV) + "%"}
            />
          }
        </Col>
        {!isProductTransfer &&
          <Col xs={12} className="current-mortgage__valuation">
            <InformationIcon
              className="current-mortgage__information"
              {...currentMortgage.currentMortgageValuationModal}
            />
            <StaticInformationField
              label={staticInformationFields[6].label}
              value={dataExists(automated_valuation, fullFormatCurrency)}
              type={staticInformationFields[6].type}
              shouldApplyDataNotFound={true}
              reflected
            />
          </Col>
        }
      </Row>
    );
  }
}

export default CurrentMortgage;
