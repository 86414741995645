import React from "react";
import PropTypes from "prop-types";
import Button from "../../atoms/Button/Button";
import { NavLink } from "react-router-dom";
import BackButton from "../../atoms/BackButton/BackButton";
import "./NavFooter.css";

/**
 * Returns the add bar component to the pages
 * @props {backTo} back page
 * @props {forwardTo} NextPage should be
 * @props {continueOnClick} Event to be raised when Continue is clicked
 * @props {backOnClick} Event to be raised when Continue is clicked
 * @props {label} What should be the text of the add bar component
 * @props {isEnabled} whether the Continue button to be disabled
 * @returns {AddNavBar}
 */
const NavFooter = ({
  backTo = "",
  forwardTo = "",
  continueOnClick,
  backOnClick,
  label,
  isEnabled
}) => {
  const handleClick = e => {
    if (!isEnabled) e.preventDefault();
  };

  return (
    <div className="navFooter" data-test="component-navbar">
      <div className="nav-align">
        <NavLink
          to={forwardTo}
          onClick={handleClick}
          className={`${isEnabled ? "no-decor " : "no-decor disabled-link"}`}
        >
          <Button
            className="continueButton contbtn"
            handleClick={continueOnClick}
            type="primary"
            next
            data-test="continue-button"
            label={label}
            isEnabled={isEnabled}
          />
        </NavLink>
      </div>
      <div className="nav-align">
        <BackButton
          to={backTo}
          className="back-button-nav"
          handleClick={backOnClick}
          type="primary"
          data-test="back-button"
        />
      </div>
    </div>
  );
};

NavFooter.propTypes = {
  label: PropTypes.string,
  caption: PropTypes.string,
  backTo: PropTypes.string,
  continueOnClick: PropTypes.func,
  backOnClick:PropTypes.func,
  isEnabled: PropTypes.bool
};

export default NavFooter;
