import React from 'react'
import './MessageInformationField.css'
import '../InformationIcon/InformationIcon.css'

function MessageInformationField({ messageClass, color, value = '' }) {
  const inlineStyle = {   
    color: color
  }
  return (
    <div className={`${messageClass}`} data-test='message-information' style={inlineStyle}>{value}</div>
  )
}

export default MessageInformationField
