import React from "react";
import { Container } from "reactstrap";
import "./Header.css";
import Logo from "../../atoms/Logo/Logo";
import authentication from "@kdpw/msal-b2c-react";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var dropdownList = document.getElementById("dropDownList");
    var dropdownArrow = document.getElementById("arrowIcon");
    document.getElementById("dropdown").onclick = function(e) {
      if (dropdownList.classList.contains("showDropDown")) {
        dropdownclose();
      } else {
        dropdownOpen();
      }
    };

    function dropdownclose() {
      dropdownList.classList.remove("showDropDown");
      dropdownList.classList.add("hideDropDownHide");
      dropdownArrow.classList.remove("rotate");
    }

    function dropdownOpen() {
      dropdownList.classList.remove("hideDropDownHide");
      dropdownList.classList.add("showDropDown");
      dropdownArrow.classList.add("rotate");
    }

    var dropdownCont = document.getElementById("dropdown");
    dropdownCont.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    var bodycont = document.getElementById("body");
    bodycont.addEventListener("click", (e) => {
      dropdownclose();
    });
  }

  gotoHomePage() {
    window.location.href = "/";
  }

  logouts() {
    authentication.signOut();
  }

  render() {
    return (
      <div className="header">
        <Container className="header__content" id="dropdownMenu">
          <Logo></Logo>
          <button
            id="dropdown"
            className="header__sign-out-button"
            aria-hidden="true"
          >
            <span className="sigoutLabel">Sign out</span>

            <i
              id="arrowIcon"
              className="fa fa-chevron-down arrow"
              aria-hidden="true"
            ></i>
          </button>
          <div>
            <ul id="dropDownList" className="header_drop-down">
              <li onClick={this.gotoHomePage}>Home</li>
              <li onClick={this.logouts}>Sign out</li>
            </ul>
          </div>
        </Container>
      </div>
    );
  }
}

export default Header;
