import React from 'react'

export const JsonSchema = {
	step1: {
		sections: [{
            headerDescription: "Account details",
            currentStepNumberForBroker: "Step 1 of 8",
            currentStepNumberForCustomer: "Step 1 of 7",
            accountInformation: 
            {
              header: "Applicant details",
              staticInformationField:
              [
                { 
                  label: "Account number"
                },
                { 
                  label: "Account name"
                },
                { 
                  label: "Other parties"
                },
                { 
                  label: "Mortgaged property address"
                }
              ]
            },
            personalDetails:
            {
              header: "Contact details",
              staticInformationField:
              [
                { 
                  label: "Telephone"
                },
                { 
                  label: "Email"
                },
                { 
                  label: "Correspondence address"
                }
              ]    
            },
            brokerDetails:
            {
              header: "Broker details",
              subHeading: "Broker",
              staticInformationField:
              [
                { 
                  label: "Broker"
                },
                { 
                  label: "Broker contact"
                },
                { 
                  label: "Telephone"
                },
                { 
                  label: "Email"
                }
              ]  
            }
		}]
    },
    step2: {
        sections: [
          {
            headerDescriptionProductSwitch: "How much would you like to borrow?",
            headerDescriptionProductTransfer: "Current loan details",
            currentStepNumberForBroker: "Step 2 of 8",
            currentStepNumberForCustomer: "Step 2 of 7",
            questions: [{
              description:"We’ve estimated the value of your property using an Automated Valuation Model. Would you prefer a visit from a surveyor? There is a charge for this service.", 
              answers: [{
                label: "No, use this valuation",
                value: true
              },
              {
              label: "Yes, I'd like a surveyor",
              value: false
            }],
            },
            {
              description:"How much do you want to borrow?", 
              answers: [{
                label: "",
                value: true
              },
              {
              label: "A different amount",
              value: false
            }]
            },
            {
              description: "What is your expected property value?",
              type: "currency",
              required: true,
              thresholdBreachedText: "This value breaches our LTV threshold. Please enter a different amount.",
              ltvTimeline: "Please be advised that the value you have entered takes your LTV over our maximum threshold. You will be able to select a product however your application will be referred to one of our internal teams who will review your case by exception. We may require some additional information from you.",
              validation_error: "Please enter a valid amount",
              answers: [{
                label: "",
                value: true
              },
              {
              label: "A different amount",
              value: false,
              loanAmountValuationText: "Please be aware altering the amount you wish to borrow, will require further review and may take us to longer to process."
            }]
            },
            {
              description : "Please enter your desired loan amount.",
              type: "currency",
              required: true,
              validation_error: "Please enter a valid amount",
              thresholdBreachedText : "This value breaches our LTV threshold. Please enter a different amount.",              
            },
            {
              description : "You've requested funds in excess of the current outstanding balance. What will they be used for?",
              options : [{
                label : "Debt Consolidation",
                value : "debt_consolidation"
              },
              {
              label : "I've made improvements to the property",
              value : "property_improvements"
            },
            {
              label : "A deposit for another property",
              value : "deposit"
            },
            {
              label : "It's for another purpose",
              value : "another_purpose"
            }],
            loanReasonDetails :{
              description : "Please provide a little more detail", 
              type : "textarea",
              required : true,
              validation_error : "Please provide some additional details",
              
            }  
            },
            {
              description : "Automated valuation"
            } 
          ]
		}]
    },
    step3 : {
      sections : [{
              headerDescription : "Has anything changed?",
              currentStepNumberForBroker : "Step 3 of 8",
              currentStepNumberForCustomer : "Step 3 of 7",
              questions : [{
                description :"Have any material alterations been made to the property since the mortgage was taken out?",
                inline: true, 
                options : 
                [{
                  label : "Yes",
                  value : true,
                  subDescription:"What kind of changes have been made to the property?",
                  subOptions: 
                  [{
                    label:"Internal redecoration, new bathroom and/or kitchen",
                    value: "internal_decoration"
                  },
                  {
                    label: "Change of use",
                    value: "changeOfUse"
                  },
                  {
                    label: "Extensions, conversions and/or additions",
                    value: "extension"
                  },
                  {
                    label: "Landscaping",
                    value: "landscaping"
                  }],
                  fieldInput: {
                    label: "Please provide more information about the changes that have been made",
                    type: "textarea",
                    placeholder: "Additional Details",
                    required: true,
                    validation_error: "Please provide some additional details"
                  }
                },
                {
                label : "No",
                value : false
                }],
              },
              {
                description: "Do you have any other information you wish to add to this application?",
                inline: true,
                options: 
                [{
                  label: "Yes",
                  value: true
                },
                {
                  label: "No",
                  value: false
                }],
                fieldInput: {
                  type: "textarea",
                  placeholder: "Further information",
                  required: true,
                  validation_error: "Please provide some additional details"
                }
              },
              {
                description: "Do you know of any claims, notices or disputes in existence in relation to the property?",
                inline: true,
                options: 
                [{
                  label: "Yes",
                  value: true
                },
                {
                  label: "No",
                  value: false
                }],
                fieldInput: {
                  type: "textarea",
                  placeholder: "Please provide some further details",
                  required: true,
                  validation_error: "Please provide some additional details"
                }
              },
              {
                description: "Please provide further information"
              },
              {
                description: "Does the applicant own 4 or more mortgaged BTL properties?",
                inline: true,
                options: 
                [{
                  label: "Yes",
                  value: true
                },
                {
                  label: "No",
                  value: false
                }],
                portfolioScheduleNote: "Please note a portfolio schedule and 3 months business bank statements will need to be provided.",
                portfolioScheduleNoteSF: "Please note a portfolio schedule and 3 months business bank statements may need to be provided."
              },
              {
                description: "Please confirm customer's Annual Income",
                fieldInput: {
                  type: "currency",
                  placeholder: "Annual income details",
                  required: true,
                  validation_error: "Customer's Annual Income is required"
                }
              },
            ]
      }]
      
    },
    step4 : {
      sections : [{
              headerDescription : "Product selection",
              currentStepNumberForBroker : "Step 4 of 8",
              currentStepNumberForCustomer : "Step 4 of 7",
              header: "Filter",
              mortgageRequirementSelections: 
              [
                {
                  description: "Mortgage type",
                  options: 
                  [
                    {
                      label: "Capital repayment", 
                      value: "capital_repayment"
                    },
                    {
                      label: "Interest only", 
                      value: "interest"
                    }
                  ]
                },
                {
                  description: "Rate type",
                  options: 
                  [
                    {
                      label: "Fixed", 
                      value: "fixed"
                    },
                    {
                      label: "Variable", 
                      value: "variable"
                    }
                  ]
                },
                {
                  description: "Mortgage term",
                  caption:"Years",
                  range:
                  {
                    minimumTerm: 3,
                  } 
                },
                {
                  description: "Desired fixed term",
                  caption:"Years",
                  range:true                 
                }
              ],
              results:
              {
                messages:
                [
                  {
                    resultsMessage: "Matching products found"
                  },
                  {
                    loadingMessage: "Finding matching products..."
                  }
              ]
              },
              productResults:
              {
                message: "No Products"
              }
      }]
    },
    step5 : {
      sections : [{
              headerDescription : "Review your selection",
              currentStepNumberForBroker : "Step 5 of 8",
              currentStepNumberForCustomer : "Step 5 of 7",
              productCompare: {
                currentMortgage: {
                  header: "Current mortgage",
                  staticInformationField: 
                  [
                    {
                      label: "Remaining balance"
                    },
                    {
                      label: "Gross loan amount"
                    },
                    {
                      label: "Product end date"
                    },
                    {
                      label: "Rate"
                    },
                    {
                      label: "Current monthly payment"
                    },
                    {
                      label: "LTV"
                    },
                    {
                      label: "Automated valuation",
                      type:"secondary"
                    }
                  ],
                  currentMortgageValuationModal: 
                  {
                    header: "Terms explained",
                    body: <><h6>Automated valuation</h6><p>The Automated Valuation Model (AVM) calculates a property's value at a specific date by analysing values of comparable properties.</p></>
                  }
                },
                newMortgage: {
                  header: "New mortgage",
                  staticInformationField: 
                  [
                    {
                      label: "Remaining balance"
                    },
                    {
                      label: "Product end date"
                    },
                    {
                      label: "Rate"
                    },
                    {
                      label: "New monthly payment"
                    },
                    {
                      label: "Future LTV"
                    },
                    {
                      type:"secondary"
                    },
                    {
                      label:"Gross loan amount"
                    }
                  ]
                },
                header: "Important product information"               
              }                  
        }]
    },
    step6 : {
      sections : [{
              headerDescription: "Review application",
              currentStepNumberForBroker: "Step 6 of 8",
              currentStepNumberForCustomer: "Step 6 of 7",
              step1Review:{
                description: "Account details",
                questions: 
                [ 
                  "Account number","Account name","Other parties","Other parties","Mortgaged property address","Telephone",
                  "Email","Correspondence address"
                ]
              },
              step2Review:{
                description: "How much would you like to borrow?",
                questions: 
                [ 
                  "We've estimated the value of your property using an Automated Valuation Model. Would you like a visit from a surveyor? There is a charge for this service.",
                  "What is your expected property value?",
                  "Automated valuation",
                  "How much do you want to borrow?",
                  "Please enter your desired loan amount",
                  "You've requested funds in excess of the current outstanding balance. What will they be used for?",
                  "Please provide a little more detail"
                ]
              },
              step3Review:{
                description: "Has anything changed?",
                questions: 
                [ 
                  "Have any material alterations been made to the property since the mortgage was taken out?",
                  "What kind of changes have been made to the property?",
                  "Please provide more information about the changes that have been made",
                  "Do you have any other information you wish to add to this application?",
                  "Please provide further information",
                  "Do you know of any claims, notices or disputes in existence in relation to the property?",
                  "Please provide further information"
                ]
              },
              step4Review:{
                description: "Product selection",
                questions: 
                [ 
                  "Mortgage type",
                  "Rate type",
                  "Term",
                  "Rate",
                  "Rate",
                  "Monthly payment",
                  "Monthly payment",
                  "Product end date",
                  "Future LTV"
                ]
              }                                                                      
        }]
    }
}

export function currentMortgageModal(date, isProductTransfer) {
  if(isProductTransfer)
  {
    return {
      header: "Terms explained",
      body:
      <>
          <h6>Remaining balance</h6>
          <p>This is the amount of the current mortgage balance as at the last payment date.</p>

          <h6>Gross loan amount</h6>
          <p>This is the total amount of the settlement figure of the existing mortgage plus the arrangement fee.</p>
          
          <h6>Product end date</h6>
          <p>The last day of the current mortgage agreement.</p>

          <h6>Rate</h6>
          <p>This is the current total interest rate being charged on the current mortgage as at today's date.</p>

          <h6>Monthly repayment</h6>
          <p>The current amount required to pay off the mortgage each month.</p>
      </>
  };
  }
  return {
      header: "Terms explained",
      body:
      <>
          <h6>Remaining balance</h6>
          <p>This is the amount of the current mortgage balance as at the last payment date.</p>

          <h6>Gross loan amount</h6>
          <p>This is the total amount of the settlement figure of the existing mortgage plus the arrangement fee.</p>
          
          <h6>Product end date</h6>
          <p>The last day of the current mortgage agreement.</p>

          <h6>Rate</h6>
          <p>This is the current total interest rate being charged on the current mortgage as at today's date.</p>

          <h6>Monthly repayment</h6>
          <p>The current amount required to pay off the mortgage each month.</p>

          <h6>LTV</h6>
          <p>LTV is the current loan to value displayed as a percentage. LTV is the loan amount divided by the property value (multiplied by one hundred).</p>
      </>
  };
}