import React from "react";
import NavFooter from "../components/Organisms/NavFooter/NavFooter";

/**
 * Returns the add bar controller with given sets of props
 * @param {toRefer} Is this referral journey
 * @param {To} NextPage should be
 * @param {isReady} isPageStateReady
 * @returns {AddNavBar}
 */
export const renderControllerBar = (
  backTo,
  To,
  isReady,
  label,
  isEnabled,
  backOnClick,
  continueOnClick
) => {
  return (
    isReady && (
      <NavFooter
        backTo={backTo}
        forwardTo={To}
        continueOnClick={continueOnClick}
        backOnClick={backOnClick}
        label={label ? label : "Continue"}
        isEnabled={isEnabled}
      ></NavFooter>
    )
  );
};
