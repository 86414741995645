import React from "react";
import { Route, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import authentication from "../../../services/auth";
import Step6Print from "../../../pages/Step6Print/Step6Print";
import DashBoardPage from "../../../pages/DashBoardPage/DashBoardPage";
import Welcome from "../../../pages/Welcome/Welcome";
import Step1 from "../../../pages/Step1/Step1";
import Step2 from "../../../pages/Step2/Step2";
import Step3 from "../../../pages/Step3/Step3";
import Step4 from "../../../pages/Step4/Step4";
import Step5 from "../../../pages/Step5/Step5";
import Step6 from "../../../pages/Step6/Step6";
import Step7 from "../../../pages/Step7/Step7";
import Step8 from "../../../pages/Step8/Step8";
import Confirmation from "../../../pages/Confirmation/Confirmation";
import Referral from "../../../pages/Referral/Referral";
import Button from "../../atoms/Button/Button";
import ProductSwitchProvider, {
  ProductSwitchConsumer,
} from "./ProductSwitchContext";
import Loader from "../../atoms/Loader/Loader";
import { printUrl } from "../../../data/data";

// Allows axios requests in IE.
require("es6-promise").polyfill();
require("es6-object-assign").polyfill();

class ProductSwitch extends React.Component {
  constructor(props) {
    super(props);
    // 19 minutes = 1140000 ms
    // 1 minute = 60000 ms
    this.state = {
      inactivityTimeout: 1140000,
      warningTimeout: 60000,
      modal: false
    };

    this.toggle = this.toggle.bind(this);

    this.inactivityTimer = this.startInactivityTimer();
    this.warningTimer = null;
  }

  startInactivityTimer() {
    return setTimeout(
      () => this.inactiveWarning(),
      this.state.inactivityTimeout
    );
  }

  startWarningTimer() {
    return setTimeout(
      () => authentication.signOut(),
      this.state.warningTimeout
    );
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  inactiveWarning() {
    this.toggle();
    this.warningTimer = this.startWarningTimer();
  }

  componentDidUpdate(prevProps) {
    clearTimeout(this.inactivityTimer);
    if (!this.state.modal) {
      clearTimeout(this.warningTimer);
      this.inactivityTimer = this.startInactivityTimer();
    }
  }

  render() {
    const isPrintView =
    this.props.location.pathname.indexOf(printUrl) === -1;
    return (
      <ProductSwitchProvider>
        <div className="product-switch">
          <ProductSwitchConsumer>
            {(ctx) => {
              if (this.props.location.pathname === "/") {
                return (
                  <Route path="/" exact render={() => <DashBoardPage />} />
                );
              } else if (ctx.isReferred() && isPrintView) {
                return <Route component={Referral} />;
              } else if (ctx.isProductSelected() && isPrintView) {
                return <Route component={Confirmation} />;
              } else {
                return (
                  <>
                    {!ctx.state.isDataReady ? (
                      <>
                        <Row className="mt-4">
                          <Col className="d-flex justify-content-center mt-4">
                            <Row>
                              <h3>Retrieving your details</h3>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-center">
                            <Loader />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Route
                          path="/"
                          exact
                          render={() => <DashBoardPage />}
                        />
                        <Route path="/Welcome" exact render={Welcome} />
                        <Route
                          path="/step-1"
                          render={() => (
                            <Step1
                              {...ctx}
                              key={ctx.state.accountNumber}
                              backTo="/Welcome"
                              continueTo="/step-2"
                            />
                          )}
                        />
                        <Route
                          path="/step-2"
                          render={() => (
                            <Step2
                              {...ctx}
                              key={ctx.state.accountNumber}
                              backTo="/step-1"
                              continueTo="/step-3"
                            />
                          )}
                        />
                        <Route
                          path="/step-3"
                          render={() => (
                            <Step3
                              {...ctx}
                              key={ctx.state.accountNumber}
                              backTo="/step-2"
                              continueTo="/step-4"
                            />
                          )}
                        />
                        <Route
                          path="/step-4"
                          render={() => (
                            <Step4
                              {...ctx}
                              key={ctx.state.accountNumber}
                              backTo="/step-3"
                              continueTo="/step-5"
                            />
                          )}
                        />
                        <Route
                          path="/step-5"
                          render={() => (
                            <Step5
                              {...ctx}
                              key={ctx.state.accountNumber}
                              backTo="/step-4"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/step-6/print"
                          render={() => <Step6Print />}
                        />
                        <Route
                          exact
                          path="/step-6"
                          render={() => (
                            <Step6
                              {...ctx}
                              key={ctx.state.accountNumber}
                              backTo="step-5"
                            />
                          )}
                        />
                        <Route
                          path="/step-7"
                          render={() => <Step7 {...ctx} backTo="step-6" />}
                        />
                        <Route
                          path="/declaration"
                          render={() => <Step7 {...ctx} backTo="step-6" />}
                        />
                        <Route
                          path="/step-8"
                          render={() => <Step8 {...ctx} backTo="step-7" />}
                        />
                        <Route path="/confirmation" component={Confirmation} />
                        <Route path="/referral" component={Referral} />
                      </>
                    )}
                  </>
                );
              }
            }}
          </ProductSwitchConsumer>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Inactivity Warning</ModalHeader>
          <ModalBody>You will be logged out in 1 minute</ModalBody>
          <ModalFooter>
            <Button handleClick={() => this.toggle()} label="Stay logged in." />
          </ModalFooter>
        </Modal>
      </ProductSwitchProvider>
    );
  }
}

export default withRouter(ProductSwitch);
