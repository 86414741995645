import React from 'react'
import { Row, Col } from 'reactstrap'
import StaticInformationField from '../../atoms/StaticInformationField/StaticInformationField'
import { fullFormatCurrency, rateConversion, monthsToYear } from '../../../services/utils';
import { dateFormatString } from '../../../data/data'
import './NewMortgage.css'
import { JsonSchema } from '../../../data/stepData'

var moment = require("moment");

function NewMortgage(props) {
    const { remaining_balance = "Data not Found", allInRate, fixedRate, fixed, termInMonths, fixedTermInMonths = 0, monthlyRepayment, future_ltv, arrangementFeePerc } = props.selectedProduct;
    const {
        isProductTransfer
    } = props.mortgage;
    const endDate = moment().add(termInMonths, 'M');
    var formattedEndDate = moment(endDate).format(dateFormatString);
    let grossLoanAmount = remaining_balance * (1 + (arrangementFeePerc / 100));

    const endDateFixed = moment().add(fixedTermInMonths, 'M');
    const formattedEndDateFixed = moment(endDateFixed).format(dateFormatString);

    const rate = fixed ? rateConversion(fixedRate) : rateConversion(allInRate);
    const newMortgage = JsonSchema.step5.sections[0].productCompare.newMortgage;
    const staticInformationField = JsonSchema.step5.sections[0].productCompare.newMortgage.staticInformationField;

    return (
        <Row className="new-mortgage">
            <Col xs={12} className="new-mortgage__inner">
                <h5>{newMortgage.header}</h5>
                <StaticInformationField inverted className="new-mortgage__remaining-balance" label={staticInformationField[0].label} value={fullFormatCurrency(remaining_balance)} />
                <StaticInformationField inverted className="new-mortgage__remaining-balance" label={staticInformationField[6].label} value={fullFormatCurrency(grossLoanAmount)} />
                <StaticInformationField inverted className="new-mortgage__end-date" label={staticInformationField[1].label} value={formattedEndDate} />
                <StaticInformationField inverted className="new-mortgage__rate" label={staticInformationField[2].label} value={rate} />
                <StaticInformationField inverted className="new-mortgage__new-repayment" label={staticInformationField[3].label} value={fullFormatCurrency(monthlyRepayment)} />
                {!isProductTransfer &&
                    <StaticInformationField inverted className="new-mortgage__future_ltv" label={staticInformationField[4].label} value={future_ltv} />
                }
            </Col>
            <Col xs={12} className="new-mortgage__bottom">
                <StaticInformationField className="new-mortgage__1st" label={fixed ? `Fixed until ${formattedEndDateFixed}` : <br></br>} value={`${monthsToYear(termInMonths)} year ${fixed ? 'term' : 'variable'}`} type={staticInformationField[5].type} reflected />
            </Col>
        </Row>
    )
}

export default NewMortgage;