import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/atoms/Button/Button";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import Timeline from "../../components/molecules/Timeline/Timeline";
import { WithInfoContactUsCard } from "../../components/molecules/ContactUsCard/ContactUsCard";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import MessageInformationField from "../../components/atoms/MessageInformationField/MessageInformationField";
import { downloadPdf } from "../../services/utils";
import "./Confirmation.css";
import { getGeneratedPdf } from "../../services/api";
import {
  openingHours,
  confirmationTimeline,
  pdfError,
  completeApplicationName,
  eSignatureconfirmationTimeline
} from "../../data/data";
import { RegisteredAddress } from "../../services/ContactInfo";

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitForResponse: false,
      caseId: sessionStorage.getItem("caseId"),
    };
  }

  handlePdfClick() {
    this.setState({
      loader: true,
      isError: false,
    });

    getGeneratedPdf(this.state.caseId).then((res) => {
      if (res.data.size === 0) {
        this.setState({
          loader: false,
          isError: true,
        });
      } else {
        this.setState({
          pdf: res.data,
          loader: false,
          isError: false,
        });
        downloadPdf(res, completeApplicationName);
      }
    });
  }

  render() {
    const { isError, loader } = this.state;
    return (
      <>
        <ProductSwitchConsumer>
          {(ctx) => (
            <Container fluid className="confirmation">
              <ScrollToPoint></ScrollToPoint>
              <PageIntroduction
                small_header="What happens next?"
                main_header="You're almost there..."
              />
              <ProgressBar now={100} />

              <Row className="mt-4">
                <Col xs="12" md="6" className="mt-md-0 mt-4 pr-md-4 pr-lg-5">
                  <Row className="confirmation__review">
                    <Col className="confirmation__review-inner">
                      <>
                        <Row>
                            <div  className="confirmation__offer__squareBox">
                                <FontAwesomeIcon className="confirmation__offer__check" icon={faCheck} />
                            </div>
                          <Col className="col-approve">
                          <h4 className="mortgage__approve__h4">Mortgage offer</h4>
                          <h1 className="mortgage__approve__h5">approved!</h1>
                          </Col>
                        </Row>
                      </>
                      {ctx.state.eSignatureFlag ? 
                      (
                        <>
                        { ((ctx.state.scottishCaseFlag) || (!ctx.state.scottishCase)) ? (
                          <p className="confirmation__offer-success">
                              You will receive an email shortly with the
                              mortgage offer enclosed that can be 
                              signed electronically.
                      </p>
                        ):
                        (
                        <p className="confirmation__offer-success">
                              We're pleased to be able to issue the mortgage
                              offer, which you should receive via email within
                              the next two working days.
                      </p>
                      )}
                      </>
                      ):(
                        <p className="confirmation__offer-success">
                        We're pleased to be able to issue the mortgage
                        offer, which you should receive via email within
                        the next two working days.
                </p>
                      )}                      
                    </Col>
                  </Row>

                  <br/>

                  {ctx.state.pdfFeatureFlag && (
                    <Row className="pdf__review">
                      <Col className="pdf__review-inner">
                        <h5>Your application</h5>
                        <p className="mt-4">
                          Thank you for submitting your application.
                        </p>

                        {!isError ? (
                          <ProductSwitchConsumer>
                            {(ctx) => (
                              <>
                                {!loader ? (
                                  <Button
                                    handleClick={() =>
                                      this.handlePdfClick(ctx, "pdf")
                                    }
                                    className="confirmation__pdf-button"
                                    label="Download application (PDF)"
                                    type={loader ? "inactive" : "primary"}
                                    download
                                    next
                                  />
                                ) : (
                                  <>
                                    <Button
                                      handleClick={() =>
                                        this.handlePdfClick(ctx, "pdf")
                                      }
                                      className="confirmation__pdf-button"
                                      label="Generating PDF..."
                                      type={loader ? "inactive" : "primary"}
                                      next
                                    />
                                    <img
                                      className="small-loader-confirmation"
                                      src="./Spinner.svg"
                                      alt="load"
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </ProductSwitchConsumer>
                        ) : (
                          <ProductSwitchConsumer>
                            {(ctx) => (
                              <>
                                <Button
                                  handleClick={() =>
                                    this.handlePdfClick(ctx, "pdf")
                                  }
                                  className="confirmation__pdf-button"
                                  label="Download application (PDF)"
                                  type={loader ? "inactive" : "primary"}
                                  download
                                  next
                                />
                                <MessageInformationField
                                  messageClass="pdf__validation-error"
                                  color="red"
                                  value={pdfError}
                                />
                              </>
                            )}
                          </ProductSwitchConsumer>
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row className="mt-4">
                    <Col className="p-0">
                      <WithInfoContactUsCard
                        opening_hours={openingHours}
                      ></WithInfoContactUsCard>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="6"
                  className="confirmation__contact-us mt-4 mt-md-0"
                >
                {ctx.state.eSignatureFlag ? 
                ( 
                  <>
                  { ((ctx.state.scottishCaseFlag) || (!ctx.state.scottishCase)) ? 
                  (<Timeline
                  {...eSignatureconfirmationTimeline(
                    <RegisteredAddress></RegisteredAddress>
                  )}
                ></Timeline>)
                :
                ( <Timeline
                  {...confirmationTimeline(
                    <RegisteredAddress></RegisteredAddress>
                )}
                ></Timeline>)}
                </>):(<Timeline
                  {...confirmationTimeline(
                    <RegisteredAddress></RegisteredAddress>
                )}
                ></Timeline>)}
                </Col>
              </Row>
            </Container>
          )}
        </ProductSwitchConsumer>
      </>
    );
  }
}

export default Confirmation;
