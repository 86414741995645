import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import Button from "../../components/atoms/Button/Button";
import Timeline from "../../components/molecules/Timeline/Timeline";

import {
  welcomeTimelineContent,
  eSignaturewelcomeTimelineContent,
} from "../../data/data";
import { welcomeData, welcomeDataForBroker } from "./welcomeData";

function Welcome(props) {
  return (
    <Container fluid className="welcome">
      <ProductSwitchConsumer>
        {(ctx) => (
          <>
            <ScrollToPoint />
            <PageIntroduction
              small_header="My Shawbrook"
              main_header="Welcome"
              full_name={ctx.state.mortgageDetails.accountName}
            />
            <Row>
              <Col xs="12" md="6" className="mb-3 pr-md-4 pr-lg-5 pl-0">
                <p>
                  {ctx.state.mortgageDetails.isProductTransfer
                    ? welcomeData[0].textProductTransfer[0]
                    : welcomeData[0].textProductSwitch[0]}
                </p>
                <h3 style={{ fontWeight: "bold" }}>{welcomeData[1].header}</h3>
                <p>{welcomeData[1].text[0]}</p>
                <p>{welcomeData[1].text[1]}</p>
                {ctx.state.isBroker ? (
                  <>
                    <h3
                      style={{ fontWeight: "bold" }}
                      data-test="no-advice-for-broker"
                    >
                      {welcomeDataForBroker[0].header}
                    </h3>
                    <p>{welcomeDataForBroker[0].text}</p>
                  </>
                ) : (
                  <>
                    <h3 style={{ fontWeight: "bold" }}>
                      {welcomeData[2].header}
                    </h3>
                    <p>{welcomeData[2].text}</p>
                  </>
                )}
              </Col>
              <Col xs="12" md="6">
                {ctx.state.eSignatureFlag ? (
                  <>
                    {ctx.state.scottishCaseFlag || !ctx.state.scottishCase ? (
                      <Timeline
                        {...eSignaturewelcomeTimelineContent}
                      ></Timeline>
                    ) : (
                      <Timeline {...welcomeTimelineContent}></Timeline>
                    )}
                  </>
                ) : (
                  <Timeline {...welcomeTimelineContent}></Timeline>
                )}
                <Row className="justify-content-end">
                  <Col xs="12" md="9" className="pr-0">
                    <NavLink to="/step-1">
                      <Button
                        className="mt-4"
                        label="Let's get started"
                        next
                        type="primary"
                      />
                    </NavLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </ProductSwitchConsumer>
    </Container>
  );
}

export default Welcome;
