import React from 'react'
import './CheckboxSelect.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'reactstrap'

class CheckboxSelect extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: this.props.selected || [],
            inline: this.props.inline || false
        }
    }

    setActive = value => {
        var selected_arr = this.state.selected;
        if (!this.state.selected.includes(value)) {
            selected_arr.push(value)
            this.setState({ selected: selected_arr })
            if (this.props.handleSelect) {
                this.handleSelect(selected_arr);
            }
        } else {
            var value_index = selected_arr.indexOf(value)
            selected_arr.splice(value_index, 1)

            this.setState({ selected: selected_arr })
            if (this.props.handleSelect) {
                this.handleSelect(selected_arr);
            }
        }
    }

    handleSelect = value => {
        this.props.handleSelect(value);
    }

    render() {
        return (
            <div className={`${this.props.className ? this.props.className : ''} field checkbox-selects`}>
                <div className="field-text">
                    {this.props.label}
                </div>
                {
                    this.props.options.map((option) => (
                        <Row key={option.value} className="checkbox-select__option">
                            <span className="checkbox-select__label col-10">{option.label}</span>
                            <Col xs="2">
                                <span
                                    onClick={() => this.setActive(option.value)}
                                    value={option.value}
                                    className={`${this.state.selected.includes(option.value) ? "selected" : ""} checkbox-select__input-box`}>
                                    <p className="checkbox-select__check-icon"><FontAwesomeIcon icon={faCheck} /></p>
                                </span>
                            </Col>
                        </Row>
                    ))
                }
            </div>
        )
    }
}

export default CheckboxSelect;