import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { WithInfoContactUsCard } from '../../components/molecules/ContactUsCard/ContactUsCard'
import { openingHours,emptyDashboardPageErrorTitle,emptyDashboardPageMessage } from '../../data/data'
import './EmptyDashboard.css'

class EmptyDashboard extends React.Component {
    render() {
        return (
            <Container className="error">
            <Row>
                <Col xs="12" md="6" className="pr-0 pr-md-4 pr-lg-5 pl-0 text">
                    <h3 className="mb-4">{emptyDashboardPageErrorTitle}</h3>
                    <p>{emptyDashboardPageMessage}</p> 
                </Col>
                <Col xs="12" md="6" className="px-0 mt-4 mt-md-0">
                    <WithInfoContactUsCard
                        opening_hours={openingHours}
                    />
                </Col>
            </Row>
        </Container>
        );
    }
}

export default EmptyDashboard;