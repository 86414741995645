export const welcomeData = [
    {
        textProductSwitch: [
            "For existing mortgages, you can now browse our range of products and complete product switch applications online."
        ],
        textProductTransfer: [
            "For existing mortgages, you can now browse our range of products and complete Switch & Fix applications online."
        ]
    },
    {
        header: "Who is this service for?",
        text: [
            "This process assumes that the borrowing needs of the applicant haven't changed and that there are no major changes to the property or circumstances.",
            "If things have changed, or the applicant wants to increase the borrowing amount, this service can still be used to inform us of the requirements, which will then be reviewed by one of our experienced lending managers. Depending on the needs, we may ask for further information to be provided.",
        ]
    },
    {
        header: "'No advice' service",
        text: "This service is provided on a non-advised basis. This means Shawbrook Bank isn't recommending a particular product to you. If you're not sure what is the right product for you, or if you need advice on the options available to repay your current mortgage, please seek advice from an independent financial advisor."
    }
]

export const welcomeDataForBroker = [   
    {
        header: "'No advice' service",
        text: "This is an execution-only service provided by Shawbrook Bank. This means that Shawbrook Bank is not providing any advice or making a recommendation."
    }
]
