import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import authentication from './services/auth';
import { printUrl, thankYou } from './data/data';
import {extractQueryParams} from '../src/services/utils'
import ThankYou from './pages/ThankYouPage/ThankYou';


if(window.location.pathname === printUrl){
  extractQueryParams();
  ReactDOM.render(<App />, document.getElementById('root'));
}
else if(window.location.pathname === thankYou){
  ReactDOM.render(<ThankYou />, document.getElementById('root'));
}
else
{
  authentication.run(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
}
