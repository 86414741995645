import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import "./Button.css";
import PropTypes from "prop-types";

import { Row } from "reactstrap";

function Button({
  label,
  type = "primary",
  className,
  handleClick,
  next,
  isEnabled = true,
  download
}) {

  
  const labelClass =
    typeof isEnabled !== "undefined" && isEnabled
      ? `${type} button__main`
      : `${type} button__main button__disabled`;

  return (
    <Row
      onClick={handleClick}
      className={`${className} ${type} ${
        next ? "next" : "download"
      } button justify-content-end mx-0`}
    >
      <div className={labelClass}>{label}</div>
      {next && (
        <div
          className={`${
            isEnabled ? "button__next" : "button__next button__next-disabled"
          }`}
        >
          <p
            className={`${
              isEnabled ? "button__next-icon" : "button__next-icon-disabled"
            }`}
          >
            <FontAwesomeIcon icon={download?faFileDownload :faChevronRight} />
          </p>
        </div>
      )}
    </Row>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  isEnabled: PropTypes.bool
};

export default Button;
