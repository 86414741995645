import React from "react";
import { Col, Row } from "reactstrap";
import ProductCard from "../ProductCard/ProductCard";
import { JsonSchema } from "../../../data/stepData";
import "./ProductResults.css";

class ProductResults extends React.Component {
  render() {
    return (
      <Row>
        <Col xs="12">
          {(this.props.results &&
            this.props.results.length &&
            this.props.results.map((result, idx) => (
              <ProductCard key={idx} product={result}></ProductCard>
            ))) || <p>{JsonSchema.step4.sections[0].productResults.message}</p>}
        </Col>
      </Row>
    );
  }
}

export default ProductResults;
