import React from "react";
import { Row, Col } from "reactstrap";
import "./Footer.css";
import { footerContent, footerLinks } from "../../../data/data";
import { withContactInfo } from "../../../services/ContactInfo";

class Footer extends React.Component {
  renderLinks() {
    return footerLinks(this.props.phoneNumber, this.props.email).map(
      (link, index) => (
        <Col key={index} className="text-center m-0 p-0 footer__option">
          {link.href ? (
            <a
              style={{ color: "white" }}
              rel="noopener noreferrer"
              target="_blank"
              href={link.href}
            >
              {link.label}
            </a>
          ) : (
            <p className="mb-1" style={{ color: "white" }}>
              {link.label}
            </p>
          )}
        </Col>
      )
    );
  }

  render() {
    return (
      <div className="footer p-3">
        <div className="footer__content">
          <Row className="text-center mr-0 p-2">
            <Col>{footerContent}</Col>
          </Row>
          <Row className="mt-2 d-xs-flex flex-column">{this.renderLinks()}</Row>
        </div>
      </div>
    );
  }
}
export default withContactInfo(Footer);
