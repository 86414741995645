import React from 'react'
import { Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import './Timeline.css'

/**
 * Need to define a standard data structure that can be sent as props for timelines
 * Inputs: map(Row, header, body, checked? ) MainHeader
 * props = {
 *      header: String,
 *      rows: [
 *          {
 *              header: String,
 *              body: String,
 *              checked: Boolean
 *          }
 *      ]
 * }
 */

const Timeline = props => (
    <>
        <Row className="timeline__outer">
            <div className="timeline__inner">
                <Row>
                    <h5>{props.header}</h5>
                </Row>
                {props.rows.map( (row, index) => 
                    <Row key={index}>
                        <Col className={`${index === 0 ? "mt-4" : ''} pl-0 col-2`}>
                            {row.checked ? 
                                <div className="timeline__tickbox text-center">
                                    <p className="timeline__check-icon"><FontAwesomeIcon icon={faCheck} /></p>
                                </div>
                                : 
                                    <div className="timeline__tickbox--unchecked text-center"></div>
                            }
                            {index !== (props.rows.length - 1) ?
                                <div className="timeline__bar"></div> 
                                : null
                            }
                        </Col>
                        <Col className={index === 0 ? "mt-4" : ''}>
                            <h5>{row.header}</h5>
                            <div className="mt-2">{row.body}</div>
                        </Col>
                    </Row>
                )}
            </div>
        </Row>
    </>
)

export default Timeline;