import React from 'react'
import './RadioButtons.css'
import {booleanOrNull} from '../../../services/utils'
import {Row, Col} from 'reactstrap'

class RadioButtons extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: booleanOrNull(props.selected),
            inline: props.inline || false
        }
    }

    setActive = value => {
        if (this.state.selected !== value) {
            this.setState({ selected: value })
            if (this.props.handleSelect) {
                this.handleSelect(value);
            }
        }
    }

    handleSelect = value => {
        this.props.handleSelect(value);
    }

    render() {
        return (
            <Row className="mt-4">
                <Col xs="12" md="8" lg="9" className="field-text">
                    {this.props.label}
                </Col>
                <Col className="radio-buttons inline">
                    <Row>
                        {this.props.options.map((option) => (
                            <Col key={option.value}>
                                <div onClick={() => this.setActive(option.value)} key={option.value} className={`${this.state.selected === option.value ? "selected" : "" } radio-button__option`}>
                                    <span className="radio-button__label">{option.label}</span>
                                    <span value={option.value} className={`${this.state.selected === option.value ? "selected" : ""} radio-button__input-box`}></span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default RadioButtons;