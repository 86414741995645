import React from 'react'
import './FieldInput.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'

class FieldInput extends React.Component {

  // types: ["text", "number", "textarea"]

  constructor(props) {
    super(props)
 
    this.state = {
      value: this.defaultValueFormat(props.value) || '',
      interacted: false,
      error: false,
      completed: false,
      validation_error: props.validation_error
    }
  }

  defaultValueFormat(x) {
    if (this.props.type === "currency" && typeof x === "string") {
      return this.formatNumber(x);
    } else {
      return x;
    }
  }

  // This is a function that changes a number into a correctly formatted currency string with commas in the right places
  formatNumber(x) {
    //regex to match single non zero digit after the decimal point
    var singleNonZeroDecimalPoint = /^[0-9]+(\.[1-9])$/; 
    var valid = singleNonZeroDecimalPoint.test(x);
    if(valid && typeof x.length !== "undefined"){
      x = x.padEnd(x.length + 1,'0');
    }
    //regex to match single zero after the decimal point
    var singleZeroAfterDecimal =  /^[0-9]+(\.[0])$/;
    var singleZeroAfterDecimalIsTrue = singleZeroAfterDecimal.test(x);
    let formattedValue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(formattedValue !== ""){
      if(!formattedValue.includes(".")){
        formattedValue = formattedValue + ".00"
      }
      if(singleZeroAfterDecimalIsTrue){
        formattedValue = formattedValue.replace(".0",".00")
      }
      formattedValue = '£'+formattedValue;
    }
    return formattedValue;
  }

  validateEmail(value) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  }

  handleInput = ev => {    
    var error = false;
    var complete = true;
    var value = ev.target.value;
    //If the input is a currency type then it only allows numbers.
    // eslint-disable-next-line 
    var currency_symbols = /^\xA3?\d{1,}(?:\,?\d+)*(?:\.\d{1,2})?$/; 
    var valid = currency_symbols.test(ev.target.value); 
    if (this.props.type === "currency" && !valid) {
      ev.target.value = ""
      error = true;
      complete = false;
    }
    //removing the £ from the starting.
    let targetValue = (ev.target.value).replace(/\u00A3/g, ''); 
    let inputValue = (targetValue).replace(/,/g, "");
    ev.target.value = inputValue;
    
    this.setState({ value: ev.target.value, interacted: true })

    if (this.props.handleInput) {
      this.props.handleInput(ev.target.value)
      ev.target.blur()
    }

    if (this.props.type === "currency") { 
      ev.target.value = this.formatNumber(ev.target.value.replace(/,/g, ''))
    }

    if (this.props.type === "email") {
      if (!this.validateEmail(value)) {
        error = true;
        complete = false;
      }
    }

    if (ev.target.value.length < 1) {
      error = true;
      complete = false;
    }

    this.setState({
      error: error,
      complete: complete
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.target.blur();
    }
  }

  handleFocus = ev => {
    if (this.props.type === "currency") {
      ev.target.value = ev.target.value.replace(/,/g, '');
    }
  }

  render() {
    return (
      <div className={`${this.props.className ? this.props.className : ''} field-mr`}>
        <div className="field-text-mr">{this.props.label}</div>
        <div className={`${this.props.className} field-input`}>
          {this.props.type !== "textarea" ?
            <input
              onKeyPress={this.handleKeyPress}
              onFocus={this.handleFocus}
              onBlur={this.handleInput}
              type={this.props.type}
              defaultValue={this.state.value}
              placeholder={String.fromCharCode(163) + this.props.placeholder}
              className={`${this.state.complete && (typeof this.props.hasError === 'undefined' || !this.props.hasError) ? "success" : null} ${this.state.error || (typeof this.props.hasError === 'undefined' || this.props.hasError) ? "error" : null}`}
            />
            : <textarea rows="4" cols="50"
              onKeyPress={this.handleKeyPress}
              onFocus={this.handleFocus}
              onBlur={this.handleInput}
              type={this.props.type}
              defaultValue={this.state.value}
              placeholder={this.props.placeholder}
              className={`${this.state.complete ? "success" : null} ${this.state.error ? "error" : null}`} />
          }
          <span className="field-input__validation-error-box field-input__validation-box"><FontAwesomeIcon icon={faExclamationCircle} /></span>
          <span className="field-input__validation-success-box field-input__validation-box"><FontAwesomeIcon icon={faCheck} /></span>
          {(this.props.required && this.state.error && this.state.interacted) ?
            <span className="field-input__validation-error">{this.props.validation_error}</span>
            : null
          }
        </div>
      </div>
    )
  }
}
export default FieldInput;
