export const referralData = {
    reviewText: [
        {
            type: 'h5',
            value: 'Your application has been referred to a Lending Manager.',
        },
        {
            type: 'p',
            value: 'As supplementary information has been provided, we need to review the application. Additional documentation may need to be provided to support the application.'
        
        },
        {
            type: 'p',
            value: 'One of our Lending Managers will be in contact with you via telephone to discuss your case.'
        },
        {
            type: 'p',
            value:'This will include:'
        },
        {
            type:'p',
            value:'A portfolio schedule',
        },
        {
            type:'p',
            value:'3 months business bank statements',
        }
    ]
}
