import React from "react";
import { Row, Col, Label } from "reactstrap";
import {
  dataExists,
  fullFormatCurrency,
  monthsToYear,
  rateConversion
} from "../../../services/utils";
import { dateFormatString } from "../../../data/data";
import "./Step4Review.css";
import moment from "moment";
import { JsonSchema } from "../../../data/stepData"

const Step4Review = props => {
  const { selectedProduct } = props.data;
  const { isProductTransfer } = props.data.mortgageDetails;
  const step4Description = JsonSchema.step4.sections[0].headerDescription
  const step4Question1 = JsonSchema.step4.sections[0].mortgageRequirementSelections[0].description
  const step4Question2 = JsonSchema.step4.sections[0].mortgageRequirementSelections[1].description
  const step4Question3 = JsonSchema.step4.sections[0].mortgageRequirementSelections[2].description
  const step4Question4 = JsonSchema.step5.sections[0].productCompare.newMortgage.staticInformationField[2].label
  const step4Question5 = JsonSchema.step5.sections[0].productCompare.newMortgage.staticInformationField[3].label
  const step4Question7 = JsonSchema.step5.sections[0].productCompare.currentMortgage.staticInformationField[2].label
  const step4Question8 = JsonSchema.step5.sections[0].productCompare.newMortgage.staticInformationField[4].label
  return (
    <>
      <Label className="step4Label labelspacing">
        {step4Description}
      </Label>
      <Row className="step4label-row">
        <Col className="question">{step4Question1}</Col>
        <Col className="answer">
          {selectedProduct.interestOnly ? "Interest Only" : "Capital repayment"}
        </Col>
      </Row>

      <Row className="step4label-row">
        <Col className="question">{step4Question2}</Col>
        <Col className="answer">
          {selectedProduct.fixed
            ? monthsToYear(selectedProduct.fixedTermInMonths) + " year fixed"
            : "Variable"}
        </Col>
      </Row>

      <Row className="step4label-row">
        <Col className="question">{step4Question3}</Col>
        <Col className="answer">
          {dataExists(monthsToYear(selectedProduct.termInMonths)) + " years"}
        </Col>
      </Row>

      {selectedProduct.fixed ? (
        <Row className="step4label-row">
          <Col className="question">{step4Question4}</Col>
          <Col className="answer">
            {dataExists(rateConversion(selectedProduct.fixedRate))}
          </Col>
        </Row>
      ) : (
        <Row className="step4label-row">
          <Col className="question">{step4Question4}</Col>
          <Col className="answer">
            {dataExists(rateConversion(selectedProduct.allInRate))}
          </Col>
        </Row>
      )}

      {selectedProduct.fixed ? (
        <Row className="step4label-row">
          <Col className="question">{step4Question5}</Col>
          <Col className="answer">
            {dataExists(fullFormatCurrency(selectedProduct.monthlyRepayment))}
          </Col>
        </Row>
      ) : (
        <Row className="step4label-row">
          <Col className="question">{step4Question5}</Col>
          <Col className="answer">
            {dataExists(fullFormatCurrency(selectedProduct.monthlyRepayment))}
          </Col>
        </Row>
      )}


      <Row className="step4label-row">
        <Col className="question">{step4Question7}</Col>
        <Col className="answer">
          {dataExists(moment(moment().add(selectedProduct.termInMonths, 'M')).format(dateFormatString)
          )}
        </Col>
      </Row>

      {!isProductTransfer &&
        <Row className="step4label-row">
          <Col className="question">{step4Question8} </Col>
          <Col className="answer">
            {selectedProduct.future_ltv}
          </Col>
        </Row>
      }
    </>
  );
};
export default Step4Review;
