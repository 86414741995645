import React from "react";
import { Row, Col } from "reactstrap";
import RadioButtons from "../../components/atoms/RadioButtons/RadioButtons";
import CheckboxSelect from "../../components/atoms/CheckboxSelect/CheckboxSelect";
import FieldInput from "../../components/atoms/FieldInput/FieldInput";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import CurrentMortgage from "../../components/molecules/CurrentMortgage/CurrentMortgage";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import "./Step3.css";
import { booleanOrNull, shouldRefer } from "../../services/utils";
import { JsonSchema } from "../../data/stepData";
import { formatCurrency } from "../../services/utils";

require("es6-promise").polyfill();
class Step3 extends React.Component {
  constructor(props) {
    super(props);
    const step3_values = props.state.step3;
    const capital_raise = props.state.step3.capitalRaise;
    this.state = {
      propertyAlterations: booleanOrNull(step3_values.propertyAlterations),
      typeOfChange: step3_values.typeOfChange || [],
      propertyChangesDetails: step3_values.propertyChangesDetails || "",
      additionalInformation: booleanOrNull(step3_values.additionalInformation),
      furtherDetails: step3_values.furtherDetails || "",
      claims: booleanOrNull(step3_values.claims),
      claimsInformation: step3_values.claimsInformation || "",
      capitalRaise: booleanOrNull(capital_raise),
      isProductTransfer: props.state.mortgageDetails.isProductTransfer,
      is_annual_income_valid : this.isAnnualIncomeValid(props.state.mortgageDetails.isProductTransfer, step3_values.annualIncome),
      annualIncome: booleanOrNull(step3_values.annualIncome)
    };
  }

  handleAnnualIncome = (value) => {
    this.setState(
      {
        is_annual_income_valid: value >= 0,
        annualIncome: value,
      });
  };

  handleContinue = () => {
    if (this.props.handleContinue) {
      this.props.handleContinue(this.state);
    }
  };

  handlePropertyAlterations = (value) => {
    this.setState({
      propertyAlterations: value,
      typeOfChange: value ? [] : this.state.typeOfChange,
      propertyChangesDetails: value ? this.state.propertyChangesDetails : "",
    });
  };

  handleTypeOfChange = (value) => {
    this.setState({ typeOfChange: value });
  };

  handlePropertyChangesDetails = (value) => {
    this.setState({ propertyChangesDetails: value });
  };

  handleAdditionalInformation = (value) => {
    this.setState({
      additionalInformation: value,
      furtherDetails: value ? this.state.furtherDetails : "",
    });
  };

  handleFurtherDetails = (value) => {
    this.setState({ furtherDetails: value });
  };

  handleClaims = (value) => {
    this.setState({
      claims: value,
      claimsInformation: value ? this.state.claimsInformation : "",
    });
  };

  handleClaimsInformation = (value) => {
    this.setState({ claimsInformation: value });
  };

  handlePortfolioSchedule = (value) => {
    this.setState({
      capitalRaise: value,
    });
  };

  componentDidMount() {
    if (this.props.state.step2.isDataChanged) {
      this.props.state.selectedProduct = {};
      this.props.state.step2.isDataChanged = false;
    }
  }

  isAnnualIncomeValid(isProductTransfer, annualIncome)
  {
    if(isProductTransfer 
    && (annualIncome === undefined || annualIncome === "" ||  Number(annualIncome) < 0)){
      return false;
    }
    return true;
  }

  isContinueAvailable(ctx) {
    const {
      propertyAlterations,
      typeOfChange,
      additionalInformation,
      furtherDetails,
      claims,
      claimsInformation,
      propertyChangesDetails,
      capitalRaise,
      annualIncome
    } = this.state;

    var available = true;

    if (propertyAlterations || propertyAlterations === null) {
      if (!typeOfChange.length) {
        available = false;
      }
      if (!propertyChangesDetails.length) {
        available = false;
      }
    }

    if (additionalInformation || additionalInformation === null) {
      if (!furtherDetails.length) {
        available = false;
      }
    }

    if (claims || claims === null) {
      if (!claimsInformation.length) {
        available = false;
      }
    }

    if (!this.isAnnualIncomeValid(this.state.isProductTransfer, annualIncome)) {
        available = false;
    }

    if (capitalRaise === null) {
      available = false;
    }

    if (available) {
      return true;
    }
    return false;
  }

  shouldRefer() {
    const state = this.props.state;
    state.step3 = {
      ...state.step3,
      ...this.state,
    };
    return shouldRefer(state);
  }

  render() {
    const label = "Continue";
    const step3 = JsonSchema.step3.sections[0];
    return (
      <div className="container-fluid step3">
        <ProductSwitchConsumer>
          {(ctx) => (
            <>
              <ScrollToPoint></ScrollToPoint>
              {ctx.state.isBroker ? (
                <>
                  <PageIntroduction
                    small_header={step3.currentStepNumberForBroker}
                    main_header={step3.headerDescription}
                  ></PageIntroduction>
                  <ProgressBar now={33} />
                </>
              ) : (
                <>
                  <PageIntroduction
                    small_header={step3.currentStepNumberForCustomer}
                    main_header={step3.headerDescription}
                  ></PageIntroduction>
                  <ProgressBar now={37.5} />
                </>
              )}

              <Row className="mt-4">
                <Col xs="12" md="6" className="pr-md-4 pr-lg-5">
                  <CurrentMortgage
                    mortgageDetails={ctx.state.mortgageDetails}
                  />
                </Col>
                {this.renderRightPanel(ctx)}
              </Row>
              {renderControllerBar(
                this.props.backTo,
                this.props.continueTo,
                true,
                label,
                this.isContinueAvailable(ctx),
                () => ctx.saveBackProgress("step3"),
                () => ctx.update("step3", this.state)
              )}
            </>
          )}
        </ProductSwitchConsumer>
      </div>
    );
  }

  renderRightPanel(ctx) {
    const question1 = JsonSchema.step3.sections[0].questions[0];
    const question1Options = JsonSchema.step3.sections[0].questions[0].options;
    const question1SubOptions =
      JsonSchema.step3.sections[0].questions[0].options[0].subOptions;
    const question2 = JsonSchema.step3.sections[0].questions[1];
    const question2Options = JsonSchema.step3.sections[0].questions[1].options;
    const question3 = JsonSchema.step3.sections[0].questions[2];
    const question3Options = JsonSchema.step3.sections[0].questions[2].options;
    const question4 = JsonSchema.step3.sections[0].questions[4];
    const question4Options = JsonSchema.step3.sections[0].questions[4].options;
    const question1_1 = JsonSchema.step3.sections[0].questions[5];
    const portfolioScheduleNote =
      JsonSchema.step3.sections[0].questions[4].portfolioScheduleNote;
    const portfolioScheduleNoteSF =
      JsonSchema.step3.sections[0].questions[4].portfolioScheduleNoteSF;

    return (
      this.props.state.isDataReady && (
        <Col
          xs="12"
          md="6"
          className="mt-0 pl-0 pr-0 pl-md-3 step3__property-alteration-questions page-navbar-bottom box-height"
        >
          { this.state.isProductTransfer &&  (
            <FieldInput
              label={question1_1.description}
              handleInput={this.handleAnnualIncome}
              type={question1_1.fieldInput.type}
              prefix={String.fromCharCode(163)}
              placeholder={
                this.state.annualIncome
                  ? formatCurrency(
                    this.state.annualIncome
                    )
                  : ""
              }
              value={this.state.annualIncome}
              required={true}
              hasError={!this.state.is_annual_income_valid}
              validation_error={question1_1.fieldInput.validation_error}
            />
          )}
          <RadioButtons
            label={question1.description}
            className="step3__has-property-altered"
            inline={question1.inline}
            handleSelect={this.handlePropertyAlterations}
            selected={this.state.propertyAlterations}
            options={[
              {
                label: question1Options[0].label,
                value: question1Options[0].value,
              },
              {
                label: question1Options[1].label,
                value: question1Options[1].value,
              },
            ]}
          />
          {this.state.propertyAlterations && (
            <CheckboxSelect
              label={question1Options[0].subDescription}
              className="step3__types-of-change"
              handleSelect={this.handleTypeOfChange}
              options={[
                {
                  label: question1SubOptions[0].label,
                  value: question1SubOptions[0].value,
                },
                {
                  label: question1SubOptions[1].label,
                  value: question1SubOptions[1].value,
                },
                {
                  label: question1SubOptions[2].label,
                  value: question1SubOptions[2].value,
                },
                {
                  label: question1SubOptions[3].label,
                  value: question1SubOptions[3].value,
                },
              ]}
              selected={this.state.typeOfChange}
            />
          )}
          {this.state.propertyAlterations &&
            this.state.typeOfChange.length >= 1 && (
              <FieldInput
                label={question1Options[0].fieldInput.label}
                className="step3__property-changes-details"
                type={question1Options[0].fieldInput.type}
                required={question1Options[0].fieldInput.required}
                handleInput={this.handlePropertyChangesDetails}
                value={this.state.propertyChangesDetails}
                validation_error={
                  question1Options[0].fieldInput.validation_error
                }
              />
            )}
          {((!this.state.propertyAlterations &&
            this.state.propertyAlterations !== null) ||
            (this.state.typeOfChange.length >= 1 || this.state.additionalInformation !== null)) && (
              <>
                <RadioButtons
                  label={question2.description}
                  className="step3__additional-details"
                  inline={question2.inline}
                  options={[
                    {
                      label: question2Options[0].label,
                      value: question2Options[0].value,
                    },
                    {
                      label: question2Options[1].label,
                      value: question2Options[1].value,
                    },
                  ]}
                  selected={this.state.additionalInformation}
                  handleSelect={this.handleAdditionalInformation}
                />

                {this.state.additionalInformation && (
                  <FieldInput
                    className="step3__additional-details-input"
                    type={question2.fieldInput.type}
                    handleInput={this.handleFurtherDetails}
                    required={question2.fieldInput.required}
                    value={this.state.furtherDetails}
                    validation_error={question2.fieldInput.validation_error}
                  />
                )}
                {this.state.additionalInformation !== null && (
                  <>
                    <RadioButtons
                      label={question3.description}
                      className="step3__claims"
                      inline={question3.inline}
                      options={[
                        {
                          label: question3Options[0].label,
                          value: question3Options[0].value,
                        },
                        {
                          label: question3Options[1].label,
                          value: question3Options[1].value,
                        },
                      ]}
                      selected={this.state.claims}
                      handleSelect={this.handleClaims}
                    />

                    {this.state.claims && (
                      <FieldInput
                        type={question3.fieldInput.type}
                        handleInput={this.handleClaimsInformation}
                        required={question3.fieldInput.required}
                        value={this.state.claimsInformation}
                        validation_error={question3.fieldInput.validation_error}
                      />
                    )}
                  </>
                )}

                <RadioButtons
                  label={question4.description}
                  className="step3__capitalRaise"
                  inline={question4.inline}
                  options={[
                    {
                      label: question4Options[0].label,
                      value: question4Options[0].value,
                    },
                    {
                      label: question4Options[1].label,
                      value: question4Options[1].value,
                    },
                  ]}
                  selected={this.state.capitalRaise}
                  handleSelect={this.handlePortfolioSchedule}
                />
                {this.state.capitalRaise && (
                  
                  <p
                    className="note-text"
                    data-test="portfolioSchedule-note"
                  >
                    {this.state.isProductTransfer ? portfolioScheduleNoteSF : portfolioScheduleNote}
                  </p>
                )}
              </>
            )}

        </Col>
      )
    );
  }
}

export default Step3;
