import React from 'react'
import { offerValidDate } from '../services/utils'
import { PhoneNumber } from '../services/ContactInfo'

export const openingHours = [{label: "Mon - Fri", value: "9:00am - 5:30pm"}];
export const dataNotFoundMessage = "Data not found.";

export const caseCompleteStatusId = 41;

export const currentMortgageValuationModal = {
    header: "Terms explained",
    body: <><h6>Automated valuation</h6><p>The Automated Valuation Model (AVM) calculates a property's value at a specific date by analysing values of comparable properties.</p></>
}

export const yourAccountModal = {
    header: "Terms explained",
    body: <>
        <h6>Other parties</h6>
        <p>The applicant and any other parties listed on the mortgage (including guarantors).</p>
        <h6>Guarantors</h6>
        <p>A guarantor is usually an owner or director of a limited company. Shawbrook may require these individuals to meet some or all of the company's obligations should it fail to meet them.</p>
        <h6>Mortgaged property address</h6>
        <p>The actual address of the property put as the security when applying for a secured mortgage.</p>
    </>
}

export const yourDetailsModal = {
    header: "Does everything look right?",
    body: <>
        <p>These are the current contact details Shawbrook have on file against this specific mortgage. Please note, you may have alternative contact details for other mortgages you hold with us. If any of these details are incorrect, please phone us on <PhoneNumber />.</p>
    </>
}

export const confirmTermsAndConditionsText = ["These are the most common charges that may have to be paid in relation to the administration of the new mortgage."]
export const footerContent = "© 2019 Shawbrook Bank Limited. All rights reserved. Shawbrook Bank is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority (Financial Services Register number: 204574)."

export function footerLinks(phoneNumber, email) {
    return [
        {label: "Cookie Policy", href: "https://shawbrook.co.uk/cookie-policy/"},
        {label: "Customer Security", href: "https://shawbrook.co.uk/customer-security/"},
        {label: "Phone us: " + phoneNumber},
        {label: "Email us: " + email}
    ]
}

export function staticfooterLinks(phoneNumber, email) {
    return [
        {label: "Cookie Policy", href: "https://shawbrook.co.uk/cookie-policy/"},
        {label: "Customer Security", href: "https://shawbrook.co.uk/customer-security/"},
        {label: "Phone us: ", value:  "0345 604 0989"},
        {label: "Email us: ",value: "productswitch@shawbrook.co.uk"}
    ]
}

export const minimumTerm = 3;
export const maximumTerm = 30;
export const minFloorRate = 0.75;

export const welcomeTimelineContent = {
    header: "What happens next?",
    rows: [
        {
            header: "Register for My Shawbrook",
            body: <p>You have successfully created an account with My Shawbrook, allowing you to sign in at any time.</p>,
            checked: true
        },
        {
            header: "Tell us about the applicant and property",
            body: <p>Provide more information about the applicant's borrowing needs and any changes that have been made since the current mortgage was taken out. We will use this information to find eligible products.</p>,
            checked: false
        },
        {
            header: "Receive the mortgage offer",
            body: <>
                <p>Once a suitable product has been selected and the terms have been agreed to,
                <strong> the application will be accepted. </strong>
                The mortgage offer will be sent to you by email.
                </p>
            </>,
            checked: false
        },
        {
            header: "Sign and return paperwork",
            body: <p>The applicant and any other parties listed on the mortgage (including guarantors) will need to sign and return the documentation for the new mortgage to take effect.</p>,
            checked: false
        }
    ]
}

export const eSignaturewelcomeTimelineContent = {
    header: "What happens next?",
    rows: [
        {
            header: "Register for My Shawbrook",
            body: <p>You have successfully created an account with My Shawbrook, allowing you to sign in at any time.</p>,
            checked: true
        },
        {
            header: "Tell us about the applicant and property",
            body: <p>Provide more information about the applicant's borrowing needs and any changes that have been made since the current mortgage was taken out. We will use this information to find eligible products.</p>,
            checked: false
        },
        {
            header: "Receive the mortgage offer",
            body: <>
                <p>Once a suitable product has been selected and the terms have been agreed to,
                <strong> the application will be accepted. </strong>
                The mortgage offer will be sent to you by email.
                </p>
            </>,
            checked: false
        },
        {
            header: "Sign the documentation",
            body: <p>The applicant and any other parties listed on the mortgage (including guarantors) will need to electronically sign the documentation for the new mortgage to take effect.</p>,
            checked: false
        }
    ]
}

export function confirmationTimeline(registeredAddress) {
    return {
        header: "What happens next?",
        rows: [
            {
                header: "Register for My Shawbrook",
                body: <p>You have successfully created an account with My Shawbrook, allowing you to sign in at any time.</p>,
                checked: true
            },
            {
                header: "Tell us about the applicant and property",
                body: <p>Provide more information about the applicant's borrowing needs and any changes that have been made since the current mortgage was taken out. We will use this information to find eligible products.</p>,
                checked: true
            },
            {
                header: "Receive the mortgage offer",
                body: <p>Congratulations! You should receive the mortgage documentation within the next two working days.</p>,
                checked: true
            },
            {
                header: "Sign and return paperwork",
                body: <>
                    <p>Please note, if we do not receive the signed documentation before {offerValidDate()}, there may be a delay in the mortgage taking effect.</p>
                    <p>The applicant and any other parties listed on the mortgage (including guarantors) will need to sign and return the documentation.</p>
                    <p>Please return a signed copy by post to {registeredAddress}.
                    </p>
                </>,
                checked: false
            }
        ]
    }
}

export function eSignatureconfirmationTimeline(registeredAddress) {
    return {
        header: "What happens next?",
        rows: [
            {
                header: "Register for My Shawbrook",
                body: <p>You have successfully created an account with My Shawbrook, allowing you to sign in at any time.</p>,
                checked: true
            },
            {
                header: "Tell us about the applicant and property",
                body: <p>Provide more information about the applicant's borrowing needs and any changes that have been made since the current mortgage was taken out. We will use this information to find eligible products.</p>,
                checked: true
            },
            {
                header: "Receive the mortgage offer",
                body: <p>Congratulations! You should receive the mortgage documentation shortly.</p>,
                checked: true
            },
            {
                header: "Sign the documentation",
                body: <>
                    <p>The applicant and any other parties listed on the mortgage (including guarantors) will need to sign the documentation electronically, which will be sent by email from DocuSign on behalf of Shawbrook Bank.</p>
                    <p>Please note, if the documentation is not signed before  {offerValidDate()}, there may be a delay in the mortgage taking effect.
                    </p>
                </>,
                checked: false
            }
        ]
    }
}

export const localeString = 'en-GB';
export const dateFormatString = "DD MMM YYYY";
export const dateFormatFullMonthName = "DD MMMM YYYY";
export const printDateFormatString = "DD MMMM YYYY, h:mm a";
export const currencyString = 'GBP';
export const currencyDecimalPlaces = 2;
export const ltvThresholdValue = 75;
export const ltvReferralAllowedLowerRange = 75;
export const ltvReferralAllowedUpperRange = 85;

export const thresholdBreachedText = "This value breaches our LTV threshold. Please enter a different amount."
export const loanAmountValuationText = "Please be aware altering the amount you wish to borrow, will require further review and may take us to longer to process.";
export const ltvTimeline = "Please be advised that the value you have entered takes your LTV over our maximum threshold. You will be able to select a product however your application will be referred to one of our internal teams who will review your case by exception. We may require some additional information from you."

export const useTheValuation="No, use this valuation"
export const selectSurveyor="Yes, I'd like a surveyor"
export const differentLoanAmount="A different amount"
export const debtConsolidation="Debt Consolidation"
export const propertyImprovements="I've made improvements to the property"
export const deposit="A deposit for another property"
export const anotherPurpose="It's for another purpose"

export const internalDecoration = "Internal redecoration, new bathroom and/or kitchen"
export const changeOfUse = "Change of use"
export const extension = "Extensions, conversions and/or additions"
export const landscaping = "Landscaping"

export const smallHeader= "My Shawbrook"
export const mainHeader = "Select a mortgage to switch..."
export const myShawbrookStatus = {
    NO_STATUS: null,
    NEW: 1,
    IN_PROGRESS: 2,
    COMPLETED:3,
    EXPIRED:4,
    REQUESTEDACTIVATION:5
}
export const headerTitleNew = "NEW"
export const headerTitleInProgress = "IN PROGRESS"
export const headerTitleExpired = "EXPIRED"
export const headerClassNew = "new-cases"
export const headerClassInProgress = "inprogress-cases"
export const headerClassExpired = "expired-cases"
export const buttonNameSelect = "Select"
export const buttonNameContinue = "Continue"
export const buttonNameExpired = "Reactivate"
export const printUrl = "/step-6/print"
export const thankYou = "/ThankYou"
export const pdfError = "Sorry, your application is currently unavailable for download. Please try again or contact us if you would like to obtain a copy."

export const pdfFeatureFlag = "PdfGenerator"
export const eSignatureFlag = "ESignature"
export const scottishCaseFlag = "ScottishCase"

export const completeApplicationName = "Product Switch application";
export const referApplicationName = "Product Switch Refered application";
export const brokerDetails = "Broker details";
export const emptyDashboardPageMessage = "Please contact us if you have an existing product you would like to switch.";
export const emptyDashboardPageErrorTitle = "Sorry, you have no mortgages to switch.";
export const thankYouMessage = "Thank you for choosing Shawbrook Bank";
export const thankYouMessageNote = "You'll receive a copy of the offer documents once everyone has signed.";
export const modalAlertTitle = "Request sent";
export const modalAlertBody = "Your request has been submitted. One of our lending managers will be in contact with you soon to discuss your case."
