import React from 'react'
import {withRouter} from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap'
import { WithInfoContactUsCard } from '../../components/molecules/ContactUsCard/ContactUsCard'
import { openingHours } from '../../data/data'
import ErrorMessage from '../../components/atoms/ErrorMessage/ErrorMessage'


class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }

        this.redirectToHome = this.redirectToHome.bind(this);
    }

    static getDerivedStateFromError(error) {
        return { hasError: true}
    }

    componentDidCatch(error, info) {
        // We can log our errors to some service here for monitoring.
    }

    redirectToHome() {
        this.setState({hasError: false});
        this.props.history.push('/');
    }

    render() {
        if (this.state.hasError) {
            return(
                <Container className="error">
                    <Row>
                        <Col xs="12" md="6" className="pr-0 pr-md-4 pr-lg-5 pl-0">
                            <ErrorMessage
                                message="Unfortunately the page you requested could not be found.
                                Try using the site navigation to find what you are looking for."
                                buttonLabel="Return to home page"
                                handleClick={this.redirectToHome}
                            />
                        </Col>
                        <Col xs="12" md="6" className="px-0 mt-4 mt-md-0">
                            <WithInfoContactUsCard
                                opening_hours={openingHours}
                            />
                        </Col>
                    </Row>
                </Container>
            )
        }
        return this.props.children
    }
}

export default withRouter(ErrorBoundary);