import React from "react";
import { Container, Row } from "reactstrap";
import ReviewApplication from "../../components/Organisms/ReviewApplication/ReviewApplication";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import "./Step6Print.css";
import Logo from "../../components/atoms/Logo/Logo";
import moment from 'moment';
import { printDateFormatString } from "../../data/data";

class Step6Print extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <ProductSwitchConsumer>
      { ctx =>
         <>
        <div className="header">
        <Container className="header__content">
        <Logo></Logo>
        <div className="date">
        <span className="submitted">Submitted: </span>
        <span className="submitDate">{moment(ctx.state.submissionDate).format(printDateFormatString)}</span>
        </div>
        </Container>
        </div>
        <Container className="page-content">
        <Container fluid className="step6/print">
          <PageIntroduction
            main_header="Review application"
          />
          <Row>
            <div className="page-navbar-bottom">
               <ReviewApplication context={ctx.state} />

            </div>
          </Row>
        </Container>
        </Container>
        </>
        }
        </ProductSwitchConsumer>
    );
  }
}

export default Step6Print;
