import React from "react";
import { Row, Col } from "reactstrap";
import CurrentMortgage from "../CurrentMortgage/CurrentMortgage";
import NewMortgage from "../NewMortgage/NewMortgage";
import TermAndConditionsDownload from "../../atoms/TermsAndConditionsDownload/TermsAndConditionsDownload";
import { confirmTermsAndConditionsText } from "../../../data/data";
import {
  fullFormatCurrency,
  shouldRefer,
  rateConversion,
  removeCommas
} from "../../../services/utils";

import "./ProductCompare.css";

function ProductCompare(props) {
  const { end_date = props.mortgage && props.mortgage.end_date } =
    props.selectedProduct || {};
  const { isProductTransfer } = props.mortgage;
  const referState = { step2: props.step2, step3: props.step3 };
  const isReferralJournery = shouldRefer(referState);
  if (props.selectedProduct) {
    // For MVP we are only dealing with £for£ switches
    props.selectedProduct.remaining_balance = !props.step2.accept_value
      ? parseInt(removeCommas(props.desired_loan))
      : props.mortgage.remaining_balance;
    props.selectedProduct.future_ltv = rateConversion(
      isReferralJournery && props.step2.future_ltv !== undefined && props.step2.future_ltv.toFixed(2) > 0
        ? props.step2.future_ltv
        : props.mortgage.actualLTV
    );
  }
  return (
    <section className="product-compare">
      <Row>
        <Col
          xs="12"
          md="6"
          className="product-compare__current-mortgage pr-md-4 pr-lg-5"
        >
          <CurrentMortgage {...props.mortgage} />
        </Col>

        <Col className="product-compare__new-mortgage mt-4 mt-md-0">
          <NewMortgage {...props} />
        </Col>
      </Row>

      <Row>
        <Col
          xs="12"
          md="6"
          className="product-compare__important-information pr-md-4 pr-lg-5"
        >
          <Row className="product-compare__important-information__inner">
            <h5 className="mb-3">Important product information</h5>
            <p>
              The remaining mortgage balance as at the last payment date is{" "}
              {fullFormatCurrency(props.mortgage.remaining_balance)}. This does
              not include the interest due or any interest charges incurred
              between this date and the start of the new mortgage.
            </p>
            {isProductTransfer &&
              <p>
                The mortgage end date by which the full balance must be repaid is{" "}
                {end_date}. The latest valuation for the security property we have
                is {fullFormatCurrency(props.mortgage.automated_valuation)}.
              </p>
            }
            {!isProductTransfer &&
              <p>
                The mortgage end date by which the full balance must be repaid is{" "}
                {end_date}. The latest valuation for the security property we have
                is {fullFormatCurrency(props.mortgage.automated_valuation)}. Based on
                this valuation and the current mortgage balance, the LTV is{" "}
                {props.mortgage.actualLTV}%.
              </p>
            }
          </Row>
        </Col>
        <Col className="product-compare__important-tc">
          <Row className="product-compare__important-tc__inner">
            <h5 className="mb-3">Tariff of charges</h5>
            <p className="mt-2">{confirmTermsAndConditionsText[0]}</p>

            <Col className="p-0">
              <TermAndConditionsDownload />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default ProductCompare;
