import React from 'react'
import { Row, Col } from "reactstrap";
import './StaticInformationField.css'
import { dataNotFoundMessage } from '../../../data/data'
/*
 * Component receives two required properties: 
 * label : String
 * value : String OR Array[String]
 * AND one optional property:
 * style : String -> "secondary"
*/
function StaticInformationField({ className, value = '', label, type = 'primary', inverted, reflected, shouldApplyDataNotFound = false }) {
    return (((Array.isArray(value) && value.filter(String).length <= 0) ||value === '' || value === dataNotFoundMessage) && !shouldApplyDataNotFound ? <></> :
        <div className={`${className} static-information-field ${type} ${inverted ? 'inverted' : ''}`}>
            {!reflected && <p className="static-information-field__label">{label}</p>}
            {Array.isArray(value)
                ? (value.map((line, idx) => <p key={idx} className="static-information-field__value" >{line}</p>))
                : <p className="static-information-field__value">{value}</p>
            }
            {reflected && <p className="static-information-field__label">{label}</p>}
        </div>
    )
}

export function StaticReviewField({ className, value = '', label, shouldApplyDataNotFound = false }) {
    return ( (value === '' || value === dataNotFoundMessage) && !shouldApplyDataNotFound ? <></> :
        <Row className={"step1label-row " + className}>
            <Col className="question">{label} </Col>
            <Col className="answer">{value}</Col>
        </Row>
    )
}

export default StaticInformationField
