import React from "react";
import { Container } from "reactstrap";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ProgressBar from "react-bootstrap/ProgressBar";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import AccountInformation from "../../components/molecules/AccountInformation/AccountInformation";
import PersonalDetails from "../../components/molecules/PersonalDetails/PersonalDetails";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import "./Step1.css";
import { JsonSchema } from "../../data/stepData";

class Step1 extends React.Component {
  render() {
    const step1 = JsonSchema.step1.sections[0];
    return (
      <div>
        <Container fluid className="step1">
          <ProductSwitchConsumer>
            {(ctx) => (
              <>
                <ScrollToPoint />
                {ctx.state.isBroker ? (
                  <>
                    <PageIntroduction
                      small_header={step1.currentStepNumberForBroker}
                      main_header={step1.headerDescription}
                    />
                    <ProgressBar now={11} />
                  </>
                ) : (
                  <>
                    <PageIntroduction
                      small_header={step1.currentStepNumberForCustomer}
                      main_header={step1.headerDescription}
                    />
                    <ProgressBar now={12.5} />
                  </>
                )}
                <div className="row mt-4 box-height">
                  <div className="col account-information">
                    <AccountInformation />
                  </div>
                  <div className="col-1">&nbsp;</div>
                  <div className="col personal-details">
                    <PersonalDetails />
                  </div>
                </div>
                {renderControllerBar(
                  this.props.backTo,
                  this.props.continueTo,
                  true,
                  "Continue",
                  true,
                  () => ctx.saveBackProgress("step1"),
                  () => ctx.update("step1")
                )}
              </>
            )}
          </ProductSwitchConsumer>
        </Container>
      </div>
    );
  }
}

export default Step1;
