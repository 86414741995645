import React from "react";
import { Container, Row, Col } from "reactstrap";
import { shouldRefer, getCaseDetails } from "../../services/utils";
import CheckboxSelect from "../../components/atoms/CheckboxSelect/CheckboxSelect";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Step7.css";

var jwtdecode = require("jwt-decode");
class Step7 extends React.Component {
  constructor(props) {
    super(props);
    let caseDetails = jwtdecode(getCaseDetails());
    this.state = {
      contact_options: props.contact_options || [],
      isBroker:
        typeof caseDetails.extension_IsBroker === "undefined" ||
        caseDetails.extension_IsBroker === null
          ? false
          : caseDetails.extension_IsBroker,
      isSubmit: !caseDetails.extension_IsBroker,
      selectedProduct: props.state.selectedProduct,
      isProductTransfer: props.state.mortgageDetails.isProductTransfer
    };
  }

  handleContactOptions = (options) => {
    this.setState({ contact_options: options });
  };

  handleBrokerConfirmation = () => {
    this.setState({ isSubmit: !this.state.isSubmit });
  };

  shouldRefer() {
    return shouldRefer(this.props.state);
  }

  render() {
    const { isBroker, isProductTransfer } = this.state;
    const label = "Submit Application";
    const brokerLabel = "Continue";
    return (
      <Container fluid className="step7">
        <ScrollToPoint></ScrollToPoint>
        {isBroker ? (
          <>
            <PageIntroduction
              small_header="Step 7 of 8"
              main_header="Your declaration"
            ></PageIntroduction>
            <ProgressBar now={77} />
          </>
        ) : (
          <>
            <PageIntroduction
              small_header="Step 7 of 7"
              main_header="Your declaration"
            ></PageIntroduction>
            <ProgressBar now={82.5} />
          </>
        )}
        <Row className="mt-4">
          <Col
            md="6"
            xs="12"
            className="px-0 px-md-3 pr-md-4 pr-lg-5 page-navbar-bottom"
          >
            <h3 style={{ fontWeight: "bold" }}>Your information</h3>
            {!isBroker ? (
              <p>
                The way in which we will use your information is set-out in our
                Privacy Notice at{" "}
                <a
                  href="https://www.shawbrook.co.uk/privacy-notice/"
                  title="Privacy Notice"
                  rel="noopener noreferrer external"
                  target="_blank"
                >
                  https://www.shawbrook.co.uk/privacy-notice/
                </a>
                . If you or the applicant(s) would like a paper copy of our
                Privacy Notice you can print a copy from our website or contact
                us at 0345 848 0223. By completing this application form you
                acknowledge that the applicant(s) have been directed to our
                Privacy Notice.
              </p>
            ) : (
              <p>
                The way in which we will use the applicant(s)’ information is
                set-out in our Privacy Notice at{" "}
                <a
                  href="https://www.shawbrook.co.uk/privacy-notice/"
                  title="Privacy Notice"
                  rel="noopener noreferrer external"
                  target="_blank"
                >
                  https://www.shawbrook.co.uk/privacy-notice/
                </a>
                . If you or the applicant(s) would like a paper copy of our
                Privacy Notice you can print a copy from our website or contact
                us at 0345 848 0223. By completing this application form you
                acknowledge that the applicant(s) have been directed to our
                Privacy Notice.
              </p>
            )}

            <h3 style={{ fontWeight: "bold" }}>Non-advised service</h3>
            {!isBroker ? (
              <p>
                Shawbrook is not recommending a particular product for you. We
                are not authorised to provide advice. If you need advice on your
                options, please speak to an independent financial advisor.
              </p>
            ) : (
              <p>
                This is an execution-only service provided by Shawbrook Bank.
                This means that Shawbrook Bank is not providing you with any
                advice or making any recommendation to you.
              </p>
            )}

            {this.shouldRefer() || isProductTransfer ? null : (
              <>
                {isBroker ? null : (
                  <>
                    <h3 style={{ fontWeight: "bold" }}>
                      About this application
                    </h3>
                    <p>
                      By signing this form, you authorise Shawbrook to process a
                      mortgage application from you to switch your existing
                      mortgage to a new Shawbrook mortgage product.
                    </p>
                    <p>
                      We will issue you with a mortgage offer detailing the
                      mortgage product we can offer, based on what you have
                      applied for. Note that your existing mortgage will be
                      closed and a new loan account opened.
                    </p>
                    <p>
                      You are under no obligation to accept any mortgage offer
                      provided. All account holders (including any guarantors)
                      will need to sign your mortgage documents and return them
                      to us.
                    </p>
                  </>
                )}
              </>
            )}

            {isBroker ? (
              <>
                <h3 style={{ fontWeight: "bold" }}>Fees payable</h3>
                <p>
                  This service is subject to an arrangement fee of{" "}
                  {this.state.selectedProduct.arrangementFeePerc}% of the gross
                  new loan.
                </p>
                <p>
                  Please see the separate ‘Tariff of charges’ document for the
                  most common charges that may have to be paid in relation to
                  the administration of the new mortgage.
                </p>
              </>
            ) : null}
          </Col>

          <Col
            md="6"
            xs="12"
            className="px-0 pl-md-3 step7__review page-navbar-bottom"
          >
            {(this.shouldRefer() || isProductTransfer) && !isBroker ? (
              <>
                <h3 style={{ fontWeight: "bold" }}>Fees payable</h3>
                <p>Please see the separate ‘Tariff of charges’ document.</p>
                <p>
                  This service is subject to an arrangement fee of{" "}
                  {this.state.selectedProduct.arrangementFeePerc}% of the gross
                  new loan.
                </p>
                <p>
                  If you request that a surveyor visits your property to
                  undertake a valuation, a valuation fee will be payable. The
                  formal mortgage offer will be conditional on the valuation of
                  the property being acceptable to us. Alternatively, if we use
                  an AVM, then no valuation fee is payable.
                </p>
                <p>
                  If you apply to borrow more than your current balance (the
                  amount required to redeem your existing mortgage), there may
                  be an additional title insurance fee to pay.
                </p>
                <p>
                  If there are changes to the title of the property, we may need
                  to instruct solicitors to review these. Their fees would be
                  based on the level of work required in connection with these
                  changes. These would be disclosed to you in the formal
                  mortgage offer.
                </p>
                <p>
                  If you switch with us, we’ll waive any early repayment charges
                  that would be payable on your current mortgage.
                </p>
              </>
            ) : null}

            {this.shouldRefer() || isProductTransfer || isBroker ? null : (
              <>
                <h3
                  style={{ fontWeight: "bold" }}
                  data-test="fees-payable-section"
                >
                  Fees payable
                </h3>
                <p>
                  This service is subject to an arrangement fee of{" "}
                  {this.state.selectedProduct.arrangementFeePerc}% of the gross
                  new loan.
                </p>
              </>
            )}

            {isBroker ? (
              <>
                <h3 style={{ fontWeight: "bold" }}>Marketing</h3>
                <p>
                  From time to time, Shawbrook may wish to send the applicant(s)
                  marketing information and newsletters about our own products
                  and services by email, SMS and post. We may also wish to call
                  the applicant(s) by telephone about our own products and
                  services.
                </p>
                <p>
                  If the applicant(s) do not want to hear from us in this way,
                  you can opt-out on their behalf by ticking the options below.
                  If you do not opt-out now, the applicant(s) can contact us to
                  tell us not to send further marketing at any time.
                </p>
                <p>
                  Further details about how to do this, what our lawful reason
                  is for this processing of the applicant(s)’ personal
                  information and about the applicant(s)’ rights under data
                  protection law, including in relation to marketing, are set
                  out in our privacy notice referred to above.
                </p>
                <CheckboxSelect
                  className="step7__contact-options_broker"
                  options={[
                    {
                      label:
                        "The applicant(s) do not wish to hear from us by email in relation to marketing.",
                      value: "Email",
                    },
                    {
                      label:
                        "The applicant(s) do not wish to hear from us by post in relation to marketing.",
                      value: "Post",
                    },
                    {
                      label:
                        "The applicant(s) do not wish to hear from us by phone in relation to marketing.",
                      value: "Phone",
                    },
                    {
                      label:
                        "The applicant(s) do not wish to hear from us by SMS in relation to marketing.",
                      value: "SMS",
                    },
                  ]}
                  selected={[...this.state.contact_options]}
                  handleSelect={this.handleContactOptions}
                />
                <CheckboxSelect
                  className="step7__contact-options_broker_confirmation"
                  options={[
                    {
                      label:
                        "By completing the above options you are confirming the applicant has provided the details regarding their marketing preference(s) and been informed of their right to opt out at any time.",
                      value: "",
                    },
                  ]}
                  handleSelect={this.handleBrokerConfirmation}
                />
              </>
            ) : (
              <>
                <h3 style={{ fontWeight: "bold" }}>Marketing</h3>
                <p>
                  From time to time, Shawbrook may wish to send you marketing
                  information and newsletters about our own products and
                  services by email, SMS and post. We may also wish to call you
                  by telephone about our own products and services.
                </p>
                <p>
                  If you do not want to hear from us in this way, you can
                  opt-out by ticking the option below. If you do not opt-out
                  now, you can contact us to tell us not to send further
                  marketing at any time.
                </p>
                <p>
                  Further details about how to do this, what our lawful reason
                  is for this processing of your personal information and about
                  your rights under data protection law, including in relation
                  to marketing, are set out in our privacy notice referred to
                  above.
                </p>
                <CheckboxSelect
                  className="step7__contact-options"
                  options={[
                    {
                      label: "I do not wish to be contacted by email",
                      value: "Email",
                    },
                    {
                      label: "I do not wish to be contacted by post",
                      value: "Post",
                    },
                    {
                      label: "I do not wish to be contacted by phone",
                      value: "Phone",
                    },
                    {
                      label: "I do not wish to be contacted by SMS",
                      value: "SMS",
                    },
                  ]}
                  selected={[...this.state.contact_options]}
                  handleSelect={this.handleContactOptions}
                />
              </>
            )}
          </Col>
        </Row>

        {isBroker ? (
          <>
            <ProductSwitchConsumer>
              {(ctx) =>
                renderControllerBar(
                  this.props.backTo,
                  "/step-8",
                  ctx.state.isDataReady,
                  brokerLabel,
                  this.state.isSubmit,
                  () => ctx.saveBackProgress("step7"),
                  () => ctx.update("step7", this.state)
                )
              }
            </ProductSwitchConsumer>
          </>
        ) : this.shouldRefer() || isProductTransfer ? (
          <ProductSwitchConsumer>
            {(ctx) =>
              renderControllerBar(
                this.props.backTo,
                "/referral",
                true,
                label,
                this.state.isSubmit,
                () => ctx.saveBackProgress("step7"),
                () =>
                  ctx.update("step7", this.state, {
                    referred: true,
                  })
              )
            }
          </ProductSwitchConsumer>
        ) : (
          <ProductSwitchConsumer>
            {(ctx) =>
              renderControllerBar(
                this.props.backTo,
                "/confirmation",
                true,
                label,
                true,
                () => ctx.saveBackProgress("step7"),
                () => ctx.update("step7", this.state)
              )
            }
          </ProductSwitchConsumer>
        )}
      </Container>
    );
  }
}

export default Step7;
