export const baseUrl = process.env.REACT_APP_BASE_URL;
export const apiUrl = process.env.REACT_APP_API_URL;

export const saveState = !/^(t(rue)?|1)$/i.test(
    process.env.REACT_APP_IGNORE_SAVE_STATE || ''
);
export const gaKey = process.env.REACT_APP_GA_KEY;
const instance = `https://${process.env.REACT_APP_B2C_TENANT_SUBDOMAIN}.b2clogin.com/tfp/`;
const tenant = `${process.env.REACT_APP_B2C_TENANT_SUBDOMAIN}.onmicrosoft.com`;
export const ltvUrl='/product/getcalculatedltv';
const cacheLocation =
    process.env.REACT_APP_B2C_CACHE_LOCATION || 'sessionStorage';
const signInPolicy = process.env.REACT_APP_B2C_SIGNIN_POLICY_NAME;
export const b2c = {
    instance,
    tenant,
    applicationId: process.env.REACT_APP_B2C_APPLICATION_ID,
    signInPolicy,
    resetPolicy: process.env.REACT_APP_B2C_RESET_POLICY_NAME || null,
    cacheLocation,
    redirectUri: window.location.origin || null, // null Assume CURRENT
    postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_URL ||
        window.location.origin,
    scopes: ['openid'],

    // NOTE FOLLOWING LINE: Required to support b2clogin.com 
    // "If you are using MSAL, you need to set the ValidateAuthority property to
    // false.", source: https://docs.microsoft.com/en-us/azure/active-directory-b2c/b2clogin
    validateAuthority: false,
};
