import React from "react";
import { Row, Col, Label } from "reactstrap";
import {
  fullFormatCurrency,
  dataExists,
  loanReason,
} from "../../../services/utils";
import "./Step2Review.css";
import {
  useTheValuation,
  selectSurveyor,
  differentLoanAmount,
  dataNotFoundMessage,
} from "../../../data/data";
import { JsonSchema } from "../../../data/stepData";

const Step2Review = (props) => {
  const { step2, mortgageDetails, isBroker } = props.data;
  const { remaining_balance = "Data not Found", arrangementFeePerc } = props.data.selectedProduct;
  let grossLoanAmount = remaining_balance * (1 + (arrangementFeePerc / 100));
  const step2Header = mortgageDetails.isProductTransfer
    ? JsonSchema.step2.sections[0].headerDescriptionProductTransfer
    : JsonSchema.step2.sections[0].headerDescriptionProductSwitch;
  const step2Questions = JsonSchema.step2.sections[0].questions;
  return (
    <>
      <Label className="step2Label">{step2Header}</Label>
      {!mortgageDetails.isProductTransfer && (
        <>
          <Row className="step2label-row">
            <Col className="question">{step2Questions[0].description}</Col>
            <Col className="answer">
              {dataExists(step2.avm_agreed ? useTheValuation : selectSurveyor)}
            </Col>
          </Row>
          {!step2.avm_agreed ? (
            <Row className="step2label-row">
              <Col className="question">{step2Questions[2].description}</Col>
              <Col className="answer">
                {dataExists(fullFormatCurrency(step2.expected_value))}
              </Col>
            </Row>
          ) : (
            <Row className="step2label-row">
              <Col className="question">{step2Questions[5].description}</Col>
              <Col className="answer">
                {mortgageDetails.automated_valuation > 0
                  ? dataExists(
                      fullFormatCurrency(mortgageDetails.automated_valuation)
                    )
                  : dataNotFoundMessage}
              </Col>
            </Row>
          )}
        </>
      )}
      <Row className="step2label-row">
        <Col className="question">{step2Questions[1].description} </Col>
        <Col className="answer">
          {dataExists(
            step2.accept_value
              ? fullFormatCurrency(grossLoanAmount)
              : differentLoanAmount
          )}
        </Col>
      </Row>
      {!step2.accept_value ? (
        <Row className="step2label-row">
          <Col className="question">{step2Questions[3].description}</Col>
          <Col className="answer">
            {dataExists(fullFormatCurrency(step2.desired_loan))}
          </Col>
        </Row>
      ) : null}

      {isBroker ? <div className="page-break"></div> : null}

      {typeof step2.loan_reason !== "undefined" &&
      step2.loan_reason.length > 0 ? (
        <Row className="step2label-row">
          <Col className="question">{step2Questions[4].description}</Col>
          <Col className="answer">
            {dataExists(
              step2.loan_reason.map((value) => loanReason(value)).join(", ")
            )}
          </Col>
        </Row>
      ) : null}

      {typeof step2.loan_reason_detail !== "undefined" &&
      step2.loan_reason_detail !== null &&
      step2.loan_reason_detail.length > 0 ? (
        <Row className="step2label-row">
          <Col className="question">
            {step2Questions[4].loanReasonDetails.description}
          </Col>
          <Col className="answer">{dataExists(step2.loan_reason_detail)}</Col>
        </Row>
      ) : null}
    </>
  );
};

export default Step2Review;
