import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./ThankYou.css";
import {
  footerContent,
  staticfooterLinks,
  thankYouMessage,
  thankYouMessageNote,
} from "../../data/data";

class ThankYou extends Component {
  renderLinks() {
    return staticfooterLinks().map((link, index) => (
      <Col className="text-center m-0 p-0 footer__option">
        {link.href ? (
          <a
            style={{ color: "white" }}
            rel="noopener noreferrer"
            target="_blank"
            href={link.href}
          >
            {link.label}
          </a>
        ) : (
          <p className="mb-1" style={{ color: "white" }}>
            {link.label}
            {link.value}
          </p>
        )}
      </Col>
    ));
  }
  render() {
    return (
      <div>
        <div className="sbLogo">
          <img
            className="logoImage"
            alt="Shawbrook Bank Logo"
            src="/logo.png"
          ></img>
        </div>
        <div className="thanksText">
          <h2>{thankYouMessage}</h2>
          <p className="pText">{thankYouMessageNote}</p>
        </div>
        <div className="thankyoufooter p-3">
          <div className="footer__content">
            <Row className="text-center mr-0 p-2">
              <Col>{footerContent}</Col>
            </Row>
            <Row className="mt-2 d-xs-flex flex-column">
              {this.renderLinks()}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default ThankYou;
