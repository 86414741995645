import React from 'react';
import { getContactInfo } from './api';

const ContactInfoContext = React.createContext();

// By Using a context we can ensure that the api will only be called once
// and values saved and able to be reused thoughout the project
export class ContactInfoProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: "",
            email: "",
            postAddress: "",
        }
    }

    componentDidMount() {
        this.fetchContactInfo();
    }

    fetchContactInfo() {
        getContactInfo().then(response => {
            this.setState({
                phoneNumber: response.phoneNumber,
                email: response.emailAddress,
                registeredAddress: response.registeredAddress
            })
        })
    }

    render() {
        const {children} = this.props;
        return (
          <ContactInfoContext.Provider
            value={{
              state: this.state
            }}
          >
            {children}
          </ContactInfoContext.Provider>
        );
      }
}

// A higherOrder Component which can give access to the contact information 
export function withContactInfo(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
                <ContactInfoContext.Consumer>
                    {ctx => (
                        <WrappedComponent {...ctx.state} {...this.props} ></WrappedComponent>
                    )}
                </ContactInfoContext.Consumer>
            )
        }
    }
}

//Individual Components for each piece of information
export const PhoneNumber = withContactInfo( function(props) {
    return ( <>{props.phoneNumber}</> );
})

export const RegisteredAddress = withContactInfo( function(props) {
    return ( <>{props.registeredAddress}</> );
})

export const Email = withContactInfo( function(props) {
    return ( <>{props.email}</> );
})