import React from 'react';
import { Row, Col } from 'reactstrap'
import './PageIntroduction.css';

const PageIntroduction = props => (
    <Row className="page-introduction">
        <Col xs='12' className='px-0 small_header'>
            <h6>{props.small_header}</h6>
        </Col>
        <Col xs='12' className='px-0 main_header'>
            <h2 className="mb-3">{props.main_header} {props.full_name}</h2>
        </Col>
    </Row>
);

export default PageIntroduction;
