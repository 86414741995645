import React from "react";
import {
  dateFormat,
  handleAsyncError,
  normaliseTerm,
  transformProductForBlueChip,
  determineProgress,
  transformStateForBlob,
  transformProductForMarketingPreferences,
  isProductTransfer
} from "../../../services/utils";
import { withRouter  } from "react-router-dom";
import { shouldRefer, getCaseDetails } from "../../../services/utils";
import {
  getMortgage,
  sendState,
  referApplication,
  completeApplication,
  getFeatureFlag,
  getBrokerByCaseId
} from "../../../services/api";
import { printUrl, pdfFeatureFlag, eSignatureFlag, scottishCaseFlag } from "../../../data/data";

var jwtdecode = require("jwt-decode");
require("es6-promise").polyfill();

const ProductSwitchContext = React.createContext();

class ProductSwitchProvider extends React.Component {
  constructor(props) {
    super(props);
    var caseDetails = jwtdecode(getCaseDetails());
    this.caseId = sessionStorage.getItem("caseId");
    this.customerId = caseDetails.extension_IdentityId;
    this.state = {
      error: false,
      brokerDetails:{},
      requestInProgress: false,
      isDataReady: !!props.mortgageDetails,
      accountNumber: "",
      guarantors: props.guarantors || [],
      mortgageDetails: props.mortgageDetails || {},
      maxTerm: 0,
      minTerm: 0,
      isBroker: typeof  props.isBroker !='undefined' &&  props.isBroker? props.isBroker :  (typeof caseDetails.extension_IsBroker === "undefined" || caseDetails.extension_IsBroker === null) ? false : caseDetails.extension_IsBroker,
      step1: {},
      step2: props.step2 || {
        avm_agreed: null,
        accept_value: null,
        expected_value: "",
        desired_loan: 0,
        loan_reason: [],
        loan_reason_detail: "",
        future_ltv: 0
      },
      step3: props.step3 || {
        propertyAlterations: null,
        typeOfChange: [],
        propertyChangesDetails: "",
        additionalInformation: null,
        furtherDetails: "",
        claims: null,
        claimsInformation: "",
        capitalRaise: null
      },
      step4:props.step4 || {
        mortgageType: '',
      },
      step5: {},
      step6: {},
      step7: {
        contact_options: []
      },
      selectedProduct: props.selectedProduct || {},
      confirmation: {},
      progressBar: "0%",
      caseLock: null,
      submissionDate: null,
      pdfFeatureFlag : props.pdfFeatureFlag|| false,
      pdfFetaureFlagRequestDone : false,
      eSignatureFlag:props.eSignatureFlag||false,
      eSignatureFlagRequestDone:false,
      scottishCaseFlag:props.scottishCaseFlag||false,
      scottishCaseFlagRquestDone:false,
      brokerDetailsRequestDone:false,
      scottishCase:false,
    };

    this.previousState = {};
    this.update = this.update.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
    this.saveBackProgress = this.saveBackProgress.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.handleRequestError = this.handleRequestError.bind(this);
    this.savePdfHtmlContent = this.savePdfHtmlContent.bind(this);
  }

  update(key, values, opts) {
    this.setState({ [key]: values }, () => this.saveProgress(key, opts));
  }

  selectProduct(product) {
    const { future_ltv } = { ...this.state.step2 };
    if (future_ltv > 0) {
      product.future_ltv = future_ltv;
    }
    this.setState({ selectedProduct: product });
  }

  isReferred = () => this.state.caseLock === "referred";
  isProductSelected = () => this.state.caseLock === "product_selected";
  isUnlocked = () =>
    this.state.caseLock == null || this.state.caseLock === false;
  isOfferRequested = () => this.state.caseLock === "fmo";

  lock = status => this.setState({ caseLock: status });

  getCaseId = () => this.caseId;

  updateProgressBar(key) {
    return this.setState({ progressBar: determineProgress(key) });
  }

  handleRequestError(error) {
    return this.setState({ error: error, requestInProgress: false });
  }
  submitCompleteApplication()
  {
    const MarketingPreferences = transformProductForMarketingPreferences(
      this.state.step7.contact_options
    );
    const blobObject = transformStateForBlob(this.state);
    let ProductChoice = {};
    let step="confirmation";
      ProductChoice = transformProductForBlueChip(this.state.selectedProduct);
      ProductChoice.ltv = this.state.mortgageDetails.actualLTV;
      ProductChoice.loanAmount = this.state.step2.desired_loan;
      ProductChoice.securityValue = this.state.mortgageDetails.automated_valuation;
      const request = {
        MarketingPreferences,
        ProductChoice,
        StateData: { ...blobObject, currentStep:step},
        ApplicationFormPdfContent: this.state.pdfHtmlContent
      };
      completeApplication(this.getCaseId(), request)
        .then(() =>
          this.setState({
            currentStep: step,
            caseLock: "product_selected",
            requestInProgress: false
          })
        )
        .catch(this.handleRequestError);
  }

  submitReferredApplication(){
    const MarketingPreferences = transformProductForMarketingPreferences(
      this.state.step7.contact_options
    );

    const blobObject = transformStateForBlob(this.state);
    let ProductChoice = {};
    ProductChoice = transformProductForBlueChip(this.state.selectedProduct);
    ProductChoice.loanAmount = this.state.step2.desired_loan;
    ProductChoice.securityValue = this.state.mortgageDetails.automated_valuation;
    ProductChoice.ltv =
      this.state.step2.future_ltv <= 0
        ? this.state.mortgageDetails.actualLTV
        : this.state.step2.future_ltv;
    const request = {
      MarketingPreferences,
      ProductChoice,
      StateData: { ...blobObject, currentStep: "referral" },
      ApplicationFormPdfContent: this.state.pdfHtmlContent
    };
    referApplication(this.getCaseId(), request)
      .then(() =>
        this.setState({
          currentStep: "referral",
          caseLock: "referred",
          requestInProgress: false
        })
      )
      .catch(this.handleRequestError);
  }

  savePdfHtmlContent(htmlContent) {
    return this.setState({ pdfHtmlContent: htmlContent });
  }

  saveProgress(key, opts) {
    if (this.state.requestInProgress) {
      return;
    }
    this.setState({ requestInProgress: true });
    key = key || window.location.pathname.slice(1).replace("-", "");

    // Transform the current state into only the user's inputs to store in blob
    this.previousState = JSON.parse(JSON.stringify(this.state));;
    const blobObject = transformStateForBlob(this.state);
    if (key === "step7" && opts && opts.referred) {
      if(!this.state.isBroker){
        this.submitReferredApplication();
      }
      else{
        sendState(this.getCaseId(), {
          ...blobObject,
          currentStep: "step8"
        })
        .then(() => this.setState({ requestInProgress: false }))
        .catch(this.handleRequestError);
      }
    }
    else if (key === "step7" ) {
      if(!this.state.isBroker)
      {
        this.submitCompleteApplication();
      }
      else{
        sendState(this.getCaseId(), {
          ...blobObject,
          currentStep: "step8"
        })
        .then(() => this.setState({ requestInProgress: false }))
        .catch(this.handleRequestError);
    }
    }
    else if(key === "step8" && this.state.isBroker){
      if(opts && opts.referred){
        this.submitReferredApplication();
      }
      else{
      this.submitCompleteApplication();
      }
    }
     else {
      // This will check the last saved step and add one to the value to save the 'current step'
      var currentStepKey = key;
      if (opts && opts.toRefer) {
        currentStepKey = "declaration";
      } else if (key.substring(0, 4) === "step") {
        currentStepKey = key.substring(0, 4) + (parseInt(key.slice(-1)) + 1);
      }
      sendState(this.getCaseId(), {
        ...blobObject,
        currentStep: currentStepKey
      })
        .then(() => this.setState({ requestInProgress: false }))
        .catch(this.handleRequestError);
    }
    this.updateProgressBar(key);
  }


  saveBackProgress(key){
    const blobObject = transformStateForBlob(this.previousState);
    key = key || window.location.pathname.slice(1).replace("-", "");
    var currentStepKey = key;
    if(currentStepKey === "step1")
    {
      currentStepKey = "Welcome"
    }else{
      currentStepKey = key.substring(0, 4) + (parseInt(key.slice(-1)) - 1);
    }
    //this.state = JSON.parse(JSON.stringify(this.previousState));
    sendState(this.getCaseId(), {
      ...blobObject,
      currentStep: currentStepKey
    })
  }

  // Fetch details from API
  componentDidMount() {
      this.fetchMortgage();
      this.fetchPdfFeatureFlag();
      this.fetchESignatureFlag();
      this.fetchScottishCaseFlag();
  }

  shouldRefer(referState) {
    return shouldRefer(referState);
  }
  fetchPdfFeatureFlag = () => {
    if (this.state.pdfFetaureFlagRequestDone) {
      return;
    }

    getFeatureFlag(pdfFeatureFlag).then(res=>{
      this.setState({
        pdfFeatureFlag : res.flag,
        pdfFetaureFlagRequestDone : true
      });
    });
  }

  fetchESignatureFlag=()=>{
    if(this.state.eSignatureFlagRequestDone){
      return;
    }
    getFeatureFlag(eSignatureFlag).then(res=>{
      this.setState({
        eSignatureFlag : res.flag,
        eSignatureFlagRequestDone : true
      });
    });
  }

  fetchScottishCaseFlag=()=>{
    if(this.state.scottishCaseFlagRquestDone){
      return;
    }
    getFeatureFlag(scottishCaseFlag).then(res=>{
      this.setState({
        scottishCaseFlag : res.flag,
        scottishCaseFlagRquestDone : true
      });
    });
  }

fetchBrokerDetails=()=>{
  if(!this.state.isBroker || this.state.brokerDetailsRequestDone){
    return;
  }
  else{
    getBrokerByCaseId(this.caseId).then(res => {
      this.setState({
        brokerDetails:res,
        brokerDetailsRequestDone:true
      });
    });
  }
}

  fetchMortgage = () => {
    if (this.props.location.pathname === "/") {
      return;
    }
    if (this.state.requestInProgress) {
      return;
    }

    this.fetchBrokerDetails();

    this.setState({ requestInProgress: true });

    if (this.getCaseId() === undefined || this.getCaseId() === null) {
      window.location.href = "/";
    }
    getMortgage(this.getCaseId())
      .then(response => {
        const mortgage = response;
        const progressState = mortgage.progressState;
        const caseLock = response.caseLock;

        let currentStep = progressState && progressState.currentStep;

        //When user Sign out and sign in, user should land on SelectMortgage Page
        // If the case is locked, we need to override the current step
        if(this.props.location.pathname === printUrl){
          currentStep = printUrl;
        }
        else if (caseLock === "referred") {
          currentStep = "referral";
        } else if (caseLock === "product_selected" || caseLock === "fmo") {
          currentStep = "confirmation";
        }

        if (currentStep == null || currentStep === "") {
          currentStep = "/Welcome";
        }

        if (
          currentStep === "declaration" &&
          this.shouldRefer({
            step2: progressState.step2,
            step3: progressState.step3
          })
        ) {
          const { future_ltv } = { ...progressState.step2 };
          if (future_ltv === undefined) {
            currentStep = "step2";
            progressState.currentStep = "step2";
          }
        }

        if (/^step\d$/.test(currentStep)) {
          this.setState({ ...progressState, caseLock });
          const [str, num] = currentStep.split(/(\d+)/);
          if(currentStep !== printUrl)
            this.props.history.push(`${str}-${num}`);
        } else {
          this.setState({ ...progressState, caseLock })
           if(currentStep !== printUrl){

            this.props.history.push(currentStep);
          }
        }

        if (!mortgage.endDate) {
          mortgage.endDate = "";
        } else {
          mortgage.endDate = dateFormat(mortgage.endDate.substring(0, 10));
        }

        if (!mortgage.applicants) {
          mortgage.applicants = "";
        } else {
          mortgage.applicants = JSON.parse(mortgage.applicants).map(
            guarantor =>
              guarantor.TITLE + " " + guarantor.INITIAL + " " + guarantor.SNAME
          );
        }
        this.setState({
          isDataReady: true,
          requestInProgress: false,
          mortgageDetails: {
            remaining_balance: mortgage.remainingBalance,
            grossLoan_amount:mortgage.grossLoanAmount,
            end_date: dateFormat(mortgage.previousCase.productEndDate),
            rate: mortgage.previousCase.rate,
            current_repayment: mortgage.previousCase.monthlyPayment,
            automated_valuation: mortgage.automatedValuation,
            securityAddress: mortgage.securityAddress,
            termMonths: normaliseTerm(
              mortgage.previousCase.term,
              mortgage.previousCase.termMonths
            ),
            actualLTV:mortgage.actualLTV,
            automatedValuationLTV: mortgage.automatedValuationLTV,
            accountName: mortgage.accountName,
            telephone: mortgage.telephone,
            email: mortgage.email,
            correspondenceAddress: mortgage.correspondenceAddress,
            capitalRaise: mortgage.progressState === null || mortgage.progressState.step3 === null ? null : mortgage.progressState.step3.capitalRaise,
            isProductTransfer: isProductTransfer(mortgage.applicationCreationTypeID)
          },
          accountNumber: mortgage.accountNumber,
          guarantors: mortgage.applicants,
          maxTerm: mortgage.maxTermAllowed,
          minTerm: mortgage.minTermAllowed,
          submissionDate: mortgage.progressState !=null &&  mortgage.progressState.submissionDate !=null ? mortgage.progressState.submissionDate :null,
          scottishCase:mortgage.scottishCase,
         },
         function() {
          this.previousState = JSON.parse(JSON.stringify(this.state));
        });
      })
      .catch(this.handleRequestError);
  };

  render() {
    const { children } = this.props;
    // Trigger the error boundary if any async request fails
    if (this.state.error) return handleAsyncError(this.state.error);

    return (
      <ProductSwitchContext.Provider
        value={{
          state: this.state,
          previousState:this.previousState,
          update: this.update,
          selectProduct: this.selectProduct,
          saveProgress: this.saveProgress,
          saveBackProgress:this.saveBackProgress,
          isReferred: this.isReferred,
          isProductSelected: this.isProductSelected,
          isUnlocked: this.isUnlocked,
          isOfferRequested: this.isOfferRequested,
          lock: this.lock,
          getCaseId: this.getCaseId(),
          fetchMortgage: this.fetchMortgage,
          savePdfHtmlContent: this.savePdfHtmlContent
        }}
      >
        {children}
      </ProductSwitchContext.Provider>
    );
  }
}

export default withRouter(ProductSwitchProvider);
export const ProductSwitchConsumer = ProductSwitchContext.Consumer;
