import React from 'react';
import Button from '../Button/Button'
import { requestPdf } from '../../../services/utils'
import ReactGA from 'react-ga'

const TermsAndConditionsDownload = props => (
    <Button 
        handleClick={() => {
            requestPdf('tariffofcharges', 'TariffOfChargesDownload'); 
            ReactGA.ga('send', {
                hitType: 'event',
                eventCategory: 'T&Cs downloaded',
                eventAction: 'Downloaded',
                eventLabel: 'T&Cs'
            })
        }}
        className="step7__download" 
        type="secondary" 
        label="Download" 
    />
)

export default TermsAndConditionsDownload;