import React, { Component } from "react";
import { Col } from "reactstrap";
import { shouldRefer, getCaseDetails } from "../../services/utils";
import CheckboxSelect from "../../components/atoms/CheckboxSelect/CheckboxSelect";
import PageIntroduction from "../../components/atoms/PageIntroduction/PageIntroduction";
import ScrollToPoint from "../../components/atoms/ScrollToPoint/ScrollToPoint";
import { ProductSwitchConsumer } from "../../components/Organisms/ProductSwitch/ProductSwitchContext";
import { renderControllerBar } from "../../services/navFooterForPages";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Step8.css";

var jwtdecode = require("jwt-decode");
class Step8 extends Component {
  constructor(props) {
    super(props);
    let caseDetails = jwtdecode(getCaseDetails());
    this.state = {
      loader: false,
      contact_options: props.contact_options || [],
      isBroker:
        typeof caseDetails.extension_IsBroker === "undefined" ||
        caseDetails.extension_IsBroker === null
          ? false
          : caseDetails.extension_IsBroker,
      isSubmit: !caseDetails.extension_IsBroker,
      isProductTransfer: props.state.mortgageDetails.isProductTransfer
    };
  }

  shouldRefer() {
    return shouldRefer(this.props.state);
  }

  handleBrokerConfirmation = () => {
    this.setState({ isSubmit: !this.state.isSubmit });
  };

  render() {
    const { isProductTransfer } = this.state;
    const label = "Submit Application";
    return (
      <Col fluid className="step8 page-navbar-bottom">
        <ScrollToPoint></ScrollToPoint>
        <ProductSwitchConsumer>
          {(ctx) => (
            <>
              {ctx.state.isBroker ? (
                <PageIntroduction
                  small_header="Step 8 of 8"
                  main_header="Confirm &amp; submit"
                ></PageIntroduction>
              ) : null}
              {ctx.state.isBroker ? <ProgressBar now={88} /> : null}

              <h3 className="subHeading">Declaration</h3>

              <CheckboxSelect
                className="step8__contact-options_broker_confirmation box-height checkboxLeft"
                options={[
                  {
                    label: "",
                    value: "",
                  },
                ]}
                handleSelect={this.handleBrokerConfirmation}
              />
              <p className="declaration">
                The application has been submitted by{" "}
                {ctx.state.brokerDetails.companyName} as agent for the
                applicant(s) and by submitting this application{" "}
                {ctx.state.brokerDetails.companyName} confirms that it is
                authorised by the applicant(s) to declare on their behalf that
                the replies to the questions contained herein are true and
                complete in every respect to the best of the applicant(s) belief
                and knowledge and the applicant(s) have confirmed their
                understanding that these replies will be relied upon by, and may
                form the basis of any contract between, the applicant(s) and
                Shawbrook Bank Limited.
              </p>
            </>
          )}
        </ProductSwitchConsumer>

        {this.shouldRefer() || isProductTransfer ? (
          <ProductSwitchConsumer>
            {(ctx) =>
              renderControllerBar(
                this.props.backTo,
                "/referral",
                true,
                label,
                this.state.isSubmit,
                () => ctx.saveBackProgress("step8"),
                () =>
                  ctx.update("step8", this.state, {
                    referred: true,
                  })
              )
            }
          </ProductSwitchConsumer>
        ) : (
          <ProductSwitchConsumer>
            {(ctx) =>
              renderControllerBar(
                this.props.backTo,
                "/confirmation",
                true,
                label,
                this.state.isSubmit,
                () => ctx.saveBackProgress("step8"),
                () => ctx.update("step8", this.state)
              )
            }
          </ProductSwitchConsumer>
        )}
      </Col>
    );
  }
}

export default Step8;
