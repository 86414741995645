import React from "react";
import { Row, Col, Button } from "reactstrap";
import "./ButtonSelect.css";

class ButtonSelect extends React.Component {
  constructor(props) {
    super(props);
    // Takes an options prop as an Array[String]
    this.state = {
      selected: null
    };

    if (props.selected !== null) {
      this.state.selected = this.props.options.findIndex(option => {
        return option.value === props.selected;
      });
    }
  }



  setActive = value => {
    if (this.state.selected !== value) {
      this.setState({ selected: value });
      if (this.props.handleSelect) {
        this.handleSelect(value);
      }
    }
  };

  handleSelect = value => {
    this.props.handleSelect(this.props.options[value].value);
  };

  render() {
    return (
      <Row
        className={`${
          this.props.className ? this.props.className : ""
        } field-mr`}
      >
        <Col xs="12" className="field-text-label">
          {this.props.label}
        </Col>
        <Col className={`${this.props.className} button-select`}>
          <Button
            
            outline
            color="danger"
            className={`${
              this.state.selected === 0 ? "selected" : ""
            } p-0 mr-md-1 button-select__option col-md-6 col-md-12`}
            onClick={() => this.setActive(0)}
          >
            {this.props.options[0].label}
          </Button>
          <Button
            xs="12"
            md="6"
            outline
            color="danger"
            className={`${
              this.state.selected === 1 ? "selected" : ""
            } p-0 ml-md-1 mt-xs-1 button-select__option col-md-6 col-md-12`}
            onClick={() => this.setActive(1)}
          >
            {this.props.options[1].label}
          </Button>
        </Col>
      </Row>
    );
  }
}

export default ButtonSelect;
