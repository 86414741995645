import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "./RangeInput.css";

import { Row, Col } from "reactstrap";
import { isNumeric, ifIsFunctionCall } from "../../../services/utils";

class RangeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.default || props.min,
      label:
        (props.range
          ? props.default[0] + " - " + props.default[1]
          : props.default) || props.min,
    };

    this.marks = {
      [props.min]: {
        style: {
          color: "#cc0066",
        },
        label: <strong>{props.min}</strong>,
      },
      [props.max]: {
        style: {
          color: "#cc0066",
        },
        label: <strong>{props.max}</strong>,
      },
    };
  }

  getMarks() {
    var rangeMarks = {};
    if (this.props.range)
      this.props.marks.forEach((mark) => {
        var markObj = {
          style: { color: "#c06" },
          label: <strong>{mark}</strong>,
        };
        rangeMarks[mark] = markObj;
      });
    return rangeMarks;
  }

  onAfterChange = (value) => {
    if (isNumeric(value) || Array.isArray(value))
      this.setState({ value: value }, () =>
        ifIsFunctionCall(this.props.onChange, value)
      );
  };

  onChange = (value) => {
    if (this.props.range) {
      var label = value[0] + " - " + value[1];
      this.setState({ label: label, value: value });
    } else if (isNumeric(value)) {
      this.setState({ value: value, label: value });
    }
  };

  render() {
    const props = {
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
      defaultValue: this.props.default,
      marks: this.marks,
      onChange: this.onChange,
      onAfterChange: this.onAfterChange,
      handleStyle: {
        borderRadius: 0,
        borderColor: "#cc0066",
        marginTop: "-4px",
        marginLeft: "-10px",
        width: "22px",
        height: "22px",
      },
      railStyle: {
        height: "14px",
        backgroundColor: "#e5e5e5",
        borderRadius: "0",
      },
      trackStyle: {
        height: "14px",
        borderRadius: "0",
        backgroundColor: "#e5e5e5",
      },
      dotStyle: {
        marginLeft: "-10px",
        top: "-4px",
        height: "22px",
        width: "22px",
        display: "none",
      },
      className: "bp-slider",
    };

    const rangeProps = {
      ...props,
      step: null,
      handleStyle: [
        {
          borderRadius: 0,
          borderColor: "#cc0066",
          marginTop: "-4px",
          marginLeft: "-10px",
          width: "22px",
          height: "22px",
        },
        {
          borderRadius: 0,
          borderColor: "#cc0066",
          marginTop: "-4px",
          marginLeft: "-10px",
          width: "22px",
          height: "22px",
        },
      ],
      trackStyle: [
        { height: "14px", borderRadius: "0", backgroundColor: "#cc0066" },
      ],
      marks: this.getMarks(),
    };

    return (
      <Row
        className={`${this.props.className ? this.props.className : ""} field`}
      >
        <Col xs="12" className="field-text">
          {this.props.label}
        </Col>
        <Col xs="12" className={`${this.props.className} px-4`}>
          {this.props.range ? <Range {...rangeProps} /> : <Slider {...props} />}
        </Col>
        <Col
          xs="12"
          className={`${
            this.props.range ? `mt-5` : `mt-4`
          } text-center field-text`}
        >
          {`${this.state.label || this.props.default} ${this.props.caption}`}
        </Col>
      </Row>
    );
  }
}

RangeInput.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  default: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  label: PropTypes.string,
  caption: PropTypes.string,
};

RangeInput.defaultValue = {
  min: 0,
  max: 10,
  step: 1,
  default: 0,
  label: "",
  caption: "",
};

export default RangeInput;
