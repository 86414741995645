// NOTE: Using an external libray for the AAD/B2C OIDC flows.
// This is a lesser known library which extends some good work
// to add support for other MSAL (MS JS LIB) features so we can 
// use *.b2clogin.com

import authentication from '@kdpw/msal-b2c-react';
import {b2c as b2cConfig} from '../config';

authentication.initialize(b2cConfig);

export default authentication;
