import React from "react";
import { Button, Row, Table, Modal, ModalBody,ModalHeader} from "reactstrap";
import "./ProductList.css";
import {  updateMyshawbrookStatus, requestActivation, getMortgageList} from "../../../services/api";
import {  splitAddress,getCaseDetails } from "../../../services/utils";
import { dateFormatString, modalAlertTitle, modalAlertBody } from '../../../data/data';

let moment = require("moment");
class ProductList extends React.Component {
  constructor(props) {
    super(props);
    var jwtdecode = require("jwt-decode");
    var caseDetails = jwtdecode(getCaseDetails());
    this.caseId = caseDetails.extension_BlueChipCaseID;
      this.state = {
        loading: true,
        products: props.products || [],
        status: props.status || [],
        headerClass:props.headerClass || "",
        headerText:props.headerText || "",
        selectName: props.selectName || "",
        modal:false,
        reactivationCaseId:null,
      };
      this.toggle = this.toggle.bind(this);
      this.displayAlert = this.displayAlert.bind(this);
  }

  toggle(){
    this.setState({
      modal:!this.state.modal,
    });
  }

  changeRoute(caseId) {
    updateMyshawbrookStatus(caseId).then(res => {
      sessionStorage.setItem("caseId", caseId);
      window.location.href = "/welcome";
    })
  }

  async displayAlert(caseId){
    this.setState({
      loading:true
    });
    await getMortgageList(this.caseId).then(res => {
      this.setState({
        products: res,
        loading: false
      });
    });
    await requestActivation(caseId);
     this.setState({ 
      reactivationCaseId : caseId
    });
    this.toggle();
  }
 
  render() {
   const {status,headerClass,headerText, selectName}=this.state;
    
    if (this.state.products.filter((product) => { return status.includes(product.status) }).length > 0) {
      return <>
        {
          <div className={headerClass}>{headerText}</div>
        }
        {
            this.state.products.map((product, id) => {
            if (status.includes(product.status)) {
              if((product.status !== 4) && (product.status !== 5))
              {
                return <React.Fragment key={product.caseId}>
                <Row>
                <Table className="table-row-mr">
                <thead>
                <tr key={id}>
                      <th key={id} className="th-vertical-align">
                        <img className="home-icon" alt="" src={require('../../../../src/house.svg')} />
                      </th>
                      <th className="client-mr" >
                        <strong className="card-title">Client</strong><br/>
                        <strong className="card-subtitle">{product.client}</strong>
                      </th>
                      <th className="address-mr">
                      <strong className="card-title">Mortgaged address</strong><br/>
                        <span className="card-subtitle">{splitAddress(product.mortgagedAddress)}</span>
                      </th>
                      <th className="case-mr">
                      <strong className="card-title ">Case reference</strong><br/>
                      <span className="card-subtitle">{product.caseRef}</span>
                      </th>
                      <th className="date-mr">
                      <strong className="card-title ">Product end date</strong><br/>
                      <span className="card-subtitle">{moment(product.productEndDate).format(dateFormatString)}</span>
                      </th>
                      <th className="th-vertical-align">
                        <Button
                          className="button-to-select"
                          onClick={() => this.changeRoute(product.caseId)}>
                          {selectName}
                        </Button>
                      </th>
                    </tr>
                  </thead>
                </Table>
                </Row>
              </React.Fragment>
              }else{
                return <React.Fragment key={product.caseId}>
                <Row>
                <Table className="table-row-mr">
                <thead>
                <tr key={id}>
                      <th key={id} className="th-vertical-align">
                        <img className="home-icon" alt="" src={require('../../../../src/house.svg')} />
                      </th>
                      <th className="client-mr" >
                        <strong className="card-title">Client</strong><br/>
                        <strong className="card-subtitle">{product.client}</strong>
                      </th>
                      <th className="address-mr">
                      <strong className="card-title">Mortgaged address</strong><br/>
                        <span className="card-subtitle">{splitAddress(product.mortgagedAddress)}</span>
                      </th>
                      <th className="case-mr">
                      <strong className="card-title ">Case reference</strong><br/>
                      <span className="card-subtitle">{product.caseRef}</span>
                      </th>
                      <th className="date-mr">
                      <strong className="card-title ">Product end date</strong><br/>
                      <span className="card-subtitle">{moment(product.productEndDate).format(dateFormatString)}</span>
                      </th>
                      <th className="th-vertical-align">
                        {product.status === 5 ? 
                        (<Button
                          disabled = {true}
                          className="button-to-select"
                          onClick={() => this.displayAlert(product.caseId)}>
                          {selectName}
                        </Button>):
                        (
                          <>
                          <Button
                          disabled={this.state.reactivationCaseId === product.caseId}
                          className="button-to-select"
                          onClick={() => this.displayAlert(product.caseId)}
                          >
                          {selectName}
                        </Button>
                        
                          <Modal isOpen= {this.state.modal} toggle = {this.toggle} className={this.props.className}>
                              <ModalHeader toggle={this.toggle}>{modalAlertTitle}</ModalHeader>
                              <ModalBody>{modalAlertBody}</ModalBody>
                          </Modal>
                          </>
                       )}
                      </th>
                    </tr>
                  </thead>
                </Table>
                </Row>
              </React.Fragment>
              }
            }
            else {
              return <></>
            }
          })
        }
      </>
    }
    else {
      return <></>
    }
  }
}

export default ProductList;
