import React from 'react'
import { Row, Col } from 'reactstrap'
import { minFloorRate } from '../../../data/data';

export default function Note(props) {
    return (
        <Row className="my-4">
            <Col xs="12">
                <Row className="ml-0">
                    <Col md="12">
                        <h5 style={{color: props.color,fontSize:'18px',marginLeft:'-10px',marginBottom:5 }}>*A note on {props.arrLongName} ({props.arrName})...</h5>
                    </Col>
                </Row>
                <Row className="note mx-1" style={{
                    border: `3px solid ${props.color}`,
                }}>
                    <Col xs='12' className="align-self-center" style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                        {props.arrName === "SBR" ?(
                            <p style={{ margin: '1em auto', color: props.color }}>
                            Current SBR is {props.arrValue}%. 
                            Shawbrook Bank applies a minimum base rate floor to our mortgage products of 0.75%. Therefore, even when SBR is, for example, 0.10%, the minimum rate applied to our mortgage products will be 0.75%.
                            </p>
                        ):<p style={{ margin: '1em auto', color: props.color }}>
                        The current Shawbrook 3 months LIBOR: {props.arrValue}%. 
                            <br/>Please note that Shawbrook Bank applies a minimum rate of {minFloorRate.toFixed(2)}%.
                        </p>}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
