import React from 'react'
import Button from '../Button/Button'

import {Row, Col} from 'reactstrap'
import {NavLink} from 'react-router-dom'

const ErrorMessage = props => (
    <>
        <h3 className="mb-4">Sorry, something went wrong.</h3>
        <p>{props.message}</p>
        <Row className="justify-content-end">
            <Col xs="12" md="9">
                <NavLink to="/">
                    <Button
                        handleClick={props.handleClick}
                        className="mt-4"
                        label={props.buttonLabel}
                        next
                        type="primary"
                    />
                </NavLink>
            </Col>
        </Row>
    </>
)

export default ErrorMessage;