import React from 'react'
import './PersonalDetails.css'
import StaticInformationField from '../../atoms/StaticInformationField/StaticInformationField'
import InformationIcon from '../../atoms/InformationIcon/InformationIcon'
import { ProductSwitchConsumer } from '../../Organisms/ProductSwitch/ProductSwitchContext'
import { Row, Col } from 'reactstrap' 
import { objectValues, dataExists } from '../../../services/utils'
import { yourDetailsModal } from '../../../data/data'
import { JsonSchema } from '../../../data/stepData'

const personalDetails = JsonSchema.step1.sections[0].personalDetails
const brokerDetails = JsonSchema.step1.sections[0].brokerDetails
const brokerDetailsFields = JsonSchema.step1.sections[0].brokerDetails.staticInformationField
const staticInformationField1 = JsonSchema.step1.sections[0].personalDetails.staticInformationField[0]
const staticInformationField2 = JsonSchema.step1.sections[0].personalDetails.staticInformationField[1]
const staticInformationField3 = JsonSchema.step1.sections[0].personalDetails.staticInformationField[2]

const PersonalDetails = props => (
    <ProductSwitchConsumer>
        {ctx => (
            <Row >
                <Col className="personal-details__inner">
                    <h5>{personalDetails.header}</h5>
                    <InformationIcon className="personal-details__main-information" {...yourDetailsModal} />
                    <StaticInformationField
                        label={staticInformationField1.label}
                        value={ dataExists(ctx.state.mortgageDetails.telephone) }
                    />
                    <StaticInformationField label={staticInformationField2.label} value={dataExists(ctx.state.mortgageDetails.email)} />
                    <StaticInformationField label={staticInformationField3.label} value={dataExists(ctx.state.mortgageDetails.correspondenceAddress, objectValues)} />
                                    
                  {ctx.state.isBroker?(
                       <>
                        <h5 className="brokerLabel">{brokerDetails.header}</h5>
                        <StaticInformationField
                            label={brokerDetailsFields[0].label}
                            value={ dataExists(ctx.state.brokerDetails.companyName)}/>
                        <StaticInformationField label={brokerDetailsFields[1].label} value={dataExists(ctx.state.brokerDetails.contactName)} />
                        <StaticInformationField label={brokerDetailsFields[2].label} value={dataExists(ctx.state.brokerDetails.contactTelephone)} />
                        <StaticInformationField label={brokerDetailsFields[3].label} value={dataExists(ctx.state.brokerDetails.email)}/>
                   </>):(null)}
                </Col>
            </Row>
        )}
    </ProductSwitchConsumer>
)

export default PersonalDetails;